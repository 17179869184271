
import Vue from "vue";
import TeamForm from "./TeamForm.vue";

import { Team, Walker, VMenu, VForm, Trophy } from "@/types";
import { Route } from "@/types/enums";
import { getRouteColour } from "@/utils/colours";
import { getTotalTime } from "@/utils/route";
import { getTeamGender } from "@/utils/trophies";

export default Vue.extend({
  data() {
    return {
      headers: [
        {
          text: "Team Number",
          value: "teamNumber",
        },
        {
          text: "Walker 1",
          value: "walker1",
        },
        {
          text: "Walker 2",
          value: "walker2",
        },
        {
          text: "Walker 3",
          value: "walker3",
        },
        {
          text: "Additional Walker 1",
          value: "additionalWalker1",
        },
        {
          text: "Additional Walker 2",
          value: "additionalWalker2",
        },
        {
          text: "Tracker Number",
          value: "tracker",
        },
        {
          text: "Route",
          value: "route",
        },
        {
          text: "Group Contact",
          value: "contact",
        },
        {
          text: "Start Time",
          value: "startTime",
        },
        {
          text: "Finish Time",
          value: "finishTime",
        },
        {
          text: "Time Taken",
          value: "totalTime",
        },
        {
          text: "Team Gender",
          value: "gender",
        },
        {
          text: "Intact",
          value: "broken",
        },
        {
          text: "Disqualified",
          value: "disqualified",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
        },
      ],
      emptyWalker: {
        firstName: "",
        lastName: "",
      } as Walker,
      teamForm: false,
      deleteDialog: false,
      finishForm: false,
      timeMenu: false,
      finishTime: "",
      currentTeam: {} as Team,
      currentWalker: { homeContact: {} } as Walker,
      fab: false,
      retireForm: false,
      retiredWalkerNumber: "",
      retireCheckpoint: "",
      search: "",
      helpDialog: false,
      walkerDialog: false,
    };
  },
  computed: {
    teams(): Team[] {
      return this.$store.state.teams.teams;
    },
    tableTeams(): {}[] {
      return this.teams.map((team) => {
        return {
          teamNumber: team.teamNumber,
          walker1: team.walkers[0],
          walker2: team.walkers[1],
          walker3: team.walkers[2],
          additionalWalker1: team.additionalWalkers[0] ?? "",
          additionalWalker2: team.additionalWalkers[1] ?? "",
          tracker: team.trackerId,
          route: team.route,
          contact: this.getContactName(team.contact),
          startTime: team.startTime,
          finishTime: team.finishTime,
          totalTime: team.totalTime,
          gender: this.getTeamGender(team),
          broken: this.isBroken(team),
          disqualified: this.isDisqualified(team),
        };
      });
    },
    retiredWalker(): Walker {
      return this.retiredWalkerNumber === ""
        ? {}
        : this.$store.getters["walkers/getWalker"](
            parseInt(this.retiredWalkerNumber)
          );
    },
    finishTimeMenu(): VMenu {
      return this.$refs.menu as VMenu;
    },
    finishFormRef(): VForm {
      return this.$refs.finishForm as VForm;
    },
  },
  methods: {
    closeForm() {
      this.teamForm = false;
    },
    newTeam() {
      this.currentTeam = {
        walkers: [] as number[],
        additionalWalkers: [] as number[],
      } as Team;
      this.teamForm = true;
    },
    editTeam(teamNumber: number) {
      const team = this.teams.find((team) => team.teamNumber === teamNumber);
      if (!team) {
        return;
      }
      this.currentTeam = team;
      this.teamForm = true;
    },
    deleteTeam(teamNumber: number) {
      const team = this.teams.find((team) => team.teamNumber === teamNumber);
      if (!team) {
        return;
      }
      this.currentTeam = team;
      this.deleteDialog = true;
    },
    confirmDelete() {
      this.$store
        .dispatch("teams/deleteTeam", this.currentTeam.id)
        .then(() => {
          this.deleteDialog = false;
        })
        .catch((err) => {
          throw err;
        });
    },
    finishTeam(teamNumber: number) {
      const team = this.teams.find((team) => team.teamNumber === teamNumber);
      if (!team) {
        return;
      }
      this.currentTeam = team;
      this.finishForm = true;
    },
    confirmFinish() {
      if (!this.finishFormRef.validate()) {
        return;
      }
      const time = this.getTime(this.currentTeam.startTime, this.finishTime);
      this.currentTeam.finishTime = this.finishTime;
      this.currentTeam.totalTime = time;
      this.$store
        .dispatch("teams/updateTeam", this.currentTeam)
        .then(() => {
          this.finishForm = false;
        })
        .catch((err) => {
          throw err;
        });
    },
    getColour(route: Route) {
      return getRouteColour(route);
    },
    getTeamWithCoreWalker(walkerNumber: number): Team {
      return this.$store.getters["teams/getTeamByCoreWalker"](walkerNumber);
    },
    walkerName(walkerNumber: number): string {
      if (!walkerNumber) {
        return "";
      }
      return this.$store.getters["walkers/getWalkerName"](walkerNumber);
    },
    getContactName(id: string): string {
      const contact = this.$store.getters["admin/getUser"](id);
      return `${contact.firstName} ${contact.lastName}`;
    },
    confirmRetire() {
      this.retiredWalker.retired = true;
      this.retiredWalker.lastCheckpoint = this.retireCheckpoint;
      this.$store
        .dispatch("walkers/updateWalker", this.retiredWalker)
        .then(() => {
          if (
            this.$store.getters["teams/isCoreWalker"](
              this.retiredWalker.walkerNumber
            )
          ) {
            const team = this.getTeamWithCoreWalker(
              this.retiredWalker.walkerNumber
            );
            team.isBroken = true;
            return this.$store.dispatch("teams/updateTeam", team);
          } else {
            return;
          }
        })
        .then(() => {
          this.retiredWalkerNumber = "";
          this.retireForm = false;
        })
        .catch((err) => {
          throw err;
        });
    },
    isRetired(walkerNumber: number): boolean {
      if (!walkerNumber) {
        return false;
      }
      const walker = this.$store.getters["walkers/getWalker"](walkerNumber);
      return walker.retired;
    },
    isBroken(team: Team): boolean {
      return team.isBroken;
    },
    isDisqualified(team: Team): boolean {
      return this.getTeamWalkers(team)
        .map((walker) => !!walker.disqualified)
        .reduce(
          (teamDisqualified, walkerDisqualified) =>
            teamDisqualified || walkerDisqualified,
          false
        );
    },
    getTime(startTime: string, finishTime: string): string {
      return getTotalTime(startTime, finishTime);
    },
    getTracker(team: Team): string {
      return team.trackerId;
    },
    downloadTeams() {
      const teamList = this.teams.map((t) => {
        return {
          teamNumber: t.teamNumber,
          walker1: t.walkers[0],
          walker2: t.walkers[1],
          walker3: t.walkers[2],
          additionalWalker1: t.additionalWalkers[0],
          additionalWalker2: t.additionalWalkers[1],
          intact: !t.isBroken,
          contact: this.$store.getters["admin/getUser"](t.contact),
          route: t.route,
          startTime: t.startTime,
          finishTime: t.finishTime,
          timeTaken: t.totalTime,
          trackerNumber: this.getTracker(t),
        };
      });

      const header =
        "Team Number,Walker 1,Walker 2,Walker 3,Additional Walker 1,Additional Walker 2,Intact,Contact,Route,Tracker Number,Start Time,Finish Time,Time Taken";
      const values = teamList
        .map((o) => {
          return [
            `"${o.teamNumber}"`,
            `"${o.walker1}"`,
            `"${o.walker2}"`,
            `"${o.walker3}"`,
            `"${o.additionalWalker1}"`,
            `"${o.additionalWalker2}"`,
            `"${o.intact}"`,
            `"${o.contact.firstName} ${o.contact.lastName}"`,
            `"${o.route}"`,
            `"${o.trackerNumber}"`,
            `"${o.startTime}"`,
            `"${o.finishTime}"`,
            `"${o.timeTaken}"`,
          ];
        })
        .join("\n");

      const encodedUri = encodeURI(
        "data:text/csv;charset=utf-8," + header + "\n" + values
      );
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "teams.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    exportToOldSystem() {
      const exportList = this.teams.map((t) => {
        const teamRetired = t.walkers
          .concat(t.additionalWalkers)
          .reduce(
            (retired, w) =>
              this.$store.getters["walkers/getWalker"](w).retired && retired,
            true
          );
        const trophies: Trophy[] = this.$store.getters[
          "teams/getTrophiesForTeam"
        ](t.teamNumber);
        return {
          teamNumber: t.teamNumber,
          teamBreakup: t.isBroken,
          walker1: t.walkers[0],
          walker2: t.walkers[1],
          walker3: t.walkers[2],
          walker4: t.additionalWalkers[0] ?? "",
          walker5: t.additionalWalkers[1] ?? "",
          startTime: t.startTime,
          finishTime: t.finishTime,
          status: t.finishTime
            ? "F"
            : teamRetired
            ? "R"
            : t.startTime
            ? "W"
            : "NS",
          cpRetired: teamRetired
            ? this.$store.getters["walkers/getWalker"](t.walkers[0])
                .lastCheckpoint
            : "",
          trophyAward: trophies[0] ? trophies[0].name : "",
          trophy2: trophies[1] ? trophies[1].name : "",
          trophy3: trophies[2] ? trophies[2].name : "",
        };
      });

      const header =
        "Team Number,Team Breakup,Walker1,Walker2,Walker3,Walker4,Walker5,Start Time,Finish Time,Status,CP Retired,Trophy Award,Trophy2,Trophy3";
      const body = exportList
        .map(
          (item) =>
            `${item.teamNumber},${item.teamBreakup},${item.walker1},${item.walker2},${item.walker3},${item.walker4},${item.walker5},${item.startTime},${item.finishTime},${item.status},${item.cpRetired},${item.trophyAward},${item.trophy2},${item.trophy3}`
        )
        .join("\n");
      const csvString = `data:text/csv;charset=utf-8,${header}\n${body}`;
      const encodedUri = encodeURI(csvString);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "teamsExport.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    getTeamGender(team: Team) {
      return getTeamGender(this.getTeamWalkers(team));
    },
    openWalkerInfo(walkerNumber: number) {
      const walker = this.$store.getters["walkers/getWalker"](walkerNumber);
      this.currentWalker = walker;
      this.walkerDialog = true;
    },
    getTeamWalkers(team: Team): Walker[] {
      return team.walkers.map((walkerNumber) =>
        this.$store.getters["walkers/getWalker"](walkerNumber)
      );
    },
    getWalkerColour(walkerNumber: number) {
      if (!walkerNumber) {
        return "white";
      }
      const walker = this.$store.getters["walkers/getWalker"](
        walkerNumber
      ) as Walker;
      if (walker.disqualified) {
        return "red";
      } else if (walker.retired) {
        return "pink";
      } else {
        return "white";
      }
    },
    isDarkButton(walkerNumber: number) {
      if (!walkerNumber) {
        return false;
      }
      const walker = this.$store.getters["walkers/getWalker"](
        walkerNumber
      ) as Walker;
      if (walker.disqualified || walker.retired) {
        return true;
      } else {
        return false;
      }
    },
  },
  components: {
    TeamForm,
  },
});
