
import { store } from "@/plugins/firebaseConfig";
import { Team, Trophy } from "@/types";
import { Route } from "@/types/enums";
import { createTrophyReport } from "@/utils/reports";
import Vue from "vue";

import TrophyCard from "./TrophyCard.vue";
export default Vue.extend({
  data() {
    return {
      emptyTeam: {
        route: Route.BRONZE,
        walkers: [],
        additionalWalkers: [],
        startTime: "",
        finishTime: "",
        id: "",
        isBroken: false,
        totalTime: "",
        contact: "",
        teamNumber: 0,
        createdTimestamp: new store.Timestamp(0, 0),
        updatedTimestamp: new store.Timestamp(0, 0),
        trackerId: "",
      } as Team,
    };
  },
  computed: {
    bronzeTrophies(): Trophy[] {
      return this.$store.getters["teams/getBronzeTrophies"];
    },
    silverTrophies(): Trophy[] {
      return this.$store.getters["teams/getSilverTrophies"];
    },
    goldTrophies(): Trophy[] {
      return this.$store.getters["teams/getGoldTrophies"];
    },
    sobleTrophy(): Trophy {
      return this.$store.getters["teams/getSobleTrophy"];
    },
  },
  methods: {
    downloadReport() {
      const walkers = this.$store.state.walkers.walkers;
      createTrophyReport(
        this.bronzeTrophies,
        this.silverTrophies,
        this.goldTrophies,
        this.sobleTrophy,
        walkers
      );
    },
    addNewSobleTeam(teamNumber: string) {
      const sobleTeams = this.$store.state.info.trophies.soble as number[];
      sobleTeams.push(parseInt(teamNumber));
      this.$store.dispatch("info/updateTrophies", {
        ...this.$store.state.info.trophies,
        soble: sobleTeams,
      });
    },
    removeSobleTeam(teamNumber: string) {
      const sobleTeams = (
        this.$store.state.info.trophies.soble as number[]
      ).filter((value) => value !== parseInt(teamNumber));
      this.$store.dispatch("info/updateTrophies", {
        ...this.$store.state.info.trophies,
        soble: sobleTeams,
      });
    },
  },
  components: {
    TrophyCard,
  },
});
