
import Vue from "vue";

import Trophies from "./Trophies.vue";
import ResultsTable from "./ResultsTable.vue";
import { Route } from "@/types/enums";

export default Vue.extend({
  data() {
    return {
      tab: null,
      items: [
        { tab: "Trophies", component: Trophies, prop: null },
        { tab: "Bronze Results", component: ResultsTable, prop: Route.BRONZE },
        { tab: "Silver Results", component: ResultsTable, prop: Route.SILVER },
        { tab: "Gold Results", component: ResultsTable, prop: Route.GOLD },
      ],
    };
  },
});
