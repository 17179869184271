import { jsPDF } from "jspdf";

export async function createStatisticsExport(
  charts: { name: string; image: string }[]
) {
  const doc = new jsPDF({ unit: "pt" });

  doc.setFont("helvetica", "normal");

  // Generate Title

  doc.setFontSize(18);
  doc.setFont("helvetica", "bold");
  doc.text("Cotswold Marathon 2025 Statistics", 297, 70, { align: "center" });
  doc.setFontSize(14);

  let y = 100;

  for (const chart of charts) {
    const totalHeight = 285;

    const endY = y + totalHeight;

    if (endY > 820) {
      doc.addPage();
      y = 30;
    }

    y += 20;
    doc.text(chart.name, 297, y, { align: "center" });

    y += 5;

    const imageHeight = 250;
    const { width, height } = await getImageDimensions(
      chart.image,
      imageHeight
    );

    doc.addImage(chart.image, "PNG", 297 - width / 2, y, width, height);

    y += height + 10;
  }

  doc.save("Cotswold Marathon Statistics.pdf");
}

function getImageDimensions(
  image: string,
  containerHeight: number
): Promise<{ width: number; height: number }> {
  return new Promise((resolve) => {
    const img = new Image();
    img.src = image;

    img.onload = () => {
      const aspectRatio = img.width / img.height;
      resolve({
        width: aspectRatio * containerHeight,
        height: containerHeight,
      });
    };
  });
}
