
import Vue from "vue";

export default Vue.extend({
  data() {
    return {};
  },
  methods: {
    getLink(status?: string[]) {
      let link = "/walkers?";
      if (this.title != "All Walkers") {
        link += `route=["${this.title}"]&`;
      }
      if (status) {
        link += `status=${JSON.stringify(status)}`;
      }
      return link;
    },
  },
  props: {
    title: String,
    walkers: Array,
    color: String,
  },
});
