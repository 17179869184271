
/* eslint-disable no-undef */
import Vue from "vue";
import { auth, functions, store } from "../plugins/firebaseConfig";

export default Vue.extend({
  data() {
    return {
      confirmationNotification: false,
      errorDialog: false,
      errorMessage: "",
      formattedDate: "",
      confirmationDialog: false,
      resetDialog: false,
      countdown: false,
      testing: false,
      bronzeStart: "0",
      silverStart: "0",
      goldStart: "0",
      bronzeLimit: "0",
      silverLimit: "0",
      goldLimit: "0",
      mainEventDateMenu: false,
      mainBookingsOpenDateMenu: false,
      mainEarlyBirdEndsDateMenu: false,
      mainWalkerNumbersLockedDateMenu: false,
      mainBookingsCloseDateMenu: false,
      shopCloseDateMenu: false,
      virtualStartDateMenu: false,
      virtualEndDateMenu: false,
      mainEventDate: "",
      mainBookingsOpenDate: "",
      mainEarlyBirdEndsDate: "",
      mainWalkerNumbersLockedDate: "",
      mainBookingsCloseDate: "",
      shopCloseDate: "",
      virtualStartDate: "",
      virtualEndDate: "",
    };
  },
  computed: {
    eventDate() {
      return this.$store.state.info.dates.main.event;
    },
  },
  methods: {
    saveChanges() {
      this.$store
        .dispatch("info/updateConfig", {
          countdown: this.countdown,
          testing: this.testing,
          walkerNumbers: {
            start: {
              Bronze: parseInt(this.bronzeStart),
              Silver: parseInt(this.silverStart),
              Gold: parseInt(this.goldStart),
            },
            limit: {
              Bronze: parseInt(this.bronzeLimit),
              Silver: parseInt(this.silverLimit),
              Gold: parseInt(this.goldLimit),
            },
          },
          dates: {
            main: {
              bookingsOpen: new store.Timestamp(
                new Date(this.mainBookingsOpenDate + "T00:00:00").getTime() /
                  1000,
                0
              ),
              earlyBirdEnds: new store.Timestamp(
                new Date(this.mainEarlyBirdEndsDate + "T23:59:59").getTime() /
                  1000,
                0
              ),
              walkerNumbersLocked: new store.Timestamp(
                new Date(
                  this.mainWalkerNumbersLockedDate + "T23:59:59"
                ).getTime() / 1000,
                0
              ),
              bookingsClose: new store.Timestamp(
                new Date(this.mainBookingsCloseDate + "T23:59:59").getTime() /
                  1000,
                0
              ),
              event: new store.Timestamp(
                new Date(this.mainEventDate + "T00:00:00").getTime() / 1000,
                0
              ),
            },
            virtual: {
              start: new store.Timestamp(
                new Date(this.virtualStartDate + "T00:00:00").getTime() / 1000,
                0
              ),
              end: new store.Timestamp(
                new Date(this.virtualEndDate + "T23:59:59").getTime() / 1000,
                0
              ),
            },
            shopClose: new store.Timestamp(
              new Date(this.shopCloseDate + "T23:59:59").getTime() / 1000,
              0
            ),
          },
        })
        .then(() => {
          this.confirmationNotification = true;
          this.confirmationDialog = false;
        })
        .catch((err) => {
          throw err;
        });
    },
    getConfig() {
      this.countdown = this.$store.state.info.countdown;
      this.testing = this.$store.state.info.testing;
      this.bronzeStart = this.$store.state.info.walkerNumbers.start.Bronze;
      this.silverStart = this.$store.state.info.walkerNumbers.start.Silver;
      this.goldStart = this.$store.state.info.walkerNumbers.start.Gold;
      this.bronzeLimit = this.$store.state.info.walkerNumbers.limit.Bronze;
      this.silverLimit = this.$store.state.info.walkerNumbers.limit.Silver;
      this.goldLimit = this.$store.state.info.walkerNumbers.limit.Gold;
      this.mainEventDate = (
        this.$store.state.info.dates.main
          .event as firebase.default.firestore.Timestamp
      )
        .toDate()
        .toISOString()
        .substr(0, 10);
      this.mainBookingsOpenDate = (
        this.$store.state.info.dates.main
          .bookingsOpen as firebase.default.firestore.Timestamp
      )
        .toDate()
        .toISOString()
        .substr(0, 10);
      this.mainEarlyBirdEndsDate = (
        this.$store.state.info.dates.main
          .earlyBirdEnds as firebase.default.firestore.Timestamp
      )
        .toDate()
        .toISOString()
        .substr(0, 10);
      this.mainWalkerNumbersLockedDate = (
        this.$store.state.info.dates.main
          .walkerNumbersLocked as firebase.default.firestore.Timestamp
      )
        .toDate()
        .toISOString()
        .substr(0, 10);
      this.mainBookingsCloseDate = (
        this.$store.state.info.dates.main
          .bookingsClose as firebase.default.firestore.Timestamp
      )
        .toDate()
        .toISOString()
        .substr(0, 10);
      this.virtualStartDate = (
        this.$store.state.info.dates.virtual
          .start as firebase.default.firestore.Timestamp
      )
        .toDate()
        .toISOString()
        .substr(0, 10);
      this.virtualEndDate = (
        this.$store.state.info.dates.virtual
          .end as firebase.default.firestore.Timestamp
      )
        .toDate()
        .toISOString()
        .substr(0, 10);
      this.shopCloseDate = (
        this.$store.state.info.dates
          .shopClose as firebase.default.firestore.Timestamp
      )
        .toDate()
        .toISOString()
        .substr(0, 10);
    },
    resetData() {
      const resetFirestore = functions.httpsCallable("resetFirestore");
      this.$store.commit("startLoading", "functions/resetFirestore");
      resetFirestore()
        .then(() => {
          this.$store.commit("finishLoading", "functions/resetFirestore");
          auth
            .signOut()
            .then(() => {
              this.$store.dispatch("clearData");
              this.$router.push("/login");
            })
            .catch((err: Error) => {
              console.error("Failed to logout: ", err);
            });
        })
        .catch((err) => {
          this.$store.commit("finishLoading", "functions/resetFirestore");
          this.$store.commit("setErrorMessage", `Failed to reset data: ${err}`);
        });
    },
  },
  watch: {
    eventDate() {
      this.getConfig();
    },
  },
  created() {
    this.getConfig();
  },
});
