
import { store } from "@/plugins/firebaseConfig";
import { VForm, VirtualWalker } from "@/types";
import { VirtualRoute as Route, Section } from "@/types/enums";
import { getRouteColour } from "@/utils/colours";
import Vue, { PropType } from "vue";

export default Vue.extend({
  data() {
    return {
      emptyWalker: {
        id: "",
        route: Route.BRONZE,
        firstName: "",
        lastName: "",
        section: Section.BEAVERS,
        activityLog: [],
        contact: "",
        linked: false,
        linkedWalkerNumber: -1,
        createdTimestamp: new store.Timestamp(0, 0),
        updatedTimestamp: new store.Timestamp(0, 0),
      } as VirtualWalker,
      options: {
        route: Object.values(Route).filter((v) => v !== ""),
      },
      sections: Object.values(Section).sort(),
      errorMessage: "",
      errorDialog: false,
    };
  },
  props: {
    walkerProp: { type: Object as PropType<VirtualWalker> },
  },
  computed: {
    walkerInfo(): VirtualWalker {
      if (this.walkerProp) {
        return this.walkerProp;
      } else {
        return this.emptyWalker;
      }
    },
    buttonText(): string {
      if (this.walkerProp) {
        return "Save Walker";
      } else {
        return "Add Walker";
      }
    },
    routeColour(): string {
      return getRouteColour(this.walkerInfo.route);
    },
    form(): VForm {
      return this.$refs.form as VForm;
    },
  },
  methods: {
    validateForm() {
      if (this.form.validate()) {
        this.addWalker();
      }
    },
    addWalker() {
      if (this.walkerInfo.id) {
        this.$store
          .dispatch("virtual/updateVirtualWalker", this.walkerInfo)
          .then(() => {
            this.$emit("exit", true);
          })
          .catch((err) => {
            throw err;
          });
      } else {
        // Walker doesn't exist, create new entry
        this.walkerInfo.contact = this.$store.state.user.credentials.uid;
        this.$store
          .dispatch("virtual/createVirtualWalker", this.walkerInfo)
          .then(() => {
            this.$emit("exit", true);
          })
          .catch((err) => {
            throw err;
          });
      }
    },
  },
});
