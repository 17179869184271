
import { Route } from "@/types/enums";
import { getRouteColour } from "@/utils/colours";
import Vue from "vue";

export default Vue.extend({
  data() {
    return {
      showStartGuide: false,
      startGuide: 0,
    };
  },
  computed: {
    isAdmin() {
      return this.$store.getters["user/isAdmin"];
    },
    isEnhancedStaff() {
      return this.$store.getters["user/isEnhancedStaff"];
    },
    isStaff() {
      return this.$store.getters["user/isStaff"];
    },
    isParent() {
      return this.$store.getters["user/isParent"];
    },
  },
  methods: {
    getColour(route: Route): string {
      return getRouteColour(route);
    },
  },
  watch: {
    showStartGuide() {
      if (this.showStartGuide === false && this.$route.query.welcome) {
        this.$router.replace("/");
      }
    },
  },
  created() {
    this.showStartGuide = this.$route.query.welcome ? true : false;
  },
});
