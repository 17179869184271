import { Route, VirtualRoute } from "@/types/enums";

export function getVirtualRouteLength(route: VirtualRoute): number {
  switch (route) {
    case VirtualRoute.BRONZE:
      return 10;
    case VirtualRoute.SILVER:
      return 20;
    case VirtualRoute.GOLD:
      return 30;
    case VirtualRoute.PLATINUM:
      return 50;
    default:
      return 0;
  }
}

export function getDistanceFromStart(checkpoint: string) {
  switch (checkpoint) {
    case "0":
      return 0;
    case "1":
      return 1.5;
    case "2":
      return 3.33;
    case "3":
      return 5.32;
    case "4":
      return 7.35;
    case "5":
      return 8.7;
    case "6":
      return 10.22;
    case "7":
      return 11.75;
    case "8":
      return 14.59;
    case "9":
      return 17.77;
    case "10":
      return 20.45;
    case "11":
      return 23.62;
    case "12":
      return 26.12;
    case "13":
      return 27.4;
    case "14":
      return 28.84;
    case "15":
      return 31.29;
    default:
      return 0;
  }
}

export function getFinish(route: Route) {
  switch (route) {
    case Route.BRONZE:
      return 6;
    case Route.SILVER:
      return 10;
    case Route.GOLD:
      return 15;
    default:
      return 0;
  }
}

export function getDate(time: string): Date {
  if (time > "12:00") {
    return new Date(`2025-02-08T${time}:00`);
  } else {
    return new Date(`2025-02-09T${time}:00`);
  }
}

export function getTimeToCheckpoint(checkpoint: number) {
  switch (checkpoint) {
    default:
      return 60 * 60 * 1000;
  }
}

export function getTimeToFinish(currentCheckpoint: number, route: Route) {
  const finishCP = getFinish(route);
  const checkpoints = [];
  for (let i = currentCheckpoint + 1; i <= finishCP; i++) {
    checkpoints.push(i);
  }
  return checkpoints.reduce(
    (total, cp) => (total += getTimeToCheckpoint(cp)),
    0
  );
}

export function getTotalTime(startTime: string, finishTime: string): string {
  const startTimeDate = new Date(`2025-02-08T${startTime}:00`);
  const finishTimeDate =
    finishTime > startTime
      ? new Date(`2025-02-08T${finishTime}:00`)
      : new Date(`2025-02-09T${finishTime}:00`);
  const diff = Math.abs(finishTimeDate.getTime() - startTimeDate.getTime());
  return new Date(diff).toISOString().slice(11, 16);
}

export function getCheckpointPosition(number: number) {
  switch (number) {
    case 0:
      return { lat: 51.836563, lng: -2.2590157 };
    case 1:
      return { lat: 51.834008, lng: -2.2337493 };
    case 2:
      return { lat: 51.832093, lng: -2.1996364 };
    case 3:
      return { lat: 51.808364, lng: -2.1954702 };
    case 4:
      return { lat: 51.816698, lng: -2.1532912 };
    case 5:
      return { lat: 51.814931, lng: -2.127754 };
    case 6:
      return { lat: 51.827541, lng: -2.1047181 };
    case 7:
      return { lat: 51.813707, lng: -2.0904701 };
    case 8:
      return { lat: 51.782255, lng: -2.0647493 };
    case 9:
      return { lat: 51.755359, lng: -2.0844134 };
    case 10:
      return { lat: 51.753242, lng: -2.1369958 };
    case 11:
      return { lat: 51.777192, lng: -2.1788119 };
    case 12:
      return { lat: 51.804237, lng: -2.1900863 };
    case 13:
      return { lat: 51.810678, lng: -2.2094051 };
    case 14:
      return { lat: 51.823065, lng: -2.2203443 };
    default:
      return { lat: 0, lng: 0 };
  }
}

export function getRoute() {
  return [
    { lat: 51.836563, lng: -2.2590157 },
    { lat: 51.834008, lng: -2.2337493 },
    { lat: 51.832093, lng: -2.1996364 },
    { lat: 51.808364, lng: -2.1954702 },
    { lat: 51.816698, lng: -2.1532912 },
    { lat: 51.814931, lng: -2.127754 },
    { lat: 51.827541, lng: -2.1047181 },
    { lat: 51.813707, lng: -2.0904701 },
    { lat: 51.782255, lng: -2.0647493 },
    { lat: 51.755359, lng: -2.0844134 },
    { lat: 51.753242, lng: -2.1369958 },
    { lat: 51.777192, lng: -2.1788119 },
    { lat: 51.804237, lng: -2.1900863 },
    { lat: 51.810678, lng: -2.2094051 },
    { lat: 51.823065, lng: -2.2203443 },
    { lat: 51.836563, lng: -2.2590157 },
  ];
}

export function getBronzeRoute() {
  return [
    { lat: 51.836563, lng: -2.2590157 },
    { lat: 51.834008, lng: -2.2337493 },
    { lat: 51.832093, lng: -2.1996364 },
    { lat: 51.808364, lng: -2.1954702 },
    { lat: 51.816698, lng: -2.1532912 },
    { lat: 51.814931, lng: -2.127754 },
    { lat: 51.827541, lng: -2.1047181 },
  ];
}

export function getSilverRoute() {
  return [
    { lat: 51.827541, lng: -2.1047181 },
    { lat: 51.813707, lng: -2.0904701 },
    { lat: 51.782255, lng: -2.0647493 },
    { lat: 51.755359, lng: -2.0844134 },
    { lat: 51.753242, lng: -2.1369958 },
  ];
}

export function getGoldRoute() {
  return [
    { lat: 51.753242, lng: -2.1369958 },
    { lat: 51.777192, lng: -2.1788119 },
    { lat: 51.804237, lng: -2.1900863 },
    { lat: 51.810678, lng: -2.2094051 },
    { lat: 51.823065, lng: -2.2203443 },
    { lat: 51.836563, lng: -2.2590157 },
  ];
}
