import { UserProfile, Walker } from "@/types";
import { jsPDF } from "jspdf";

export function createLabels(data: UserProfile[]) {
  const doc = new jsPDF({ unit: "mm" });
  doc.setFont("helvetica", "normal");
  doc.setFontSize(10);

  const pageCount = Math.ceil(data.length / 21);

  for (let i = 0; i < pageCount; i++) {
    const users = data.slice(i * 21, (i + 1) * 21);
    // drawConstructionLines(doc);
    generateLabelPage(doc, users);
    doc.addPage();
  }
  doc.deletePage(pageCount + 1);

  doc.save("labels.pdf");
}

function drawConstructionLines(doc: jsPDF) {
  doc.line(7.25, 0, 7.25, 297);
  doc.line(7.25 + 63.5, 0, 7.25 + 63.5, 297);
  doc.line(7.25 + 63.5 + 2.5, 0, 7.25 + 63.5 + 2.5, 297);
  doc.line(7.25 + 63.5 * 2 + 2.5, 0, 7.25 + 63.5 * 2 + 2.5, 297);
  doc.line(7.25 + 63.5 * 2 + 2.5 * 2, 0, 7.25 + 63.5 * 2 + 2.5 * 2, 297);
  doc.line(7.25 + 63.5 * 3 + 2.5 * 2, 0, 7.25 + 63.5 * 3 + 2.5 * 2, 297);

  doc.line(0, 15.15, 210, 15.15);
  doc.line(0, 15.15 + 38.1, 210, 15.15 + 38.1);
  doc.line(0, 15.15 + 38.1 * 2, 210, 15.15 + 38.1 * 2);
  doc.line(0, 15.15 + 38.1 * 3, 210, 15.15 + 38.1 * 3);
  doc.line(0, 15.15 + 38.1 * 4, 210, 15.15 + 38.1 * 4);
  doc.line(0, 15.15 + 38.1 * 5, 210, 15.15 + 38.1 * 5);
  doc.line(0, 15.15 + 38.1 * 6, 210, 15.15 + 38.1 * 6);
  doc.line(0, 15.15 + 38.1 * 7, 210, 15.15 + 38.1 * 7);
}

function generateLabelPage(doc: jsPDF, users: UserProfile[]) {
  for (let x = 0; x < 3; x++) {
    for (let y = 0; y < 7; y++) {
      if (!users[x * 7 + y]) {
        break;
      }
      generateLabel(
        doc,
        users[x * 7 + y],
        10 + x * (63.5 + 2.5),
        23 + y * 38.1
      );
    }
  }
}

function generateLabel(doc: jsPDF, user: UserProfile, x: number, y: number) {
  doc.text(`${user.firstName} ${user.lastName}`, x, y);
  const group = doc.splitTextToSize(user.group, 50);
  doc.text(group, x, (y += 4));
  doc.text(user.address.line1, x, (y += 4 * group.length));
  if (user.address.line2) {
    doc.text(user.address.line2, x, (y += 4));
  }
  if (user.address.town) {
    doc.text(user.address.town, x, (y += 4));
  }
  if (user.address.county) {
    doc.text(user.address.county, x, (y += 4));
  }
  doc.text(user.address.postcode, x, (y += 4));
}
