import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/functions";
import "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBJUQygPb7mkn2OcS_pbrX2DPNOOrNA_yY",
  authDomain: "cotswoldmarathon-ad342.firebaseapp.com",
  databaseURL: "https://cotswoldmarathon-ad342.firebaseio.com",
  projectId: "cotswoldmarathon-ad342",
  storageBucket: "cotswoldmarathon-ad342.appspot.com",
  messagingSenderId: "666577630560",
  appId: "1:666577630560:web:9edfb03370aefba1065a05",
  measurementId: "G-LFFN1NDC7W",
};
firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
const store = firebase.firestore;
const auth = firebase.auth();
const authentication = firebase.auth;
const functions = firebase.functions();
const storage = firebase.storage();
const currentUser = auth.currentUser;

if (location.hostname === "localhost") {
  db.useEmulator("localhost", 8081);
  functions.useEmulator("localhost", 5001);
  auth.useEmulator("http://localhost:9099");
  storage.useEmulator("localhost", 9199);
}

const usersCollection = db.collection("users");
const walkersCollection = db.collection("walkers");
const infoCollection = db.collection("info");
const teamsCollection = db.collection("teams");
const virtualWalkersCollection = db.collection("virtualWalkers");
const shopCollection = db.collection("shop");
const adminCollection = db.collection("admin");

export {
  db,
  store,
  auth,
  authentication,
  functions,
  storage,
  currentUser,
  usersCollection,
  walkersCollection,
  infoCollection,
  teamsCollection,
  virtualWalkersCollection,
  shopCollection,
  adminCollection,
  firebase,
};
