
import { storage } from "@/plugins/firebaseConfig";
import { ShopItem, ShopItemVariant } from "@/types";
import Vue, { PropType } from "vue";

export default Vue.extend({
  data() {
    return {
      imageUrl: "",
      quantities: [0],
    };
  },
  computed: {},
  methods: {
    incrementQuantity(variantName?: string) {
      if (variantName && variantName !== "") {
        const variantIndex = this.item.variants.findIndex(
          (v) => v.name === variantName
        );
        this.$emit(
          "adjustBalance",
          this.item.id,
          this.quantities[variantIndex] + 1,
          variantIndex
        );
        this.$set(
          this.quantities,
          variantIndex,
          this.quantities[variantIndex] + 1
        );
      } else {
        this.$emit("adjustBalance", this.item.id, this.quantities[0] + 1);
        this.$set(this.quantities, 0, this.quantities[0] + 1);
      }
    },
    decrementQuantity(variantName?: string) {
      if (variantName) {
        const variantIndex = this.item.variants.findIndex(
          (v) => v.name === variantName
        );
        if (this.quantities[variantIndex] > 0) {
          this.$emit(
            "adjustBalance",
            this.item.id,
            this.quantities[variantIndex] - 1,
            variantIndex
          );
          this.$set(
            this.quantities,
            variantIndex,
            this.quantities[variantIndex] - 1
          );
        }
      } else if (this.quantities[0] > 0) {
        this.$emit("adjustBalance", this.item.id, this.quantities[0] - 1);
        this.$set(this.quantities, 0, this.quantities[0] - 1);
      }
    },
    isItemSoldOut(item: ShopItem) {
      return item.quantitySold >= item.quantity;
    },
    isVariantSoldOut(variant: ShopItemVariant) {
      return variant.quantitySold >= variant.quantity;
    },
  },
  mounted() {
    this.quantities = this.initialQuantities;
    storage
      .ref(this.item.image)
      .getDownloadURL()
      .then((url) => {
        this.imageUrl = url;
      })
      .catch((err) => {
        console.log(err);
        this.imageUrl = "";
      });
  },
  props: {
    item: {
      type: Object as PropType<ShopItem>,
      required: true,
    },
    initialQuantities: Array as PropType<number[]>,
  },
});
