import jsPDF from "jspdf";
import { NUNITO_SANS_BLACK } from "./NunitoSans-Black-normal";
import { NUNITO_SANS_BLACK_ITALIC } from "./NunitoSans-BlackItalic-normal";
import { NUNITO_SANS_BOLD } from "./NunitoSans-Bold-normal";
import { NUNITO_SANS_BOLD_ITALIC } from "./NunitoSans-BoldItalic-normal";
import { NUNITO_SANS_EXTRA_BOLD } from "./NunitoSans-ExtraBold-normal";
import { NUNITO_SANS_EXTRA_BOLD_ITALIC } from "./NunitoSans-ExtraBoldItalic-normal";
import { NUNITO_SANS_EXTRA_LIGHT } from "./NunitoSans-ExtraLight-normal";
import { NUNITO_SANS_EXTRA_LIGHT_ITALIC } from "./NunitoSans-ExtraLightItalic-normal";
import { NUNITO_SANS_ITALIC } from "./NunitoSans-Italic-normal";
import { NUNITO_SANS_LIGHT } from "./NunitoSans-Light-normal";
import { NUNITO_SANS_LIGHT_ITALIC } from "./NunitoSans-LightItalic-normal";
import { NUNITO_SANS_MEDIUM } from "./NunitoSans-Medium-normal";
import { NUNITO_SANS_MEDIUM_ITALIC } from "./NunitoSans-MediumItalic-normal";
import { NUNITO_SANS_REGULAR } from "./NunitoSans-Regular-normal";
import { NUNITO_SANS_SEMI_BOLD } from "./NunitoSans-SemiBold-normal";
import { NUNITO_SANS_SEMI_BOLD_ITALIC } from "./NunitoSans-SemiBoldItalic-normal";

type Font = {
  filename: string;
  name: string;
  filecontent: string;
  ref: string;
};

const fonts = [
  {
    filename: "NunitoSans-Black-normal.ttf",
    name: "NunitoSans-Black",
    filecontent: NUNITO_SANS_BLACK,
    ref: "NUNITO_SANS_BLACK",
  },
  {
    filename: "NunitoSans-BlackItalic-normal.ttf",
    name: "NunitoSans-BlackItalic",
    filecontent: NUNITO_SANS_BLACK_ITALIC,
    ref: "NUNITO_SANS_BLACK_ITALIC",
  },
  {
    filename: "NunitoSans-Bold-normal.ttf",
    name: "NunitoSans-Bold",
    filecontent: NUNITO_SANS_BOLD,
    ref: "NUNITO_SANS_BOLD",
  },
  {
    filename: "NunitoSans-BoldItalic-normal.ttf",
    name: "NunitoSans-BoldItalic",
    filecontent: NUNITO_SANS_BOLD_ITALIC,
    ref: "NUNITO_SANS_BOLD_ITALIC",
  },
  {
    filename: "NunitoSans-ExtraBold-normal.ttf",
    name: "NunitoSans-ExtraBold",
    filecontent: NUNITO_SANS_EXTRA_BOLD,
    ref: "NUNITO_SANS_EXTRA_BOLD",
  },
  {
    filename: "NunitoSans-ExtraBoldItalic-normal.ttf",
    name: "NunitoSans-ExtraBoldItalic",
    filecontent: NUNITO_SANS_EXTRA_BOLD_ITALIC,
    ref: "NUNITO_SANS_EXTRA_BOLD_ITALIC",
  },
  {
    filename: "NunitoSans-ExtraLight-normal.ttf",
    name: "NunitoSans-ExtraLight",
    filecontent: NUNITO_SANS_EXTRA_LIGHT,
    ref: "NUNITO_SANS_EXTRA_LIGHT",
  },
  {
    filename: "NunitoSans-ExtraLightItalic-normal.ttf",
    name: "NunitoSans-ExtraLightItalic",
    filecontent: NUNITO_SANS_EXTRA_LIGHT_ITALIC,
    ref: "NUNITO_SANS_EXTRA_LIGHT_ITALIC",
  },
  {
    filename: "NunitoSans-Italic-normal.ttf",
    name: "NunitoSans-Italic",
    filecontent: NUNITO_SANS_ITALIC,
    ref: "NUNITO_SANS_ITALIC",
  },
  {
    filename: "NunitoSans-Light-normal.ttf",
    name: "NunitoSans-Light",
    filecontent: NUNITO_SANS_LIGHT,
    ref: "NUNITO_SANS_LIGHT",
  },
  {
    filename: "NunitoSans-LightItalic-normal.ttf",
    name: "NunitoSans-LightItalic",
    filecontent: NUNITO_SANS_LIGHT_ITALIC,
    ref: "NUNITO_SANS_LIGHT_ITALIC",
  },
  {
    filename: "NunitoSans-Medium-normal.ttf",
    name: "NunitoSans-Medium",
    filecontent: NUNITO_SANS_MEDIUM,
    ref: "NUNITO_SANS_MEDIUM",
  },
  {
    filename: "NunitoSans-MediumItalic-normal.ttf",
    name: "NunitoSans-MediumItalic",
    filecontent: NUNITO_SANS_MEDIUM_ITALIC,
    ref: "NUNITO_SANS_MEDIUM_ITALIC",
  },
  {
    filename: "NunitoSans-Regular-normal.ttf",
    name: "NunitoSans-Regular",
    filecontent: NUNITO_SANS_REGULAR,
    ref: "NUNITO_SANS_REGULAR",
  },
  {
    filename: "NunitoSans-SemiBold-normal.ttf",
    name: "NunitoSans-SemiBold",
    filecontent: NUNITO_SANS_SEMI_BOLD,
    ref: "NUNITO_SANS_SEMI_BOLD",
  },
  {
    filename: "NunitoSans-SemiBoldItalic-normal.ttf",
    name: "NunitoSans-SemiBoldItalic",
    filecontent: NUNITO_SANS_SEMI_BOLD_ITALIC,
    ref: "NUNITO_SANS_SEMI_BOLD_ITALIC",
  },
] as const;

function callAddFont(font: Font) {
  return function (this: jsPDF) {
    this.addFileToVFS(font.filename, font.filecontent);
    this.addFont(font.filename, font.name, "normal");
  };
}

const refs = fonts.map(({ ref }) => ref);

export default fonts
  .map((font) => {
    jsPDF.API.events.push(["addFonts", callAddFont(font)]);
    return font;
  })
  .reduce((acc, font) => {
    acc[font.ref] = font.name;
    return acc;
  }, {} as { [Key in typeof refs[number]]: string });
