
import moment from "moment";
import Vue from "vue";

export default Vue.extend({
  data() {
    return {};
  },
  computed: {
    earlyBirdEndsDate(): Date {
      const date = this.$store.state.info.dates.main.earlyBirdEnds.toDate();
      return date;
    },
    virtualStartDate() {
      const date = this.$store.state.info.dates.virtual.start.toDate();
      return date;
    },
    virtualEndDate() {
      const date = this.$store.state.info.dates.virtual.end.toDate();
      return date;
    },
  },
  methods: {
    getReadableDate(date: Date) {
      return moment(date).format("DD/MM/YYYY");
    },
  },
});
