import { ShopItem, UserProfile, Walker } from "@/types";
import { jsPDF } from "jspdf";
// @ts-ignore
import logo from "../assets/BOOT600.png";
import { calculateDeliveryCost, calculateBasketCost } from "./costs";

export function createInvoice(
  data: UserProfile,
  walkers: Walker[],
  totalCost: number,
  shopItems: ShopItem[]
) {
  const doc = new jsPDF({ unit: "pt" });
  const walkerCost = walkers.reduce((total, walker) => total + walker.cost, 0);

  doc.setFont("helvetica", "normal");

  if (totalCost - data.totalPaid <= 0) {
    generatePaidMark(doc);
  }

  generateHeader(doc, logo);
  generateCustomerInformation(doc, data, walkerCost, shopItems);
  generateInvoiceTable(doc, data, walkers, walkerCost, shopItems);
  generateFooter(doc);

  doc.save("invoice.pdf");
}

function generatePaidMark(doc: jsPDF) {
  doc.setTextColor("0.8");
  doc.setFontSize(200);
  doc.text("PAID", 150, 650, { angle: 30 });
  doc.setTextColor("0");
}

function generateHeader(doc: jsPDF, logo: string) {
  doc.addImage(logo, "PNG", 50, 45, 60, 48);
  doc.setFillColor("#444444");
  doc.setFontSize(20);
  doc.setFont("helvetica", "bold");
  doc.text("Cotswold Marathon", 120, 70);
  doc.setFontSize(10);
  doc.text("Cotswold Marathon", 550, 50, { align: "right" });
  doc.setFont("helvetica", "normal");
  doc.text("Gloucester District Scouts", 550, 65, { align: "right" });
  doc.text("Murray Hall", 550, 80, { align: "right" });
  doc.text("Tuffley Lane", 550, 95, { align: "right" });
  doc.text("Gloucester", 550, 110, { align: "right" });
  doc.text("GL4 0NU", 550, 125, { align: "right" });
}

function generateCustomerInformation(
  doc: jsPDF,
  data: UserProfile,
  walkerCost: number,
  shopItems: ShopItem[]
) {
  const address = data.address;

  doc.setFillColor("#444444");
  doc.setFontSize(20);
  doc.setFont("helvetica", "bold");
  doc.text("Invoice", 50, 160);

  generateHr(doc, 170);

  doc.setFontSize(10);
  doc.text(`Invoice Date: ${new Date().toDateString()}`, 50, 185);
  doc.text(
    `Balance Due: £${(
      calculateBasketCost(data.basket, shopItems) +
      calculateDeliveryCost(data.basket, data.deliveryMethod, shopItems) +
      walkerCost -
      data.totalPaid
    ).toFixed(2)}`,
    50,
    200
  );
  doc.text("Bank Details", 50, 220);
  doc.setFont("helvetica", "normal");
  doc.text(
    "Name: Gloucester District Scouts Cotswold Marathon Account",
    50,
    235
  );
  doc.text("Sort Code: 20-33-83", 50, 250);
  doc.text("Account Number: 00969656", 50, 265);
  doc.text(`Reference: ${getReference(data)}`, 50, 280);
  doc.setFontSize(10);
  doc.setFont("helvetica", "bold");
  doc.text(`${data.firstName} ${data.lastName}`, 550, 185, { align: "right" });
  doc.setFont("helvetica", "normal");
  doc.text(address.line1, 550, 200, { align: "right" });
  doc.text(address.line2, 550, 215, { align: "right" });
  doc.text(address.town, 550, 230, { align: "right" });
  doc.text(address.county, 550, 245, { align: "right" });
  doc.text(address.postcode, 550, 260, { align: "right" });

  generateHr(doc, 300);
}

function generateInvoiceTable(
  doc: jsPDF,
  data: UserProfile,
  walkers: Walker[],
  walkerCost: number,
  shopItems: ShopItem[]
) {
  const invoiceTableTop = 330;

  doc.setFont("helvetica", "bold");
  generateTableRow(
    doc,
    invoiceTableTop,
    "Item",
    "Unit Cost",
    "Quantity",
    "Line Total"
  );
  generateHr(doc, invoiceTableTop + 10);
  doc.setFont("helvetica", "normal");

  let position = invoiceTableTop + 10;

  const earlyWalkers = walkers.filter((walker) => walker.cost === 15);
  const otherWalkers = walkers.filter((walker) => walker.cost === 16);

  if (earlyWalkers.length > 0) {
    position += 15;

    generateTableRow(
      doc,
      position,
      "Walkers (Early Bird)",
      `£15.00`,
      earlyWalkers.length.toString(),
      `£${(earlyWalkers.length * 15).toFixed(2)}`
    );

    position += 10;

    generateHr(doc, position);
  }

  if (otherWalkers.length > 0) {
    position += 15;

    generateTableRow(
      doc,
      position,
      "Walkers",
      `£16.00`,
      otherWalkers.length.toString(),
      `£${(otherWalkers.length * 16).toFixed(2)}`
    );

    position += 10;

    generateHr(doc, position);
  }

  // const items = [
  //   {
  //     name: "badge",
  //     quantity: data.shopItems.badge,
  //   },
  //   {
  //     name: "necker",
  //     quantity: data.shopItems.necker,
  //   },
  //   {
  //     name: "meatBreakfast",
  //     quantity: data.shopItems.meatBreakfast,
  //   },
  //   {
  //     name: "vegBreakfast",
  //     quantity: data.shopItems.vegBreakfast,
  //   },
  //   {
  //     name: "bobbleHatBlack",
  //     quantity: data.shopItems.bobbleHatBlack,
  //   },
  //   {
  //     name: "bobbleHatGreen",
  //     quantity: data.shopItems.bobbleHatGreen,
  //   },
  //   {
  //     name: "bobbleHatGrey",
  //     quantity: data.shopItems.bobbleHatGrey,
  //   },
  //   {
  //     name: "bobbleHatWhite",
  //     quantity: data.shopItems.bobbleHatWhite,
  //   },
  // ].filter((v) => v.quantity && v.quantity != 0);

  const items = data.basket.filter((basketItem) => basketItem.quantity > 0);

  for (let i = 0; i < items.length; i++) {
    const item = items[i];
    position += 15;

    generateTableRow(
      doc,
      position,
      getName(item.id, item.variantIndex, shopItems),
      `£${getPrice(item.id, shopItems).toFixed(2)}`,
      item.quantity.toString(),
      `£${(getPrice(item.id, shopItems) * item.quantity).toFixed(2)}`
    );

    position += 10;

    generateHr(doc, position);
  }

  position += 15;
  generateTableRow(
    doc,
    position,
    "Delivery",
    "",
    "",
    `£${calculateDeliveryCost(
      data.basket,
      data.deliveryMethod,
      shopItems
    ).toFixed(2)}`
  );
  generateHr(doc, position + 10);
  generateHr(doc, position + 15);

  position += 30;
  generateTableRow(
    doc,
    position,
    "Total Paid",
    "",
    "",
    `- £${data.totalPaid.toFixed(2)}`
  );
  generateHr(doc, position + 10);

  doc.setFont("helvetica", "bold");

  position += 25;
  generateTableRow(
    doc,
    position,
    "Total",
    "",
    "",
    `£${(
      calculateBasketCost(data.basket, shopItems) +
      calculateDeliveryCost(data.basket, data.deliveryMethod, shopItems) +
      walkerCost -
      data.totalPaid
    ).toFixed(2)}`
  );
  generateHr(doc, position + 10);
  doc.setFont("helvetica", "normal");
}

function generateFooter(doc: jsPDF) {
  doc.setFontSize(10);
  doc.text(
    "Cotswold Marathon is an event organised on behalf of Gloucester District Scout Council (Charity Number 302111)",
    298,
    780,
    { align: "center" }
  );
}

function generateTableRow(
  doc: jsPDF,
  y: number,
  c1: string,
  c2: string,
  c3: string,
  c4: string
) {
  doc.setFontSize(10);
  doc.text(c1, 50, y);
  doc.text(c2, 370, y, { align: "right" });
  doc.text(c3, 460, y, { align: "right" });
  doc.text(c4, 550, y, { align: "right" });
}

function getPrice(id: string, items: ShopItem[]): number {
  const item = items.find((item) => item.id === id);
  if (item) {
    return item.cost;
  } else {
    return 0;
  }
}

function generateHr(doc: jsPDF, y: number) {
  doc.line(50, y, 550, y);
}

function getName(
  id: string,
  variantIndex: number | undefined,
  items: ShopItem[]
): string {
  const item = items.find((item) => item.id === id);
  if (!item) {
    return "";
  } else if (item.hasVariants) {
    return `${item.name} - ${item.variants[variantIndex ?? 0].name}`;
  } else {
    return item.name;
  }
}

function getReference(data: UserProfile) {
  return `${data.id.substring(0, 3)}-${data.id.substring(
    3,
    7
  )}-${data.id.substring(7, 10)}`.toUpperCase();
}
