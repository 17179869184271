
/* eslint-disable no-undef */
import { UserProfile, VirtualWalker } from "@/types";
import Vue from "vue";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  ArcElement,
} from "chart.js";
import { Pie } from "vue-chartjs";
import { Section } from "@/types/enums";
import { getSectionColour } from "@/utils/colours";
import {
  isInGloucesterDistrict,
  isInGloucestershireCounty,
} from "@/utils/groups";
import { createStatisticsExport } from "@/utils/statistics";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

export default Vue.extend({
  components: {
    Pie,
  },
  data() {
    return {
      labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18],
      checkpoints: [
        "Start",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "Finish",
      ],
    };
  },
  computed: {
    walkers(): VirtualWalker[] {
      return this.$store.state.virtual.virtualWalkers as VirtualWalker[];
    },
    sectionLabels(): string[] {
      return Object.values(Section);
    },
    sectionData(): { backgroundColor: string[]; data: number[] }[] {
      const backgroundColor = Object.values(Section).map((section) =>
        getSectionColour(section)
      );
      const data = Object.values(Section).map(
        (section) =>
          this.walkers.filter((walker) => walker.section === section).length
      );
      return [
        {
          backgroundColor,
          data,
        },
      ];
    },
    locationData(): { backgroundColor: string[]; data: number[] }[] {
      const gloucesterCount = this.walkers.filter((walker) =>
        isInGloucesterDistrict(this.getGroup(walker))
      ).length;
      const gloucestershireCount = this.walkers.filter(
        (walker) =>
          !isInGloucesterDistrict(this.getGroup(walker)) &&
          isInGloucestershireCounty(this.getGroup(walker))
      ).length;
      const outOfCountyCount = this.walkers.filter(
        (walker) => !isInGloucestershireCounty(this.getGroup(walker))
      ).length;
      return [
        {
          backgroundColor: ["#7413dc", "#088486", "#ff912a"],
          data: [gloucesterCount, gloucestershireCount, outOfCountyCount],
        },
      ];
    },
  },
  methods: {
    downloadCharts() {
      this.$store.commit("startLoading", "statistics/export");

      const chartNames = ["Section Split", "Group Location Split"];

      const images = chartNames.map((chartName) => {
        const chartRef =
          chartName
            .split(" ")
            .map((component, index) =>
              index === 0 ? component.toLowerCase() : component
            )
            .join("") + "Chart";
        const chartInstance = (this.$refs[chartRef] as any).$children[0].chart;
        return {
          name: chartName,
          image: chartInstance.toBase64Image(),
        };
      });

      createStatisticsExport(images).then(() =>
        this.$store.commit("finishLoading", "statistics/export")
      );
    },
    getContact(walker: VirtualWalker): UserProfile {
      return this.$store.getters["admin/getUser"](walker.contact);
    },
    getGroup(walker: VirtualWalker): string {
      return this.getContact(walker).group.trim();
    },
  },
});
