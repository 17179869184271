import { UserProfile } from "@/types";
import { PaymentStatus } from "@/types/enums";

export function getReference(id: string) {
  return `${id.substring(0, 3)}-${id.substring(3, 7)}-${id.substring(
    7,
    10
  )}`.toUpperCase();
}

export function getPaymentStatus(user: UserProfile, totalCost: number) {
  if (totalCost === 0) {
    return PaymentStatus.NONE;
  } else if (user.paymentSent) {
    return PaymentStatus.PAYMENT_SENT;
  } else if (totalCost <= user.totalPaid) {
    return PaymentStatus.PAID;
  } else if (user.totalPaid === 0) {
    return PaymentStatus.NOT_PAID;
  } else {
    return PaymentStatus.PARTIALLY_PAID;
  }
}
