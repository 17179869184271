
import Vue from "vue";
import { auth } from "../plugins/firebaseConfig";
import { VForm } from "@/types";
import RegisterForm from "@/components/RegisterForm.vue";
import PrivacyPolicy from "./PrivacyPolicy.vue";

enum State {
  NONE,
  LOGIN,
  REGISTER,
  PASSWORD_RESET,
}

export default Vue.extend({
  data() {
    return {
      State,
      loginForm: {
        email: "",
        password: "",
      },
      passwordForm: {
        email: "",
      },
      performingRequest: false,
      errorMessage: "",
      errorDialog: false,
      resetSentDialog: false,
      groupContactStepperPage: 1,
      currentState: State.NONE,
      role: "Parent",
      privacyPolicyDialog: false,
    };
  },
  computed: {},
  methods: {
    login() {
      if ((this.$refs.loginForm as VForm).validate()) {
        this.performingRequest = true;
        this.$store
          .dispatch("user/login", {
            email: this.loginForm.email,
            password: this.loginForm.password,
          })
          .then(() => {
            this.$router.push("/");
            this.performingRequest = false;
          })
          .catch((err) => {
            console.error("Login failed: ", err);
            this.performingRequest = false;
          });
      }
    },
    resetPassword() {
      this.performingRequest = true;
      this.$store.commit("startLoading", "auth/resetPassword");
      auth
        .sendPasswordResetEmail(this.passwordForm.email)
        .then(() => {
          this.resetSentDialog = true;
          this.passwordForm.email = "";
          this.$store.commit("finishLoading", "auth/resetPassword");
          this.performingRequest = false;
        })
        .catch((err) => {
          this.$store.commit("finishLoading", "auth/resetPassword");
          this.$store.commit(
            "setErrorMessage",
            `Failed to reset password: ${err}`
          );
          console.error("Failed to reset password: ", err);
          this.performingRequest = false;
        });
    },
    nextStep(formRef: string) {
      if ((this.$refs[formRef] as VForm).validate()) {
        this.groupContactStepperPage++;
      }
    },
    setState(state: State) {
      this.currentState = state;
    },
  },
  components: {
    RegisterForm,
    PrivacyPolicy
  },
});
