import { PaymentStatus, Route, Section, VirtualRoute } from "@/types/enums";

export function getPaymentStatusColour(status: PaymentStatus): string {
  switch (status) {
    case PaymentStatus.PAYMENT_SENT:
      return "primary";
    case PaymentStatus.PAID:
      return "success";
    case PaymentStatus.PARTIALLY_PAID:
      return "warning";
    case PaymentStatus.NOT_PAID:
      return "error";
    default:
      return "#ffffff";
  }
}

export function getRouteColour(route: Route | VirtualRoute): string {
  switch (route) {
    case Route.GOLD:
      return "#ffdf00";
    case Route.SILVER:
      return "#646464";
    case Route.BRONZE:
      return "#b1560f";
    case VirtualRoute.PLATINUM:
      return "#aaa9ad";
    default:
      return "#ffffff";
  }
}

export function getSectionColour(section: Section): string {
  switch (section) {
    case Section.SQUIRRELS:
      return "#e22e12";
    case Section.BEAVERS:
      return "#006ddf";
    case Section.CUBS:
      return "#23a950";
    case Section.SCOUTS:
      return "#004851";
    case Section.EXPLORERS:
      return "#003982";
    case Section.RAINBOWS:
      return "#abe1fa";
    case Section.BROWNIES:
      return "#ffd400";
    case Section.GUIDES:
      return "#0072bc";
    case Section.RANGERS:
      return "#009dad";
    case Section.ADULTS:
      return "#7413dc";
    case Section.SIBLINGS:
      return "#4e88c7";
  }
}
