
import Vue, { PropType } from "vue";
import { Activity, VirtualWalker } from "@/types";

export default Vue.extend({
  name: "ActivityLog",
  data() {
    return {
      headers: [
        {
          text: "Date",
          align: "start",
          value: "date",
        },
        {
          text: "Activity",
          value: "method",
        },
        {
          text: "Marathon Miles",
          value: "distance",
        },
        {
          text: "Actions",
          value: "actions",
        },
      ],
    };
  },
  methods: {
    formatDate(date: string) {
      if (date) {
        const dateArray = date.split("-");
        return `${dateArray[2]}/${dateArray[1]}/${dateArray[0]}`;
      } else {
        return "";
      }
    },
    deleteActivity(activity: Activity) {
      if (!this.walkerProp.id) {
        return;
      }
      const index = this.walkerProp.activityLog.indexOf(activity);
      const walker = this.walkerProp;
      walker.activityLog.splice(index, 1);
      this.$store.dispatch("virtual/updateVirtualWalker", walker);
    },
  },
  props: {
    walkerProp: {
      type: Object as PropType<VirtualWalker>,
      required: true,
    },
  },
  computed: {},
});
