
import { auth } from "@/plugins/firebaseConfig";
import { Role } from "@/types/enums";
import Vue from "vue";

interface Item {
  title: string;
  icon: string;
  restrictionLevel: Role;
  action: () => void;
}

export default Vue.extend({
  name: "Navigation",
  data() {
    return {
      mini: true,
      mainItems: [
        {
          title: "Dashboard",
          icon: "mdi-speedometer",
          restrictionLevel: Role.LEADER,
          action: () => {
            this.$router.push("/dashboard");
          },
        },
        {
          title: "Walkers",
          icon: "mdi-hiking",
          restrictionLevel: Role.LEADER,
          action: () => {
            this.$router.push("/walkers");
          },
        },
        {
          title: "Teams",
          icon: "mdi-account-group",
          restrictionLevel: Role.ADMIN,
          restricted: true,
          action: () => {
            this.$router.push("/teams");
          },
        },
        {
          title: "Event Management",
          icon: "mdi-flag",
          restrictionLevel: Role.ENHANCED_STAFF,
          action: () => {
            this.$router.push("/eventManagement");
          },
        },
        {
          title: "Tracking",
          icon: "mdi-map",
          restrictionLevel: Role.ADMIN,
          action: () => {
            this.$router.push("/tracking");
          },
        },
        {
          title: "Results",
          icon: "mdi-trophy",
          restrictionLevel: Role.ADMIN,
          action: () => {
            this.$router.push("/results");
          },
        },
        {
          title: "Statistics",
          icon: "mdi-chart-line",
          restrictionLevel: Role.ADMIN,
          action: () => {
            this.$router.push("/statistics");
          },
        },
      ] as Item[],
      virtualItems: [
        {
          title: "Dashboard",
          icon: "mdi-speedometer",
          restrictionLevel: Role.PARENT,
          action: () => {
            this.$router.push("/virtualDashboard");
          },
        },
        {
          title: "Walkers",
          icon: "mdi-hiking",
          restrictionLevel: Role.PARENT,
          action: () => {
            this.$router.push("/virtual");
          },
        },
        {
          title: "Results",
          icon: "mdi-trophy",
          restrictionLevel: Role.ADMIN,
          action: () => {
            this.$router.push("/virtualResults");
          },
        },
        {
          title: "Statistics",
          icon: "mdi-chart-line",
          restrictionLevel: Role.ADMIN,
          action: () => {
            this.$router.push("/virtualStatistics");
          },
        },
      ] as Item[],
      myItems: [
        {
          title: "My Profile",
          icon: "mdi-account-circle",
          restrictionLevel: Role.PARENT,
          action: () => {
            this.$router.push("/profile");
          },
        },
        {
          title: "Shop",
          icon: "mdi-cart",
          restrictionLevel: Role.PARENT,
          action: () => {
            this.$router.push("/shop");
          },
        },
        {
          title: "FAQ",
          icon: "mdi-help-circle",
          restrictionLevel: Role.PARENT,
          action: () => {
            this.$router.push("/faq");
          },
        },
      ] as Item[],
      adminItems: [
        {
          title: "Users",
          icon: "mdi-account",
          restrictionLevel: Role.ADMIN,
          action: () => {
            this.$router.push("/contacts");
          },
        },
        {
          title: "Orders",
          icon: "mdi-basket",
          restrictionLevel: Role.ADMIN,
          action: () => {
            this.$router.push("/orders");
          },
        },
        {
          title: "Shop Management",
          icon: "mdi-store-cog",
          restrictionLevel: Role.ADMIN,
          action: () => {
            this.$router.push("/shopManagement");
          },
        },
        {
          title: "Staff",
          icon: "mdi-account-hard-hat",
          restrictionLevel: Role.ADMIN,
          action: () => {
            this.$router.push("/staff");
          },
        },
        {
          title: "Config",
          icon: "mdi-wrench",
          restrictionLevel: Role.ADMIN,
          action: () => {
            this.$router.push("/config");
          },
        },
        {
          title: "Errors",
          icon: "mdi-bug",
          restrictionLevel: Role.ADMIN,
          action: () => {
            this.$router.push("/errors");
          },
        },
      ] as Item[],
      logoutItems: [
        {
          title: "Logout",
          icon: "mdi-logout",
          restrictionLevel: Role.PARENT,
          action: () => {
            auth
              .signOut()
              .then(() => {
                this.$store.dispatch("clearData");
                this.$router.push("/login");
              })
              .catch((err: Error) => {
                console.error("Failed to logout: ", err);
              });
          },
        },
      ] as Item[],
      localDrawer: false,
    };
  },
  computed: {
    isAdmin(): boolean {
      return this.$store.getters["user/isAdmin"];
    },
    isEnhancedStaff(): boolean {
      return this.$store.getters["user/isEnhancedStaff"];
    },
    isStaff(): boolean {
      return this.$store.getters["user/isStaff"];
    },
    isLeader(): boolean {
      return this.$store.getters["user/isLeader"];
    },
  },
  watch: {
    localDrawer(value: boolean): void {
      this.$emit("updateDrawerStatus", value);
    },
    show(value: boolean): void {
      this.localDrawer = value;
    },
  },
  props: {
    mobile: Boolean,
    show: Boolean,
  },
  methods: {
    hasClearance(restrictionLevel: Role) {
      if (this.isAdmin) {
        return true;
      } else if (this.isEnhancedStaff) {
        return (
          restrictionLevel === Role.PARENT ||
          restrictionLevel === Role.LEADER ||
          restrictionLevel === Role.STAFF ||
          restrictionLevel === Role.ENHANCED_STAFF
        );
      } else if (this.isStaff) {
        return (
          restrictionLevel === Role.PARENT ||
          restrictionLevel === Role.LEADER ||
          restrictionLevel === Role.STAFF
        );
      } else if (this.isLeader) {
        return (
          restrictionLevel === Role.PARENT || restrictionLevel === Role.LEADER
        );
      } else {
        return restrictionLevel === Role.PARENT;
      }
    },
  },
});
