var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-row',[_c('h1',{staticClass:"ml-4 mb-4 mt-2"},[_vm._v("Statistics")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"mb-4 mr-4 mt-2",attrs:{"id":"download-button"},on:{"click":function($event){return _vm.downloadCharts()}}},'v-icon',attrs,false),on),[_vm._v(" mdi-download ")])]}}])},[_c('span',[_vm._v("Download")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-card',{staticClass:"ma-4"},[_c('v-card-title',[_vm._v("Time Taken")]),_c('v-card-text',[_c('Bar',{ref:"timeTakenChart",attrs:{"data":{ labels: _vm.labels, datasets: _vm.timeGroupData },"options":{ responsive: true }}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-card',{staticClass:"ma-4"},[_c('v-card-title',[_vm._v("Checkpoints Reached")]),_c('v-card-text',[_c('Bar',{ref:"checkpointsReachedChart",attrs:{"data":{ labels: _vm.checkpoints, datasets: _vm.checkpointData },"options":{
              scales: { x: { stacked: true }, y: { stacked: true } },
              responsive: true,
            }}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-card',{staticClass:"ma-4"},[_c('v-card-title',[_vm._v("Booking Dates")]),_c('v-card-text',[_c('Bar',{ref:"bookingDatesChart",attrs:{"data":{ labels: _vm.bookingsLabels, datasets: _vm.bookingsData },"options":{
              scales: { x: { stacked: true }, y: { stacked: true } },
              responsive: true,
            }}})],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('v-card',{staticClass:"ma-4"},[_c('v-card-title',[_vm._v("Gender Split")]),_c('v-card-text',[_c('Pie',{ref:"genderSplitChart",attrs:{"data":{
              labels: ['Male', 'Female', 'Other/Not Given'],
              datasets: _vm.genderData,
            },"options":{
              responsive: true,
            }}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('v-card',{staticClass:"ma-4"},[_c('v-card-title',[_vm._v("Gender Split By Route")]),_c('v-card-text',[_c('Pie',{ref:"genderSplitByRouteChart",attrs:{"data":{
              labels: [
                'Bronze Male',
                'Bronze Female',
                'Bronze Other/Not Given',
                'Silver Male',
                'Silver Female',
                'Silver Other/Not Given',
                'Gold Male',
                'Gold Female',
                'Gold Other/Not Given',
              ],
              datasets: _vm.genderRouteData,
            },"options":{
              responsive: true,
            }}})],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('v-card',{staticClass:"ma-4"},[_c('v-card-title',[_vm._v("Group Location Split")]),_c('v-card-text',[_c('Pie',{ref:"groupLocationSplitChart",attrs:{"data":{
              labels: [
                'Gloucester',
                'Gloucestershire (not Gloucester)',
                'Out of County',
              ],
              datasets: _vm.locationData,
            },"options":{
              responsive: true,
            }}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('v-card',{staticClass:"ma-4"},[_c('v-card-title',[_vm._v("Group Location Split By Route")]),_c('v-card-text',[_c('Pie',{ref:"groupLocationSplitByRouteChart",attrs:{"data":{
              labels: [
                'Bronze Gloucester',
                'Bronze Gloucestershire (not Gloucester)',
                'Bronze Out of County',
                'Silver Gloucester',
                'Silver Gloucestershire (not Gloucester)',
                'Silver Out of County',
                'Gold Gloucester',
                'Gold Gloucestershire (not Gloucester)',
                'Gold Out of County',
              ],
              datasets: _vm.locationRouteData,
            },"options":{
              responsive: true,
            }}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }