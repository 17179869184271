import Vue from "vue";
import VueRouter from "vue-router";
import Dashboard from "../components/Dashboard.vue";
import Login from "../components/Login.vue";
import GroupContacts from "../components/GroupContacts.vue";
import Walkers from "../components/Walkers.vue";
import Teams from "../components/Teams.vue";
import Results from "../components/Results.vue";
import VirtualResultsTable from "../components/VirtualResultsTable.vue";
import Staff from "../components/Staff.vue";
import Validate from "../components/Validate.vue";
import Shop from "../components/Shop.vue";
import ShopManagement from "../components/ShopManagement.vue";
import Orders from "../components/Orders.vue";
import Profile from "../components/Profile.vue";
import Virtual from "../components/Virtual.vue";
import VirtualDashboard from "../components/VirtualDashboard.vue";
import Config from "../components/Config.vue";
import EventManagement from "../components/EventManagement.vue";
import Tracking from "../components/Tracking.vue";
import Home from "../components/Home.vue";
import FAQ from "../components/FAQ.vue";
import Errors from "../components/Errors.vue";
import Statistics from "../components/Statistics.vue";
import VirtualStatistics from "../components/VirtualStatistics.vue";
import { auth } from "../plugins/firebaseConfig";

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    redirect: "/",
  },
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/profile",
    name: "My Profile",
    component: Profile,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/contacts",
    name: "Group Contacts",
    component: GroupContacts,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/walkers",
    name: "Walkers",
    component: Walkers,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/teams",
    name: "Teams",
    component: Teams,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/results",
    name: "Results",
    component: Results,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/virtualResults",
    name: "Virtual Results",
    component: VirtualResultsTable,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/staff",
    name: "Staff",
    component: Staff,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/shop",
    name: "Shop",
    component: Shop,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/shopManagement",
    name: "ShopManagement",
    component: ShopManagement,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/orders",
    name: "Orders",
    component: Orders,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/validate",
    name: "Validate",
    component: Validate,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/virtual",
    name: "Virtual",
    component: Virtual,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/virtualDashboard",
    name: "Virtual Dashboard",
    component: VirtualDashboard,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/config",
    name: "Config",
    component: Config,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/eventManagement",
    name: "Event Management",
    component: EventManagement,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/tracking",
    name: "Tracking",
    component: Tracking,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/faq",
    name: "FAQs",
    component: FAQ,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/errors",
    name: "Errors",
    component: Errors,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/statistics",
    name: "Statistics",
    component: Statistics,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/virtualStatistics",
    name: "Virtual Statistics",
    component: VirtualStatistics,
    meta: {
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  routes: routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((x) => x.meta.requiresAuth);
  const currentUser = auth.currentUser;
  if (requiresAuth && !currentUser) {
    next("/login");
  } else {
    next();
  }
});

export default router;
