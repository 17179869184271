
import Vue from "vue";

import { ShopItem } from "@/types";
import ShopItemForm from "./ShopItemForm.vue";

export default Vue.extend({
  data() {
    return {
      headers: [
        {
          text: "Name",
          align: "start",
          value: "name",
        },
        {
          text: "Category",
          value: "category",
        },
        {
          text: "Cost",
          value: "cost",
        },
        {
          text: "Quantity",
          value: "quantity",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
        },
      ],
      emptyShopItem: {
        name: "",
        description: "",
        category: "",
        quantity: 0,
        quantitySold: 0,
        cost: 0,
        image: "",
        hasVariants: false,
        variants: [],
        id: "",
        deliveryCosts: [],
      } as ShopItem,
      shopItemForm: false,
      currentItem: {} as ShopItem,
    };
  },
  computed: {
    items(): ShopItem[] {
      return this.$store.state.shop.shopItems;
    },
  },
  methods: {
    editItem(item: ShopItem) {
      this.currentItem = item;
      this.shopItemForm = true;
    },
    newItem() {
      this.currentItem = this.emptyShopItem;
      this.shopItemForm = true;
    },
    closeForm() {
      this.shopItemForm = false;
    },
  },
  components: {
    ShopItemForm,
  },
});
