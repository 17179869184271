
import { Team, Walker } from "@/types";
import Vue, { PropType } from "vue";
export default Vue.extend({
  data() {
    return {
      editMode: false,
      teamNumber: "",
    };
  },
  methods: {
    getWalkerName(walkerNumber: number): string {
      return walkerNumber
        ? this.$store.getters["walkers/getWalkerName"](walkerNumber)
        : "";
    },
    getGroups(team: Team): string[] {
      return [
        ...new Set(
          team.walkers.map(
            (walkerNumber) =>
              (this.$store.getters["walkers/getWalker"](walkerNumber) as Walker)
                .group
          )
        ),
      ];
    },
  },
  props: {
    name: String,
    description: String,
    teams: Array as PropType<Team[]>,
    editable: {
      type: Boolean,
      default: false,
    },
  },
});
