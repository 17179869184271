
import { BasketItem, ShopItem, UserProfile } from "@/types";
import { DeliveryMethod } from "@/types/enums";
import Vue, { PropType } from "vue";
import { store } from "@/plugins/firebaseConfig";

interface Quantities {
  [id: string]: number[];
}

export default Vue.extend({
  data() {
    return {
      user: {
        basket: [] as BasketItem[],
        deliveryMethod: DeliveryMethod.POST,
      } as UserProfile,
      quantities: {} as Quantities,
      deliveryMethod: DeliveryMethod.POST,
    };
  },
  computed: {
    items(): ShopItem[] {
      return this.$store.state.shop.shopItems;
    },
    allUsers(): UserProfile[] {
      return this.$store.state.admin.users;
    },
  },
  methods: {
    save() {
      console.log(this.quantities);
      Object.keys(this.quantities)
        .flatMap((id) => {
          const quantity = this.quantities[id];
          if (quantity.length > 1) {
            console.log("variant");
            return quantity.map((value, index) => {
              return {
                id,
                quantity: value,
                variantIndex: index,
              };
            });
          } else {
            console.log("not");
            return {
              id,
              quantity: quantity[0],
              variantIndex: -1,
            };
          }
        })
        // .filter(({ quantity }) => quantity > 0)
        .map(({ id, quantity, variantIndex }) => {
          console.log(id, quantity, variantIndex);
          const basketItem = this.user.basket.find(
            (basketItem) =>
              basketItem.id === id &&
              (variantIndex === -1 || basketItem.variantIndex === variantIndex)
          );
          if (basketItem) {
            if (basketItem.quantity !== quantity) {
              basketItem.quantity = quantity;
              basketItem.updatedTimestamp = store.Timestamp.now();
              basketItem.delivered = false;
            }
          } else if (quantity > 0) {
            console.log("adding", id);
            if (variantIndex > -1) {
              this.user.basket.push({
                id,
                quantity,
                variantIndex,
                createdTimestamp: store.Timestamp.now(),
                updatedTimestamp: store.Timestamp.now(),
                delivered: false,
              });
            } else {
              this.user.basket.push({
                id,
                quantity,
                createdTimestamp: store.Timestamp.now(),
                updatedTimestamp: store.Timestamp.now(),
                delivered: false,
              });
            }
          }
        });
      this.user.deliveryMethod = this.deliveryMethod;
      console.log(this.user);
      console.log(this.user.basket);
      this.$store
        .dispatch("admin/updateUser", this.user)
        .then(() => {
          this.$emit("exit", true);
        })
        .catch((err) => {
          console.error(err);
        });
    },
    updateQuantities() {
      this.user = this.userProp;
      console.log("user changed");
      this.items.map((item) => {
        const newQuantities = [];
        if (item.hasVariants) {
          this.quantities[item.id] = [];
          item.variants.map((variant, index) => {
            newQuantities.push(
              this.user.basket.find(
                (basketItem) =>
                  basketItem.id === item.id && basketItem.variantIndex === index
              )?.quantity ?? 0
            );
          });
        } else {
          newQuantities.push(
            this.user.basket.find((basketItem) => basketItem.id === item.id)
              ?.quantity ?? 0
          );
        }
        this.$set(this.quantities, item.id, newQuantities);
      });
      this.deliveryMethod = this.user.deliveryMethod;
      console.log(this.quantities);
    },
  },
  beforeMount() {
    this.user = this.userProp;
    this.items.map((item) => {
      const newQuantities = [];
      if (item.hasVariants) {
        this.quantities[item.id] = [];
        item.variants.map((variant, index) => {
          newQuantities.push(
            this.user.basket.find(
              (basketItem) =>
                basketItem.id === item.id && basketItem.variantIndex === index
            )?.quantity ?? 0
          );
        });
      } else {
        newQuantities.push(
          this.user.basket.find((basketItem) => basketItem.id === item.id)
            ?.quantity ?? 0
        );
      }
      this.$set(this.quantities, item.id, newQuantities);
    });
    this.deliveryMethod = this.user.deliveryMethod;
  },
  watch: {
    userProp() {
      this.user = this.userProp;
      this.updateQuantities();
    },
  },
  props: {
    userProp: {
      type: Object as PropType<UserProfile>,
      required: true,
    },
    newOrder: Boolean,
  },
});
