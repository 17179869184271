
import { UserProfile, VirtualWalker } from "@/types";
import { VirtualRoute as Route } from "@/types/enums";
import { getRouteColour } from "@/utils/colours";
import { getVirtualRouteLength } from "@/utils/route";
import Vue from "vue";
export default Vue.extend({
  data() {
    return {
      bronzeColour: getRouteColour(Route.BRONZE),
      silverColour: getRouteColour(Route.SILVER),
      goldColour: getRouteColour(Route.GOLD),
      platinumColour: getRouteColour(Route.PLATINUM),
    };
  },
  computed: {
    walkers(): VirtualWalker[] {
      return this.$store.state.virtual.virtualWalkers;
    },
    user(): UserProfile {
      return this.$store.state.user.profile;
    },
    totalToBePaid(): number {
      if (this.$store.getters["user/isAdmin"]) {
        return this.$store.getters["admin/totalOutstandingBalance"];
      } else {
        return this.$store.getters["user/outstandingBalance"];
      }
    },
    totalPaid(): number {
      if (this.$store.getters["user/isAdmin"]) {
        return this.$store.getters["admin/totalPaid"];
      } else {
        return this.user.totalPaid;
      }
    },
    totalDistanceAllWalkers(): number {
      return this.$store.getters["virtual/totalDistanceAllWalkers"].toFixed(2);
    },
    averageDistance(): string {
      if (this.walkers.length === 0) {
        return "0.00";
      }
      return (this.totalDistanceAllWalkers / this.walkers.length).toFixed(2);
    },
    pageTitle(): string {
      if (this.$store.getters["user/isAdmin"]) {
        return "Dashboard";
      } else {
        return "Team Dashboard";
      }
    },
  },
  methods: {
    totalDistance(walker: VirtualWalker): number {
      return this.$store.getters["virtual/totalDistance"](walker.id);
    },
    routeDistance(walker: VirtualWalker): number {
      return getVirtualRouteLength(walker.route);
    },
    formatMoney(value: number) {
      return value ? `£${value.toFixed(2)}` : "£0.00";
    },
  },
});
