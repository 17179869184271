import { store } from "@/plugins/firebaseConfig";
import { Team, Trophy, Walker } from "@/types";
import { Gender, Route } from "@/types/enums";

const emptyTeam = {
  route: Route.BRONZE,
  walkers: [],
  additionalWalkers: [],
  startTime: "",
  finishTime: "",
  id: "",
  isBroken: false,
  totalTime: "",
  contact: "",
  teamNumber: 0,
  trackerId: "1",
  createdTimestamp: new store.Timestamp(0, 0),
  updatedTimestamp: new store.Timestamp(0, 0),
} as Team;

export function getTrophies(
  teams: Team[],
  walkers: Walker[],
  sobleTeamNumbers: number[]
): Trophy[] {
  const bronzeTrophies = getBronzeTrophies(teams, walkers);
  const silverTrophies = getSilverTrophies(teams, walkers);
  const goldTrophies = getGoldTrophies(teams, walkers);
  const sobleTrophy = getSobleTrophy(teams, sobleTeamNumbers);
  return [...bronzeTrophies, ...silverTrophies, ...goldTrophies];
}

export function getBronzeTrophies(teams: Team[], walkers: Walker[]): Trophy[] {
  const bronzeTeams = teams.filter((team) => {
    const teamMembers = getWalkersForTeam(team, walkers);
    return (
      team.route === Route.BRONZE &&
      team.totalTime &&
      !team.isBroken &&
      !teamMemberDisqualified(teamMembers) &&
      getMinTeamAge(teamMembers) >= 11 &&
      getMaxTeamAge(teamMembers) < 14
    );
  });

  const bronzeWinners = getFastestTeams(bronzeTeams);
  const bronzeRunnersUp = bronzeTeams.filter(
    (team) => !bronzeWinners.includes(team)
  );

  const maleRunnersUp = bronzeRunnersUp.filter(
    (team) => getTeamGender(getWalkersForTeam(team, walkers)) === Gender.MALE
  );

  const femaleRunnersUp = bronzeRunnersUp.filter(
    (team) => getTeamGender(getWalkersForTeam(team, walkers)) === Gender.FEMALE
  );

  const mixedRunnersUp = bronzeRunnersUp.filter(
    (team) => getTeamGender(getWalkersForTeam(team, walkers)) === Gender.MIXED
  );

  return [
    {
      name: "Gait Trophy",
      description: "Fastest Bronze team",
      teams: bronzeWinners,
    },
    {
      name: "Male Trophy",
      description: "Fastest all-male Bronze runners up",
      teams: getFastestTeams(maleRunnersUp),
    },
    {
      name: "Female Trophy",
      description: "Fastest all-female Bronze runners up",
      teams: getFastestTeams(femaleRunnersUp),
    },
    {
      name: "Di Large Trophy",
      description: "Fastest mixed Bronze runners up",
      teams: getFastestTeams(mixedRunnersUp),
    },
  ];
}

export function getSilverTrophies(teams: Team[], walkers: Walker[]): Trophy[] {
  const silverTeams = teams.filter((team) => {
    const teamMembers = getWalkersForTeam(team, walkers);
    return (
      team.route === Route.SILVER &&
      team.totalTime &&
      !team.isBroken &&
      !teamMemberDisqualified(teamMembers) &&
      getMinTeamAge(teamMembers) >= 13 &&
      getMaxTeamAge(teamMembers) < 18
    );
  });

  const silverWinners = getFastestTeams(silverTeams);
  const silverRunnersUp = silverTeams.filter(
    (team) => !silverWinners.includes(team)
  );

  const maleRunnersUp = silverRunnersUp.filter(
    (team) => getTeamGender(getWalkersForTeam(team, walkers)) === Gender.MALE
  );

  const femaleRunnersUp = silverRunnersUp.filter(
    (team) => getTeamGender(getWalkersForTeam(team, walkers)) === Gender.FEMALE
  );

  const mixedRunnersUp = silverRunnersUp.filter(
    (team) => getTeamGender(getWalkersForTeam(team, walkers)) === Gender.MIXED
  );

  return [
    {
      name: "Keith Aston Cup",
      description: "Fastest Silver team",
      teams: silverWinners,
    },
    {
      name: "Tony Day Trophy",
      description: "Fastest all-male Silver runners up",
      teams: getFastestTeams(maleRunnersUp),
    },
    {
      name: "Gloucester Division Girls Trophy",
      description: "Fastest all-female Silver runners up",
      teams: getFastestTeams(femaleRunnersUp),
    },
    {
      name: "Sealey Trophy",
      description: "Fastest mixed Silver runners up",
      teams: getFastestTeams(mixedRunnersUp),
    },
  ];
}

export function getGoldTrophies(teams: Team[], walkers: Walker[]): Trophy[] {
  const goldTeams = teams
    .filter((team) => {
      const teamMembers = getWalkersForTeam(team, walkers);
      return (
        team.route === Route.GOLD &&
        team.totalTime &&
        !team.isBroken &&
        !teamMemberDisqualified(teamMembers) &&
        getMinTeamAge(teamMembers) >= 14
      );
    })
    .sort(
      (a, b) => getTimeInMinutes(a.totalTime) - getTimeInMinutes(b.totalTime)
    );

  const goldWinners = getFastestTeams(goldTeams);
  const goldRunnersUp = goldTeams.filter((team) => !goldWinners.includes(team));

  const juniorRunnersUp = goldRunnersUp.filter((team) => {
    const teamMembers = getWalkersForTeam(team, walkers);
    return getMinTeamAge(teamMembers) >= 14 && getMaxTeamAge(teamMembers) < 18;
  });

  const seniorRunnersUp = goldRunnersUp.filter((team) => {
    const teamMembers = getWalkersForTeam(team, walkers);
    return getMinTeamAge(teamMembers) >= 18 && getMaxTeamAge(teamMembers) < 25;
  });

  const cotswoldRunnersUp = goldRunnersUp.filter(
    (team) => getMinTeamAge(getWalkersForTeam(team, walkers)) >= 25
  );

  const youthFemaleRunnersUp = goldRunnersUp.filter((team) => {
    const teamMembers = getWalkersForTeam(team, walkers);
    return (
      getTeamGender(teamMembers) === Gender.FEMALE &&
      getMinTeamAge(teamMembers) >= 14 &&
      getMaxTeamAge(teamMembers) < 25
    );
  });

  const cotswoldFemaleRunnersUp = goldRunnersUp.filter((team) => {
    const teamMembers = getWalkersForTeam(team, walkers);
    return (
      getTeamGender(teamMembers) === Gender.FEMALE &&
      getMinTeamAge(teamMembers) >= 25
    );
  });

  return [
    {
      name: "Lowrey Trophy",
      description: "Fastest Gold team",
      teams: goldWinners,
    },
    {
      name: "Junior Trophy",
      description: "Fastest Gold runners up with walkers aged 14-18",
      teams: getFastestTeams(juniorRunnersUp),
    },
    {
      name: "Senior Trophy",
      description: "Fastest Gold runners up with walkers aged 18-25",
      teams: getFastestTeams(seniorRunnersUp),
    },
    {
      name: "Justin Bailey (Lonsdale) Trophy",
      description: "Fastest Gold runners up with walkers aged over 25",
      teams: getFastestTeams(cotswoldRunnersUp),
    },
    {
      name: "Lasses Trophy",
      description: "Fastest all-female Gold runners up with walkers aged 14-25",
      teams: getFastestTeams(youthFemaleRunnersUp),
    },
    {
      name: "Ladies Trophy",
      description:
        "Fastest all-female Gold runners up with walkers aged over 25",
      teams: getFastestTeams(cotswoldFemaleRunnersUp),
    },
  ];
}

export function getSobleTrophy(teams: Team[], teamNumbers: number[]): Trophy {
  return {
    name: "Soble Trophy",
    description:
      "Awarded at the discretion of the organisers to an outstanding team who complete the marathon and do not qualify for any other trophy",
    teams: teamNumbers.flatMap((teamNumber) => {
      const foundTeam = teams.find((team) => team.teamNumber === teamNumber);
      return foundTeam ? [foundTeam] : [];
    }),
  };
}

function getTimeInMinutes(timeString: string): number {
  const timeArray = timeString.split(":");
  return parseInt(timeArray[0]) * 60 + parseInt(timeArray[1]);
}

export function getTeamGender(teamMembers: Walker[]): Gender {
  const teamGenders = teamMembers
    .map((walker) => walker.gender)
    .reduce((a, b) => (b === a ? a : Gender.MIXED));
  return teamGenders;
}

function getMinTeamAge(teamMembers: Walker[]): number {
  return Math.min(...teamMembers.map((w) => w.age));
}

function getMaxTeamAge(teamMembers: Walker[]): number {
  return Math.max(...teamMembers.map((w) => w.age));
}

function getWalkersForTeam(team: Team, walkers: Walker[]) {
  return team.walkers.map(
    (walkerNumber) =>
      walkers.find((w) => w.walkerNumber === walkerNumber) as Walker
  );
}

function teamMemberDisqualified(walkers: Walker[]) {
  return walkers
    .map((walker) => !!walker.disqualified)
    .reduce(
      (teamDisqualified, walkerDisqualified) =>
        teamDisqualified || walkerDisqualified,
      false
    );
}

function getFastestTeams(teams: Team[]) {
  return teams
    .sort(
      (a, b) => getTimeInMinutes(a.totalTime) - getTimeInMinutes(b.totalTime)
    )
    .reduce((teams, team) => {
      if (teams.length <= 0) {
        return [team];
      } else if (teams[0].totalTime === team.totalTime) {
        return [...teams, team];
      }
      return teams;
    }, [] as Team[]);
}
