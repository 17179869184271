
/* eslint-disable no-undef */
import { functions } from "@/plugins/firebaseConfig";
import { VForm, VMenu } from "@/types";
import moment from "moment";
import Vue from "vue";

enum FinishType {
  FINISHED,
  RETIRED,
  DISQUALIFIED,
}

export default Vue.extend({
  data() {
    return {
      time: "",
      startTimeMenu: false,
      checkpointTimeMenu: false,
      finishTimeMenu: false,
      walker1: "",
      walker2: "",
      walker3: "",
      additionalWalker1: "",
      additionalWalker2: "",
      startDialog: false,
      walker: "",
      checkpoint: "",
      checkpointDialog: false,
      FinishType,
      finishType: FinishType.FINISHED,
      finishDialog: false,
      team: "",
      tracker: "",
      trackerDialog: false,
      calculatorDialog: false,
      startTime: "",
      finishTime: "",
    };
  },
  computed: {
    startForm(): VForm {
      return this.$refs.startForm as VForm;
    },
    checkpointForm(): VForm {
      return this.$refs.checkpointForm as VForm;
    },
    finishForm(): VForm {
      return this.$refs.finishForm as VForm;
    },
    trackerForm(): VForm {
      return this.$refs.trackerForm as VForm;
    },
    startTimeMenuRef(): VMenu {
      return this.$refs.startTimeMenuRef as VMenu;
    },
    checkpointTimeMenuRef(): VMenu {
      return this.$refs.checkpointTimeMenuRef as VMenu;
    },
    finishTimeMenuRef(): VMenu {
      return this.$refs.finishTimeMenuRef as VMenu;
    },
    eventDate(): Date {
      return (
        this.$store.state.info.dates.main
          .event as firebase.default.firestore.Timestamp
      ).toDate();
    },
    totalTime(): string {
      const [startHour, startMinute] = this.startTime.split(":");
      const [finishHour, finishMinute] = this.finishTime.split(":");
      const startTimeDate = moment(this.eventDate)
        .add(parseInt(startHour), "hours")
        .add(parseInt(startMinute), "minutes");
      const finishTimeDate =
        this.finishTime > this.startTime
          ? moment(this.eventDate)
              .add(parseInt(finishHour), "hours")
              .add(parseInt(finishMinute), "minutes")
          : moment(this.eventDate)
              .add(1, "day")
              .add(parseInt(finishHour), "hours")
              .add(parseInt(finishMinute), "minutes");
      const diff = finishTimeDate
        .subtract(startTimeDate.hours(), "hours")
        .subtract(startTimeDate.minutes(), "minutes")
        .format("HH:mm");
      return diff;
    },
  },
  methods: {
    addTeam(closeDialog: boolean) {
      if (!this.startForm.validate()) {
        return;
      }

      const walkers = [this.walker1, this.walker2, this.walker3].map((w) =>
        parseInt(w)
      );
      const additionalWalkers = [this.additionalWalker1, this.additionalWalker2]
        .filter((w) => w)
        .map((w) => parseInt(w));

      let startTeam = functions.httpsCallable("startTeam");
      this.$store.commit("startLoading", "functions/startTeam");
      startTeam({
        walkers: walkers,
        additionalWalkers: additionalWalkers,
        startTime: this.time,
        trackerId: this.tracker,
      })
        .then(() => {
          this.$store.commit("finishLoading", "functions/startTeam");
          this.startDialog = !closeDialog;
          this.walker1 = "";
          this.walker2 = "";
          this.walker3 = "";
          this.additionalWalker1 = "";
          this.additionalWalker2 = "";
          this.time = "";
          this.tracker = (parseInt(this.tracker) + 1).toString();
        })
        .catch((err) => {
          this.$store.commit("finishLoading", "functions/startTeam");
          this.$store.commit("setErrorMessage", `Failed to start team: ${err}`);
        });
    },
    logProgress(closeDialog: boolean) {
      if (!this.checkpointForm.validate()) {
        return;
      }

      let logWalkerProgress = functions.httpsCallable("logWalkerProgress");
      this.$store.commit("startLoading", "functions/logWalkerProgress");
      logWalkerProgress({
        walkerNumber: parseInt(this.walker),
        checkpoint: this.checkpoint,
        time: this.time,
      })
        .then(() => {
          this.$store.commit("finishLoading", "functions/logWalkerProgress");
          this.checkpointDialog = !closeDialog;
          this.walker = "";
        })
        .catch((err) => {
          this.$store.commit("finishLoading", "functions/logWalkerProgress");
          this.$store.commit(
            "setErrorMessage",
            `Failed to log progress: ${err}`
          );
        });
    },
    finish(closeDialog: boolean) {
      if (!this.finishForm.validate()) {
        return;
      }

      if (this.finishType === FinishType.RETIRED) {
        let retireWalker = functions.httpsCallable("retireWalker");
        this.$store.commit("startLoading", "functions/retireWalker");
        retireWalker({
          walkerNumber: parseInt(this.walker),
          checkpoint: this.checkpoint,
        })
          .then(() => {
            this.$store.commit("finishLoading", "functions/retireWalker");
            this.finishDialog = !closeDialog;
          })
          .catch((err) => {
            this.$store.commit("finishLoading", "functions/retireWalker");
            this.$store.commit(
              "setErrorMessage",
              `Failed to retire walker: ${err}`
            );
          });
      } else {
        let finishWalker = functions.httpsCallable("finishWalker");
        this.$store.commit("startLoading", "functions/finishWalker");
        finishWalker({
          walkerNumber: parseInt(this.walker),
          time: this.time,
          disqualified: this.finishType === FinishType.DISQUALIFIED,
        })
          .then(() => {
            this.$store.commit("finishLoading", "functions/finishWalker");
            this.finishDialog = !closeDialog;
          })
          .catch((err) => {
            this.$store.commit("finishLoading", "functions/finishWalker");
            this.$store.commit(
              "setErrorMessage",
              `Failed to finish walker: ${err}`
            );
          });
      }
    },
    addTracker(closeDialog: boolean) {
      if (!this.trackerForm.validate()) {
        return;
      }

      let addTracker = functions.httpsCallable("addTracker");
      this.$store.commit("startLoading", "functions/addTracker");
      addTracker({
        teamNumber: parseInt(this.team),
        tracker: this.tracker,
      })
        .then(() => {
          this.$store.commit("finishLoading", "functions/addTracker");
          this.trackerDialog = !closeDialog;
          this.team = "";
          this.tracker = (parseInt(this.tracker) + 1).toString();
        })
        .catch((err) => {
          this.$store.commit("finishLoading", "functions/addTracker");
          this.$store.commit(
            "setErrorMessage",
            `Failed to add tracker: ${err}`
          );
        });
    },
    getCurrentTime() {
      const now = new Date();
      return now.toTimeString().slice(0, 5);
    },
  },
});
