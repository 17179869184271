import {
  infoCollection,
  store,
  teamsCollection,
} from "@/plugins/firebaseConfig";
import { Team, Trophy, Walker } from "@/types";
import {
  getBronzeTrophies,
  getGoldTrophies,
  getSilverTrophies,
  getSobleTrophy,
  getTrophies,
} from "@/utils/trophies";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { TeamState, RootState } from "../types";

const namespaced: boolean = true;

export const state: TeamState = {
  teams: [],
};

export const getters: GetterTree<TeamState, RootState> = {
  getTeamByCoreWalker:
    (state, getters, rootState) =>
    (walkerNumber: number): Team => {
      const team = state.teams.find((team) => {
        return team.walkers.includes(walkerNumber);
      });
      if (team) {
        return team;
      } else {
        rootState.errorMessage = `Cannot find team with walker number ${walkerNumber}`;
        return {} as Team;
      }
    },
  getTeamByWalker:
    (state, getters, rootState) =>
    (walkerNumber: number, showErrorIfTeamNotFound = true): Team => {
      const team = state.teams.find((team) => {
        return team.walkers
          .concat(team.additionalWalkers)
          .includes(walkerNumber);
      });
      if (team) {
        return team;
      } else {
        if (showErrorIfTeamNotFound) {
          rootState.errorMessage = `Cannot find team with walker number ${walkerNumber}`;
        }
        return {} as Team;
      }
    },
  isCoreWalker:
    (state) =>
    (walkerNumber: number): boolean => {
      const team = state.teams.find((team) => {
        return team.walkers.includes(walkerNumber);
      });
      if (team) {
        return true;
      } else {
        return false;
      }
    },
  isInTeam:
    (state) =>
    (walkerNumber: number): boolean => {
      const team = state.teams.find((team) => {
        return team.walkers
          .concat(team.additionalWalkers)
          .includes(walkerNumber);
      });
      if (team) {
        return true;
      } else {
        return false;
      }
    },
  getTracker:
    (state, getters, rootState, rootGetters) =>
    (id: string): string => {
      const team = state.teams.find((t) => t.id === id);
      const trackerId = team?.trackerId;
      return trackerId || "";
    },
  getTrophies: (state, getters, rootState) => {
    return getTrophies(
      state.teams,
      rootState.walkers?.walkers as Walker[],
      rootState.info?.trophies.soble ?? []
    );
  },
  getBronzeTrophies: (state, getters, rootState) => {
    return getBronzeTrophies(
      state.teams,
      rootState.walkers?.walkers as Walker[]
    );
  },
  getSilverTrophies: (state, getters, rootState) => {
    return getSilverTrophies(
      state.teams,
      rootState.walkers?.walkers as Walker[]
    );
  },
  getGoldTrophies: (state, getters, rootState) => {
    return getGoldTrophies(state.teams, rootState.walkers?.walkers as Walker[]);
  },
  getSobleTrophy: (state, getters, rootState) => {
    return getSobleTrophy(state.teams, rootState.info?.trophies.soble ?? []);
  },
  getTrophiesForTeam:
    (state, getters) =>
    (teamNumber: number): Trophy[] => {
      return (getters.getTrophies as Trophy[]).filter((trophy) =>
        trophy.teams.some((team) => team.teamNumber === teamNumber)
      );
    },
};

export const actions: ActionTree<TeamState, RootState> = {
  fetchTeams(context) {
    context.commit("startLoading", "teams/fetchTeams", { root: true });
    if (context.rootGetters["user/isAdmin"]) {
      return teamsCollection
        .get()
        .then((query) => {
          const docs = query.docs;
          const teams = docs.map((x) => {
            const data = x.data();
            data.id = x.id;
            return data;
          });
          context.commit(
            "setTeams",
            teams.sort((a, b) => {
              return a.teamNumber - b.teamNumber;
            })
          );
          context.commit("finishLoading", "teams/fetchTeams", { root: true });
        })
        .catch((err) => {
          context.commit("finishLoading", "teams/fetchTeams", { root: true });
          context.commit(
            "setErrorMessage",
            `Cannot fetch teams (admin): ${err}`,
            { root: true }
          );
        });
    } else {
      // TODO: Implement logic to retrieve teamArray
      const id = context.rootState.user?.credentials.uid;

      return teamsCollection
        .where("contact", "==", id)
        .get()
        .then((query) => {
          const docs = query.docs;
          const teams = docs.map((x) => {
            const data = x.data();
            data.id = x.id;
            return data;
          });
          context.commit(
            "setTeams",
            teams.sort((a, b) => {
              return a.teamNumber - b.teamNumber;
            })
          );
          context.commit("finishLoading", "teams/fetchTeams", { root: true });
        })
        .catch((err) => {
          context.commit("finishLoading", "teams/fetchTeams", { root: true });
          context.commit(
            "setErrorMessage",
            `Cannot fetch teams (user): ${err}`,
            { root: true }
          );
        });
    }
  },
  getNextTeamNumber(context) {
    context.commit("startLoading", "teams/getNextTeamNumber", { root: true });
    return infoCollection
      .doc("walkerNumbers")
      .get()
      .then((doc) => {
        const teamNumber = doc.get("Team");
        infoCollection.doc("walkerNumbers").update("Team", teamNumber + 1);
        context.commit("finishLoading", "teams/getNextTeamNumber", {
          root: true,
        });
        return teamNumber;
      })
      .catch((err) => {
        context.commit("finishLoading", "teams/getNextTeamNumber", {
          root: true,
        });
        context.commit(
          "setErrorMessage",
          `Cannot fetch next team number: ${err}`,
          { root: true }
        );
      });
  },
  createTeam(context, team: Team) {
    context.commit("startLoading", "teams/createTeam", { root: true });
    return context
      .dispatch("getNextTeamNumber")
      .then((teamNumber) => {
        team.teamNumber = teamNumber;
        team.createdTimestamp = store.Timestamp.now();
        team.updatedTimestamp = store.Timestamp.now();
        return teamsCollection.add(team);
      })
      .then((docRef) => {
        team.id = docRef.id;
        team.walkers.forEach((w) => {
          const walker = context.rootGetters["walkers/getWalker"](w) as Walker;
          if (!walker.retired) {
            walker.startTime = team.startTime;
            walker.finishTime = team.finishTime;
            walker.totalTime = team.totalTime;
            context.dispatch("walkers/updateWalker", walker, { root: true });
          }
        });
        team.additionalWalkers.forEach((w) => {
          const walker = context.rootGetters["walkers/getWalker"](w);
          if (!walker.retired) {
            walker.startTime = team.startTime;
            walker.finishTime = team.finishTime;
            walker.totalTime = team.totalTime;
            context.dispatch("walkers/updateWalker", walker, { root: true });
          }
        });
      })
      .then(() => {
        context.commit("setTeam", team);
        context.commit("finishLoading", "teams/createTeam", { root: true });
      })
      .catch((err) => {
        context.commit("finishLoading", "teams/createTeam", { root: true });
        context.commit("setErrorMessage", `Failed to add team: ${err}`, {
          root: true,
        });
      });
  },
  updateTeam(context, team: Team) {
    context.commit("startLoading", "teams/updateTeam", { root: true });
    team.updatedTimestamp = store.Timestamp.now();
    return teamsCollection
      .doc(team.id)
      .set(team)
      .then(() => {
        team.walkers.forEach((w) => {
          const walker = context.rootGetters["walkers/getWalker"](w);
          if (!walker.retired) {
            walker.startTime = team.startTime;
            walker.finishTime = team.finishTime;
            walker.totalTime = team.totalTime;
            context.dispatch("walkers/updateWalker", walker, { root: true });
          }
        });
        team.additionalWalkers.forEach((w) => {
          const walker = context.rootGetters["walkers/getWalker"](w);
          if (!walker.retired) {
            walker.startTime = team.startTime;
            walker.finishTime = team.finishTime;
            walker.totalTime = team.totalTime;
            context.dispatch("walkers/updateWalker", walker, { root: true });
          }
        });
      })
      .then(() => {
        context.commit("setTeam", team);
        context.commit("finishLoading", "teams/updateTeam", { root: true });
      })
      .catch((err) => {
        context.commit("finishLoading", "teams/updateTeam", { root: true });
        context.commit("setErrorMessage", `Failed to update team: ${err}`, {
          root: true,
        });
      });
  },
  deleteTeam(context, teamId: string) {
    context.commit("startLoading", "teams/deleteTeam", { root: true });
    return teamsCollection
      .doc(teamId)
      .delete()
      .then(() => {
        const teamArray = context.state.teams.filter(
          (team) => team.id !== teamId
        );
        context.commit("setTeams", teamArray);
        context.commit("finishLoading", "teams/deleteTeam", { root: true });
      })
      .catch((err) => {
        context.commit("finishLoading", "teams/deleteTeam", { root: true });
        context.commit("setErrorMessage", `Failed to delete team: ${err}`, {
          root: true,
        });
      });
  },
  clearData: {
    root: true,
    handler(context) {
      context.commit("setTeams", []);
    },
  },
};

export const mutations: MutationTree<TeamState> = {
  setTeams(state, val: Team[]) {
    state.teams = val;
  },
  setTeam(state, val: Team) {
    const i = state.teams.findIndex((team) => team.id === val.id);
    if (i >= 0) {
      state.teams[i] = val;
    } else {
      state.teams.push(val);
    }
  },
};

export const teams: Module<TeamState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
