import { store, shopCollection } from "@/plugins/firebaseConfig";
import { ShopItem } from "@/types";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { RootState, ShopState } from "../types";

const namespaced: boolean = true;

export const state: ShopState = {
  shopItems: [],
};

export const getters: GetterTree<ShopState, RootState> = {
  getItem:
    (state, _, rootState) =>
    (id: string): ShopItem => {
      const item = state.shopItems.find((item) => {
        return item.id === id;
      });
      if (item) {
        return item;
      } else {
        rootState.errorMessage = `Cannot find shop item with id ${id}`;
        return {} as ShopItem;
      }
    },
};

export const actions: ActionTree<ShopState, RootState> = {
  fetchItems(context) {
    context.commit("startLoading", "shop/fetchItems", {
      root: true,
    });
    return shopCollection
      .get()
      .then((query) => {
        const docs = query.docs;
        const items = docs.map((x) => {
          const data = x.data();
          data.id = x.id;
          return data;
        });
        context.commit(
          "setItems",
          items.sort((a, b) => {
            return a.name.localeCompare(b.name);
          })
        );
        context.commit("finishLoading", "shop/fetchItems", {
          root: true,
        });
      })
      .catch((err) => {
        context.commit("finishLoading", "shop/fetchItems", {
          root: true,
        });
        context.commit("setErrorMessage", `Cannot fetch shop items: ${err}`, {
          root: true,
        });
      });
  },
  createItem(context, shopItem) {
    context.commit("startLoading", "shop/createItem", {
      root: true,
    });
    return shopCollection
      .add(shopItem)
      .then((docRef) => {
        shopItem.id = docRef.id;
      })
      .then(() => {
        context.commit("appendItem", shopItem);
        context.commit("finishLoading", "shop/createItem", {
          root: true,
        });
      })
      .catch((err) => {
        context.commit("finishLoading", "shop/createItem", {
          root: true,
        });
        context.commit("setErrorMessage", `Error adding shop item: ${err}`, {
          root: true,
        });
      });
  },
  updateItem(context, shopItem: ShopItem) {
    context.commit("startLoading", "shop/updateItem", {
      root: true,
    });
    return shopCollection
      .doc(shopItem.id)
      .update(shopItem)
      .then(() => {
        context.commit("setItem", shopItem);
        context.commit("finishLoading", "shop/updateItem", {
          root: true,
        });
      })
      .catch((err) => {
        context.commit("finishLoading", "shop/updateItem", {
          root: true,
        });
        context.commit("setErrorMessage", `Error updating shop item: ${err}`, {
          root: true,
        });
      });
  },
  deleteItem(context, shopItem: ShopItem) {
    context.commit("startLoading", "shop/deleteItem", { root: true });
    return shopCollection
      .doc(shopItem.id)
      .delete()
      .then(() => {
        const itemArray = context.state.shopItems.filter(
          (item) => item.id !== shopItem.id
        );
        context.commit("setItems", itemArray);
        context.commit("finishLoading", "shop/deleteItem", { root: true });
      })
      .catch((err) => {
        context.commit("finishLoading", "shop/deleteItem", { root: true });
        context.commit("setErrorMessage", `Error deleting shop item: ${err}`, {
          root: true,
        });
      });
  },
  clearData: {
    root: true,
    handler(context) {
      context.commit("setItems", []);
    },
  },
};

export const mutations: MutationTree<ShopState> = {
  setItems(state, val: ShopItem[]) {
    state.shopItems = val;
  },
  appendItem(state, val: ShopItem) {
    state.shopItems.push(val);
    state.shopItems.sort((a, b) => a.name.localeCompare(b.name));
  },
  setItem(state, val: ShopItem) {
    const i = state.shopItems.findIndex((item) => item.id === val.id);
    state.shopItems[i] = val;
  },
};

export const shop: Module<ShopState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
