import {
  store,
  usersCollection,
  virtualWalkersCollection,
} from "@/plugins/firebaseConfig";
import { VirtualWalker } from "@/types";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { VirtualState, RootState } from "../types";

const namespaced: boolean = true;

export const state: VirtualState = {
  virtualWalkers: [],
};

export const getters: GetterTree<VirtualState, RootState> = {
  getWalker:
    (state, _, rootState) =>
    (id: string): VirtualWalker => {
      const walker = state.virtualWalkers.find((w) => {
        return w.id === id;
      });
      if (walker) {
        return walker;
      } else {
        rootState.errorMessage = `Cannot find virtual walker with id ${id}`;
        return {} as VirtualWalker;
      }
    },
  totalDistance:
    (_, getters) =>
    (id: string): number => {
      const walker = getters.getWalker(id) as VirtualWalker;
      if (!walker.activityLog) {
        return 0;
      }
      return walker.activityLog.reduce((total, activity) => {
        return (total += activity.distance);
      }, 0);
    },
  totalDistanceAllWalkers(state): number {
    const distance = state.virtualWalkers.reduce((acc, w) => {
      const mileCount = w.activityLog
        ? w.activityLog.reduce((acc, a) => acc + a.distance, 0)
        : 0;
      return acc + mileCount;
    }, 0);
    return distance;
  },
};

export const actions: ActionTree<VirtualState, RootState> = {
  fetchVirtualWalkers(context) {
    context.commit("startLoading", "virtual/fetchVirtualWalkers", {
      root: true,
    });
    if (context.rootGetters["user/isAdmin"]) {
      return virtualWalkersCollection
        .get()
        .then((query) => {
          const docs = query.docs;
          const walkers = docs.map((x) => {
            const data = x.data();
            data.id = x.id;
            return data;
          });
          context.commit(
            "setVirtualWalkers",
            walkers.sort((a, b) => {
              return a.firstName.localeCompare(b.firstName);
            })
          );
          context.commit("finishLoading", "virtual/fetchVirtualWalkers", {
            root: true,
          });
        })
        .catch((err) => {
          context.commit("finishLoading", "virtual/fetchVirtualWalkers", {
            root: true,
          });
          context.commit(
            "setErrorMessage",
            `Cannot fetch virtual walkers (admin): ${err}`,
            {
              root: true,
            }
          );
        });
    } else {
      const userID = context.rootState.user
        ? context.rootState.user.credentials.uid
        : "";
      return usersCollection
        .doc(userID)
        .collection("virtualWalkers")
        .get()
        .then((query) => {
          const docs = query.docs;
          const walkers = docs.map((x) => {
            const ref = x.data()
              .record as firebase.default.firestore.DocumentReference;
            return ref
              .get()
              .then((walkerDoc) => {
                const data = walkerDoc.data();
                if (data) {
                  data.id = walkerDoc.id;
                  return data;
                } else {
                  throw new Error(`${ref.id} not found`);
                }
              })
              .catch((err) => {
                context.commit("finishLoading", "virtual/fetchVirtualWalkers", {
                  root: true,
                });
                context.commit(
                  "setErrorMessage",
                  `Failed to fetch virtual walker: ${err}`,
                  {
                    root: true,
                  }
                );
                throw err;
              });
          });
          return Promise.all(walkers).then((walkers) => {
            context.commit(
              "setVirtualWalkers",
              walkers.sort((a, b) => {
                return a.firstName.localeCompare(b.firstName);
              })
            );
          });
        })
        .then(() => {
          context.commit("finishLoading", "virtual/fetchVirtualWalkers", {
            root: true,
          });
        })
        .catch((err) => {
          context.commit("finishLoading", "virtual/fetchVirtualWalkers", {
            root: true,
          });
          context.commit(
            "setErrorMessage",
            `Cannot fetch virtual walkers (user): ${err}`,
            {
              root: true,
            }
          );
        });
    }
  },
  createVirtualWalker(context, virtualWalker) {
    context.commit("startLoading", "virtual/createVirtualWalkers", {
      root: true,
    });
    return virtualWalkersCollection
      .add(virtualWalker)
      .then((docRef) => {
        virtualWalker.id = docRef.id;
        virtualWalker.createdTimestamp = store.Timestamp.now();
        virtualWalker.updatedTimestamp = store.Timestamp.now();
        usersCollection
          .doc(context.rootState.user?.profile.id)
          .collection("virtualWalkers")
          .doc(docRef.id)
          .set({
            firstName: virtualWalker.firstName,
            lastName: virtualWalker.lastName,
            record: docRef,
          });
      })
      .then(() => {
        context.commit("appendVirtualWalker", virtualWalker);
        context.commit("finishLoading", "virtual/createVirtualWalkers", {
          root: true,
        });
      })
      .catch((err) => {
        context.commit("finishLoading", "virtual/createVirtualWalkers", {
          root: true,
        });
        context.commit("setErrorMessage", `Error adding walker: ${err}`, {
          root: true,
        });
      });
  },
  updateVirtualWalker(context, virtualWalker: VirtualWalker) {
    context.commit("startLoading", "virtual/updateVirtualWalkers", {
      root: true,
    });
    virtualWalker.updatedTimestamp = store.Timestamp.now();
    return virtualWalkersCollection
      .doc(virtualWalker.id)
      .update(virtualWalker)
      .then(() => {
        context.commit("setVirtualWalker", virtualWalker);
        context.commit("finishLoading", "virtual/updateVirtualWalkers", {
          root: true,
        });
      })
      .catch((err) => {
        context.commit("finishLoading", "virtual/updateVirtualWalkers", {
          root: true,
        });
        context.commit("setErrorMessage", `Error updating walker: ${err}`, {
          root: true,
        });
      });
  },
  deleteWalker(context, walker: VirtualWalker) {
    context.commit("startLoading", "virtual/deleteWalker", { root: true });
    return virtualWalkersCollection
      .doc(walker.id)
      .delete()
      .then(() => {
        return usersCollection
          .doc(walker.contact)
          .collection("virtualWalkers")
          .doc(walker.id)
          .delete();
      })
      .then(() => {
        const walkerArray = context.state.virtualWalkers.filter(
          (w) => w.id !== walker.id
        );
        context.commit("setVirtualWalkers", walkerArray);
        context.commit("finishLoading", "virtual/deleteWalker", { root: true });
      })
      .catch((err) => {
        context.commit("finishLoading", "virtual/deleteWalker", { root: true });
        context.commit("setErrorMessage", `Error deleting walker: ${err}`, {
          root: true,
        });
      });
  },
  deleteWalkersByUserID(context, id: string) {
    context.commit("startLoading", "virtual/deleteWalkersByUserID", {
      root: true,
    });
    return virtualWalkersCollection
      .where("contact", "==", id)
      .get()
      .then((query) => {
        return query.forEach((doc) => {
          doc.ref.delete();
        });
      })
      .then(() => {
        return usersCollection.doc(id).collection("virtualWalkers").get();
      })
      .then((query) => {
        return query.forEach((doc) => {
          doc.ref.delete();
        });
      })
      .then(() => {
        const walkerArray = context.state.virtualWalkers.filter(
          (w) => w.contact !== id
        );
        context.commit("setVirtualWalkers", walkerArray);
        context.commit("finishLoading", "virtual/deleteWalkersByUserID", {
          root: true,
        });
      })
      .catch((err) => {
        context.commit("finishLoading", "virtual/deleteWalkersByUserID", {
          root: true,
        });
        context.commit("setErrorMessage", `Error deleting walkers: ${err}`, {
          root: true,
        });
      });
  },
  clearData: {
    root: true,
    handler(context) {
      context.commit("setVirtualWalkers", []);
    },
  },
};

export const mutations: MutationTree<VirtualState> = {
  setVirtualWalkers(state, val: VirtualWalker[]) {
    state.virtualWalkers = val;
  },
  appendVirtualWalker(state, val: VirtualWalker) {
    state.virtualWalkers.push(val);
    state.virtualWalkers.sort((a, b) => a.firstName.localeCompare(b.firstName));
  },
  setVirtualWalker(state, val: VirtualWalker) {
    const i = state.virtualWalkers.findIndex((walker) => walker.id === val.id);
    state.virtualWalkers[i] = val;
  },
};

export const virtual: Module<VirtualState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
