
/* eslint-disable no-undef */
import { Walker } from "@/types";
import Vue from "vue";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  ArcElement,
} from "chart.js";
import { Bar, Pie } from "vue-chartjs";
import { Gender, Route } from "@/types/enums";
import { getRouteColour } from "@/utils/colours";
import { getFinish } from "@/utils/route";
import {
  isInGloucesterDistrict,
  isInGloucestershireCounty,
} from "@/utils/groups";
import moment from "moment";
import { store } from "@/plugins/firebaseConfig";
import { createStatisticsExport } from "@/utils/statistics";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

export default Vue.extend({
  components: {
    Bar,
    Pie,
  },
  data() {
    return {
      labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18],
      checkpoints: [
        "Start",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "Finish",
      ],
    };
  },
  computed: {
    walkers(): Walker[] {
      return this.$store.state.walkers.walkers as Walker[];
    },
    bronzeData(): number[] {
      const bronzeWalkers = this.walkers.filter(
        (walker) => walker.route === Route.BRONZE && walker.totalTime
      );
      const buckets = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      bronzeWalkers.forEach((walker) => {
        const bucketIndex = parseInt(walker.totalTime.slice(0, 2));
        buckets[bucketIndex]++;
      });
      return buckets;
    },
    silverData(): number[] {
      const silverWalkers = this.walkers.filter(
        (walker) => walker.route === Route.SILVER && walker.totalTime
      );
      const buckets = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      silverWalkers.forEach((walker) => {
        const bucketIndex = parseInt(walker.totalTime.slice(0, 2));
        buckets[bucketIndex]++;
      });
      return buckets;
    },
    goldData(): number[] {
      const goldWalkers = this.walkers.filter(
        (walker) => walker.route === Route.GOLD && walker.totalTime
      );
      const buckets = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      goldWalkers.forEach((walker) => {
        const bucketIndex = parseInt(walker.totalTime.slice(0, 2));
        buckets[bucketIndex]++;
      });
      return buckets;
    },
    timeGroupData(): {
      label: string;
      data: number[];
      backgroundColor: string;
    }[] {
      return [
        {
          label: "Bronze",
          data: this.bronzeData,
          backgroundColor: getRouteColour(Route.BRONZE),
        },
        {
          label: "Silver",
          data: this.silverData,
          backgroundColor: getRouteColour(Route.SILVER),
        },
        {
          label: "Gold",
          data: this.goldData,
          backgroundColor: getRouteColour(Route.GOLD),
        },
      ];
    },
    bronzeCheckpointData(): number[] {
      const bronzeWalkers = this.walkers.filter(
        (walker) =>
          walker.route === Route.BRONZE && (walker.totalTime || walker.retired)
      );
      const buckets = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      bronzeWalkers.forEach((walker) => {
        for (let i = 0; i <= getFinish(Route.BRONZE); i++) {
          buckets[i]++;
          if (walker.retired && parseInt(walker.lastCheckpoint) === i) {
            break;
          }
        }
      });
      return buckets;
    },
    silverCheckpointData(): number[] {
      const silverWalkers = this.walkers.filter(
        (walker) =>
          walker.route === Route.SILVER && (walker.totalTime || walker.retired)
      );
      const buckets = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      silverWalkers.forEach((walker) => {
        for (let i = 0; i <= getFinish(Route.SILVER); i++) {
          buckets[i]++;
          if (walker.retired && parseInt(walker.lastCheckpoint) === i) {
            break;
          }
        }
      });
      return buckets;
    },
    goldCheckpointData(): number[] {
      const goldWalkers = this.walkers.filter(
        (walker) =>
          walker.route === Route.GOLD && (walker.totalTime || walker.retired)
      );
      const buckets = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      goldWalkers.forEach((walker) => {
        for (let i = 0; i <= getFinish(Route.GOLD); i++) {
          buckets[i]++;
          if (walker.retired && parseInt(walker.lastCheckpoint) === i) {
            break;
          }
        }
      });
      return buckets;
    },
    checkpointData(): {
      label: string;
      data: number[];
      backgroundColor: string;
    }[] {
      return [
        {
          label: "Gold",
          data: this.goldCheckpointData,
          backgroundColor: getRouteColour(Route.GOLD),
        },
        {
          label: "Silver",
          data: this.silverCheckpointData,
          backgroundColor: getRouteColour(Route.SILVER),
        },
        {
          label: "Bronze",
          data: this.bronzeCheckpointData,
          backgroundColor: getRouteColour(Route.BRONZE),
        },
      ];
    },
    bronzeBookingsData(): number[] {
      const bronzeWalkers = this.walkers.filter(
        (walker) => walker.route === Route.BRONZE
      );
      return this.bookingsLabels.map((dateString) => {
        return bronzeWalkers.filter((walker) => {
          if (!walker.createdTimestamp) {
            return false;
          }
          const createdTimestamp = walker.createdTimestamp.toDate
            ? walker.createdTimestamp.toDate()
            : new store.Timestamp(
                walker.createdTimestamp.seconds ??
                  // @ts-ignore
                  walker.createdTimestamp["_seconds"],
                walker.createdTimestamp.nanoseconds ??
                  // @ts-ignore
                  walker.createdTimestamp["_nanoseconds"]
              ).toDate();
          return moment(createdTimestamp).format("DD/MM/YYYY") === dateString;
        }).length;
      });
    },
    silverBookingsData(): number[] {
      const silverWalkers = this.walkers.filter(
        (walker) => walker.route === Route.SILVER
      );
      return this.bookingsLabels.map((dateString) => {
        return silverWalkers.filter((walker) => {
          if (!walker.createdTimestamp) {
            return false;
          }
          const createdTimestamp = walker.createdTimestamp.toDate
            ? walker.createdTimestamp.toDate()
            : new store.Timestamp(
                walker.createdTimestamp.seconds ??
                  // @ts-ignore
                  walker.createdTimestamp["_seconds"],
                walker.createdTimestamp.nanoseconds ??
                  // @ts-ignore
                  walker.createdTimestamp["_nanoseconds"]
              ).toDate();
          return moment(createdTimestamp).format("DD/MM/YYYY") === dateString;
        }).length;
      });
    },
    goldBookingsData(): number[] {
      const goldWalkers = this.walkers.filter(
        (walker) => walker.route === Route.GOLD
      );
      return this.bookingsLabels.map((dateString) => {
        return goldWalkers.filter((walker) => {
          if (!walker.createdTimestamp) {
            return false;
          }
          const createdTimestamp = walker.createdTimestamp.toDate
            ? walker.createdTimestamp.toDate()
            : new store.Timestamp(
                walker.createdTimestamp.seconds ??
                  // @ts-ignore
                  walker.createdTimestamp["_seconds"],
                walker.createdTimestamp.nanoseconds ??
                  // @ts-ignore
                  walker.createdTimestamp["_nanoseconds"]
              ).toDate();
          return moment(createdTimestamp).format("DD/MM/YYYY") === dateString;
        }).length;
      });
    },
    bookingsLabels(): string[] {
      const bookingsOpenDate = (
        this.$store.state.info.dates.main
          .bookingsOpen as firebase.default.firestore.Timestamp
      ).toDate();
      const bookingsCloseDate = (
        this.$store.state.info.dates.main
          .bookingsClose as firebase.default.firestore.Timestamp
      ).toDate();
      let bookingDates = [];
      for (
        let date = bookingsOpenDate;
        date < bookingsCloseDate;
        date = moment(date).add(1, "day").toDate()
      ) {
        bookingDates.push(moment(date).format("DD/MM/YYYY"));
      }
      return bookingDates;
    },
    bookingsData(): {
      label: string;
      data: number[];
      backgroundColor: string;
    }[] {
      return [
        {
          label: "Bronze",
          data: this.bronzeBookingsData,
          backgroundColor: getRouteColour(Route.BRONZE),
        },
        {
          label: "Silver",
          data: this.silverBookingsData,
          backgroundColor: getRouteColour(Route.SILVER),
        },
        {
          label: "Gold",
          data: this.goldBookingsData,
          backgroundColor: getRouteColour(Route.GOLD),
        },
      ];
    },
    genderData(): { backgroundColor: string[]; data: number[] }[] {
      const maleCount = this.walkers.filter(
        (walker) => walker.gender === Gender.MALE
      ).length;
      const femaleCount = this.walkers.filter(
        (walker) => walker.gender === Gender.FEMALE
      ).length;
      const otherCount = this.walkers.filter(
        (walker) => walker.gender === Gender.MIXED
      ).length;
      return [
        {
          backgroundColor: ["#7413dc", "#088486", "#ff912a"],
          data: [maleCount, femaleCount, otherCount],
        },
      ];
    },
    genderRouteData(): { backgroundColor: string[]; data: number[] }[] {
      const maleWalkers = this.walkers.filter(
        (walker) => walker.gender === Gender.MALE
      );
      const femaleWalkers = this.walkers.filter(
        (walker) => walker.gender === Gender.FEMALE
      );
      const otherWalkers = this.walkers.filter(
        (walker) => walker.gender === Gender.MIXED
      );
      return [
        {
          backgroundColor: [
            getRouteColour(Route.BRONZE),
            "#96480C",
            "#703609",
            getRouteColour(Route.SILVER),
            "#4A4A4A",
            "#363636",
            getRouteColour(Route.GOLD),
            "#D1B500",
            "#BFA600",
          ],
          data: [
            maleWalkers.filter((walker) => walker.route === Route.BRONZE)
              .length,
            femaleWalkers.filter((walker) => walker.route === Route.BRONZE)
              .length,
            otherWalkers.filter((walker) => walker.route === Route.BRONZE)
              .length,
            maleWalkers.filter((walker) => walker.route === Route.SILVER)
              .length,
            femaleWalkers.filter((walker) => walker.route === Route.SILVER)
              .length,
            otherWalkers.filter((walker) => walker.route === Route.SILVER)
              .length,
            maleWalkers.filter((walker) => walker.route === Route.GOLD).length,
            femaleWalkers.filter((walker) => walker.route === Route.GOLD)
              .length,
            otherWalkers.filter((walker) => walker.route === Route.GOLD).length,
          ],
        },
      ];
    },
    locationData(): { backgroundColor: string[]; data: number[] }[] {
      const gloucesterCount = this.walkers.filter((walker) =>
        isInGloucesterDistrict(walker.group)
      ).length;
      const gloucestershireCount = this.walkers.filter(
        (walker) =>
          !isInGloucesterDistrict(walker.group) &&
          isInGloucestershireCounty(walker.group)
      ).length;
      const outOfCountyCount = this.walkers.filter(
        (walker) => !isInGloucestershireCounty(walker.group)
      ).length;
      return [
        {
          backgroundColor: ["#7413dc", "#088486", "#ff912a"],
          data: [gloucesterCount, gloucestershireCount, outOfCountyCount],
        },
      ];
    },
    locationRouteData(): { backgroundColor: string[]; data: number[] }[] {
      const districtWalkers = this.walkers.filter((walker) =>
        isInGloucesterDistrict(walker.group)
      );
      const countyWalkers = this.walkers.filter(
        (walker) =>
          !isInGloucesterDistrict(walker.group) &&
          isInGloucestershireCounty(walker.group)
      );
      const outOfCountyWalkers = this.walkers.filter(
        (walker) => !isInGloucestershireCounty(walker.group)
      );
      return [
        {
          backgroundColor: [
            getRouteColour(Route.BRONZE),
            "#96480C",
            "#703609",
            getRouteColour(Route.SILVER),
            "#4A4A4A",
            "#363636",
            getRouteColour(Route.GOLD),
            "#D1B500",
            "#BFA600",
          ],
          data: [
            districtWalkers.filter((walker) => walker.route === Route.BRONZE)
              .length,
            countyWalkers.filter((walker) => walker.route === Route.BRONZE)
              .length,
            outOfCountyWalkers.filter((walker) => walker.route === Route.BRONZE)
              .length,
            districtWalkers.filter((walker) => walker.route === Route.SILVER)
              .length,
            countyWalkers.filter((walker) => walker.route === Route.SILVER)
              .length,
            outOfCountyWalkers.filter((walker) => walker.route === Route.SILVER)
              .length,
            districtWalkers.filter((walker) => walker.route === Route.GOLD)
              .length,
            countyWalkers.filter((walker) => walker.route === Route.GOLD)
              .length,
            outOfCountyWalkers.filter((walker) => walker.route === Route.GOLD)
              .length,
          ],
        },
      ];
    },
  },
  methods: {
    downloadCharts() {
      this.$store.commit("startLoading", "statistics/export");

      const chartNames = [
        "Time Taken",
        "Checkpoints Reached",
        "Booking Dates",
        "Gender Split",
        "Gender Split By Route",
        "Group Location Split",
        "Group Location Split By Route",
      ];

      const images = chartNames.map((chartName) => {
        const chartRef =
          chartName
            .split(" ")
            .map((component, index) =>
              index === 0 ? component.toLowerCase() : component
            )
            .join("") + "Chart";
        const chartInstance = (this.$refs[chartRef] as any).$children[0].chart;
        return {
          name: chartName,
          image: chartInstance.toBase64Image(),
        };
      });

      createStatisticsExport(images).then(() =>
        this.$store.commit("finishLoading", "statistics/export")
      );

      // const timeTakenBarChartInstance = (this.$refs.timeTakenBar as any)
      //   .$children[0].chart;
      // const image = timeTakenBarChartInstance.toBase64Image();
    },
  },
});
