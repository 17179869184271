
import Vue from "vue";

import { StaffProfile } from "@/types";
import { Role } from "@/types/enums";
import UserForm from "./UserForm.vue";
import { createStaffMedicalReport } from "@/utils/reports";

export default Vue.extend({
  data() {
    return {
      headers: [
        {
          text: "First Name",
          align: "start",
          value: "firstName",
        },
        {
          text: "Last Name",
          value: "lastName",
        },
        {
          text: "Role",
          value: "role",
        },
        {
          text: "Sub-Team",
          value: "subTeam",
        },
        {
          text: "Membership Number",
          value: "membershipNumber",
        },
        {
          text: "DBS Number",
          value: "dbsNumber",
        },
        {
          text: "Email",
          value: "email",
        },
        {
          text: "Phone Number",
          value: "phoneNumber",
        },
        {
          text: "Medical Information",
          value: "hasMedicalDetails",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
        },
      ],
      emptyStaff: {
        firstName: "",
        lastName: "",
        phoneNumber: "",
        membershipNumber: "",
        dbsNumber: "",
        address: {
          line1: "",
          line2: "",
          town: "",
          county: "",
          postcode: "",
        },
      } as StaffProfile,
      currentStaff: {} as StaffProfile,
      editForm: false,
      addAdminDialog: false,
      removeAdminDialog: false,
      updatePermissionNotification: false,
      medicalInfoDialog: false,
      medicalDownloadDialog: false,
      search: "",
    };
  },
  computed: {
    staff(): StaffProfile[] {
      return this.$store.getters["admin/staff"] as StaffProfile[];
    },
    staffInfo(): StaffProfile {
      if (this.currentStaff.id) {
        return this.currentStaff;
      } else {
        return this.emptyStaff;
      }
    },
  },
  methods: {
    openEditForm(item: StaffProfile) {
      this.currentStaff = item;
      this.editForm = true;
    },
    closeEditForm() {
      this.editForm = false;
    },
    openMedicalInfoDialog(item: StaffProfile) {
      this.currentStaff = item;
      this.medicalInfoDialog = true;
    },
    openAddAdminDialog(item: StaffProfile) {
      this.currentStaff = item;
      this.addAdminDialog = true;
    },
    addAdmin() {
      if (this.currentStaff && this.currentStaff.id) {
        this.currentStaff.role = Role.ADMIN;
        this.$store
          .dispatch("admin/updateUser", this.currentStaff)
          .then(() => {
            this.addAdminDialog = false;
          })
          .catch((err) => {
            throw err;
          });
      }
    },
    openRemoveAdminDialog(item: StaffProfile) {
      this.currentStaff = item;
      this.removeAdminDialog = true;
    },
    removeAdmin() {
      if (this.currentStaff && this.currentStaff.id) {
        this.currentStaff.role = Role.STAFF;
        this.$store
          .dispatch("admin/updateUser", this.currentStaff)
          .then(() => {
            this.removeAdminDialog = false;
          })
          .catch((err) => {
            throw err;
          });
      }
    },
    grantEventPrivileges(user: StaffProfile) {
      user.role = Role.ENHANCED_STAFF;
      this.$store
        .dispatch("admin/updateUser", user)
        .then(() => {
          this.updatePermissionNotification = true;
        })
        .catch((err) => {
          throw err;
        });
    },
    revokeEventPrivileges(user: StaffProfile) {
      user.role = Role.STAFF;
      this.$store
        .dispatch("admin/updateUser", user)
        .then(() => {
          this.updatePermissionNotification = true;
        })
        .catch((err) => {
          throw err;
        });
    },
    getColour(role: Role) {
      if (role === Role.ADMIN) {
        return "success";
      } else {
        return "warning";
      }
    },
    downloadStaff() {
      const csvString = this.makeCsv(this.staff);
      const encodedUri = encodeURI(csvString);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "staff.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    makeCsv(data: StaffProfile[]): string {
      const header =
        "First Name,Last Name,User Type,Sub-Team,Membership Number,DBS Number,Phone Number,Email,Group,Medical Details,Address Line 1,Address Line 2,Town,County,Postcode,Home Contact,Home Contact Phone Number,Home Contact Address Line 1,Home Contact Address Line 2,Home Contact Town,Home Contact County,Home Contact Postcode";
      const values = data
        .map((o) => {
          return [
            `"${o.firstName}"`,
            `"${o.lastName}"`,
            `"${o.role}"`,
            `"${
              o.subTeam === "Checkpoint" && o.checkpointNumber
                ? o.subTeam + " " + o.checkpointNumber
                : o.subTeam
            }"`,
            `"${o.membershipNumber}"`,
            `"${o.dbsNumber}"`,
            `="${o.phoneNumber}"`,
            `"${o.email}"`,
            `"${o.group}"`,
            `"${o.medicalDetails ?? "None"}"`,
            o.address ? `"${o.address.line1}"` : "",
            o.address ? `"${o.address.line2}"` : "",
            o.address ? `"${o.address.town}"` : "",
            o.address ? `"${o.address.county}"` : "",
            o.address ? `"${o.address.postcode}"` : "",
            `"${o.homeContact.firstName} ${o.homeContact.lastName}"`,
            `="${o.homeContact.phoneNumber}"`,
            `"${o.homeContact.address.line1}"`,
            `"${o.homeContact.address.line2}"`,
            `"${o.homeContact.address.town}"`,
            `"${o.homeContact.address.county}"`,
            `"${o.homeContact.address.postcode}"`,
          ].join(",");
        })
        .join("\n");
      return "data:text/csv;charset=utf-8," + header + "\n" + values;
    },
    generateMedicalReport() {
      createStaffMedicalReport(this.staff.filter((s) => s.hasMedicalDetails));
    },
    downloadMedicalSpreasheet() {
      const csvString = this.makeMedicalSpreadsheet(
        this.staff.filter((s) => s.hasMedicalDetails)
      );
      const encodedUri = encodeURI(csvString);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "Staff Medical Information.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    makeMedicalSpreadsheet(data: StaffProfile[]) {
      const header = "Name,Team,Medical Details";
      const values = data
        .map((o) => {
          const team = o.checkpointNumber
            ? `${o.subTeam} ${o.checkpointNumber}`
            : o.subTeam;
          return [
            `"${o.firstName} ${o.lastName}"`,
            `"${team}"`,
            `"${o.medicalDetails ?? ""}"`,
          ].join(",");
        })
        .join("\n");
      return "data:text/csv;charset=utf-8," + header + "\n" + values;
    },
    capitalise(role: Role): string {
      switch (role) {
        case Role.STAFF:
        case Role.ENHANCED_STAFF:
          return "Staff";
        case Role.ADMIN:
          return "Admin";
        default:
          return role.toString();
      }
    },
  },
  components: {
    UserForm,
  },
});
