
import { UserProfile, VirtualWalker } from "@/types";
import { VirtualRoute as Route, Section } from "@/types/enums";
import { getRouteColour, getSectionColour } from "@/utils/colours";
import { getVirtualRouteLength } from "@/utils/route";
import Vue from "vue";

interface Group {
  name: string;
  walkers: VirtualWalker[];
  id: number;
}

enum Tab {
  WALKERS = "walkers",
  GROUPS = "groups",
}

export default Vue.extend({
  data() {
    return {
      tab: Tab.WALKERS,
      sectionToggle: 0,
      sections: ["All"].concat(Object.values(Section)),
      downloadDialog: false,
      Route,
    };
  },
  computed: {
    walkers(): VirtualWalker[] {
      const walkerArray = this.$store.state.virtual
        .virtualWalkers as VirtualWalker[];
      return walkerArray
        .sort((a, b) => {
          const aGroup = this.getContact(a).group;
          const bGroup = this.getContact(b).group;
          if (!aGroup) return 1;
          else if (!bGroup) return -1;
          else if (aGroup.toLowerCase() > bGroup.toLowerCase()) return 1;
          else if (aGroup.toLowerCase() < bGroup.toLowerCase()) return -1;
          else return 0;
        })
        .filter((w) =>
          this.sectionToggle
            ? w.section === this.sections[this.sectionToggle]
            : true
        );
    },
    groups(): Group[] {
      const walkerArray = this.walkers;
      const groupArray = this.$store.state.info.groups as string[];
      const userArray = this.$store.state.admin.users as UserProfile[];
      let id = 0;
      return groupArray
        .map((group) => {
          id++;
          return {
            name: group,
            walkers: userArray
              .filter((u) => u.group === group)
              .reduce(
                (walkers, u) =>
                  walkers.concat(walkerArray.filter((w) => w.contact === u.id)),
                [] as VirtualWalker[]
              ),
            id: id,
          };
        })
        .filter((group) => group.walkers.length > 0)
        .sort((a, b) => {
          if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
          else if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
          else return 0;
        });
    },
  },
  methods: {
    getContact(walker: VirtualWalker): UserProfile {
      return this.$store.getters["admin/getUser"](walker.contact);
    },
    getWalkerDistance(walker: VirtualWalker): number {
      return this.$store.getters["virtual/totalDistance"](walker.id);
    },
    getGroupDistance(group: Group): number {
      return group && group.walkers
        ? group.walkers.reduce(
            (total, walker) => total + this.getWalkerDistance(walker),
            0
          )
        : 0;
    },
    getGroupSize(group: Group): number {
      return group && group.walkers ? group.walkers.length : 0;
    },
    getRouteDistance(route: Route): number {
      return getVirtualRouteLength(route);
    },
    getWalkerPercentage(walker: VirtualWalker): number {
      const percentage =
        (this.getWalkerDistance(walker) / this.getRouteDistance(walker.route)) *
        100;
      return percentage <= 100 ? percentage : 100;
    },
    getBronzeCount(group: Group): number {
      return group.walkers.filter((w) => {
        const distance = this.getWalkerDistance(w);
        return (
          distance >= getVirtualRouteLength(Route.BRONZE) &&
          distance < getVirtualRouteLength(Route.SILVER)
        );
      }).length;
    },
    getSilverCount(group: Group): number {
      return group.walkers.filter((w) => {
        const distance = this.getWalkerDistance(w);
        return (
          distance >= getVirtualRouteLength(Route.SILVER) &&
          distance < getVirtualRouteLength(Route.GOLD)
        );
      }).length;
    },
    getGoldCount(group: Group): number {
      return group.walkers.filter((w) => {
        const distance = this.getWalkerDistance(w);
        return (
          distance >= getVirtualRouteLength(Route.GOLD) &&
          distance < getVirtualRouteLength(Route.PLATINUM)
        );
      }).length;
    },
    getPlatinumCount(group: Group): number {
      return group.walkers.filter((w) => {
        const distance = this.getWalkerDistance(w);
        return distance >= getVirtualRouteLength(Route.PLATINUM);
      }).length;
    },
    downloadTable() {
      let content = `
        <html>
          <style>
            table {
              margin-left: auto;
              margin-right: auto;
            }
            .position {
              text-align: center;
              padding-right: 5px;
            }

            .section,
            .distance {
              padding-right: 5px;
            }
            th,
            td {
              padding-left: 5px;
              padding-right: 40px;
            }
            table,
            th,
            td {
              border: 1px solid grey;
              border-collapse: collapse;
            }
          </style>
          <body>`;
      switch (this.tab) {
        case "walkers":
          content += this.exportWalkerHTML();
          break;
        case "groups":
          content += this.exportGroupHTML();
          break;
      }
      content += "</body></html>";
      const encodedUri = encodeURI(content);
      var link = document.createElement("a");
      link.setAttribute("href", "data:text/html;charset=utf-8," + encodedUri);
      link.setAttribute("download", `${this.tab}Table.html`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    downloadAllSections() {
      let content = `
        <html>
          <style>
            table {
              margin-left: auto;
              margin-right: auto;
              margin-bottom: 30px;
            }
            .position {
              text-align: center;
              padding-right: 5px;
            }

            .section,
            .distance {
              padding-right: 5px;
            }
            th,
            td {
              padding-left: 5px;
              padding-right: 40px;
            }
            table,
            th,
            td {
              border: 1px solid grey;
              border-collapse: collapse;
            }
            h2 {
              margin-left: 10px
            }
          </style>
          <body>`;
      for (let i = 0; i < 12; i++) {
        this.sectionToggle = i;
        content += `<h2>${this.sections[this.sectionToggle]}</h2>`;
        switch (this.tab) {
          case Tab.WALKERS:
            content += this.exportWalkerHTML();
            break;
          case Tab.GROUPS:
            content += this.exportGroupHTML();
            break;
        }
      }
      content += "</body></html>";
      const encodedUri = encodeURI(content);
      var link = document.createElement("a");
      link.setAttribute("href", "data:text/html;charset=utf-8," + encodedUri);
      link.setAttribute("download", `${this.tab}TableAllSections.html`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      this.sectionToggle = 0;
    },
    exportWalkerHTML(): string {
      let htmlString = `
        <table>
          <tr>
            <th class="section">Section</th>
            <th>Group</th>
            <th>Route</th>
            <th class="distance">Distance</th>
            <th class="distance">Percentage</th>
          </tr>`;
      for (const i in this.walkers) {
        const walker = this.walkers[i];
        htmlString += `
          <tr>
            <td class="section">${walker.section}</td>
            <td>${this.getContact(walker).group}</td>
            <td>${walker.route}</td>
            <td class="distance">${this.getWalkerDistance(walker).toFixed(
              2
            )}</td>
            <td>${this.getWalkerPercentage(walker)}%</td>
          </tr>`;
      }
      htmlString += "</table>";
      return htmlString;
    },
    exportGroupHTML(): string {
      let htmlString = `
        <table>
          <tr>
            <th>Group</th>
            <th class="distance">Total Distance</th>
            <th class="distance">Average Distance</th>
            <th class="distance">Bronze Finishers</th>
            <th class="distance">Silver Finishers</th>
            <th class="distance">Gold Finishers</th>
            <th class="distance">Platinum Finishers</th>
          </tr>`;
      for (const i in this.groups) {
        const group = this.groups[i];
        htmlString += `
          <tr>
            <td>${group.name}</td>
            <td>${this.getGroupDistance(group).toFixed(2)}</td>
            <td>${(
              this.getGroupDistance(group) / this.getGroupSize(group)
            ).toFixed(2)}</td>
            <td>${this.getBronzeCount(group)}</td>
            <td>${this.getSilverCount(group)}</td>
            <td>${this.getGoldCount(group)}</td>
            <td>${this.getPlatinumCount(group)}</td>
          </tr>`;
      }
      htmlString += "</table>";
      return htmlString;
    },
    getSectionColour(section: Section): string {
      return getSectionColour(section);
    },
    getRouteColour(route: Route): string {
      return getRouteColour(route);
    },
    downloadFinalResultsTable() {
      this.generateFinalResultsTable((a: VirtualWalker, b: VirtualWalker) => {
        if (a.firstName > b.firstName) return 1;
        else if (a.firstName < b.firstName) return -1;
        else return 0;
      }, "FirstName");
      this.generateFinalResultsTable((a: VirtualWalker, b: VirtualWalker) => {
        const aContact = this.getContact(a);
        const bContact = this.getContact(b);
        if (aContact.group > bContact.group) return 1;
        else if (aContact.group < bContact.group) return -1;
        else if (a.section > b.section) return 1;
        else if (a.section < b.section) return -1;
        else if (a.firstName > b.firstName) return 1;
        else if (a.firstName < b.firstName) return -1;
        else return 0;
      }, "Group");
    },
    generateFinalResultsTable(sortFunction: Function, property: string) {
      const walkerList = this.$store.state.virtual.virtualWalkers;
      walkerList.sort(sortFunction);
      let content = `
        <html>
          <style>
            table {
              margin-left: auto;
              margin-right: auto;
            }
            .position {
              text-align: center;
              padding-right: 5px;
            }

            .section,
            .distance {
              padding-right: 5px;
            }
            th,
            td {
              padding-left: 5px;
              padding-right: 40px;
            }
            table,
            th,
            td {
              border: 1px solid grey;
              border-collapse: collapse;
            }
          </style>
          <body>
          <table>
          <tr>
            <th>Name</th>
            <th class="section">Section</th>
            <th>Group</th>
            <th>Route Completed</th>
            <th class="distance">Distance</th>
          </tr>`;
      for (const i in walkerList) {
        const walker = walkerList[i];
        content += `
          <tr>
            <td>${walker.firstName}</td>
            <td class="section">${walker.section}</td>
            <td>${this.getContact(walker).group}</td>
            <td>${this.getRouteCompleted(walker)}</td>
            <td class="distance">${this.getWalkerDistance(walker).toFixed(
              2
            )}</td>
          </tr>`;
      }
      content += "</table></body></html>";
      const encodedUri = encodeURI(content);
      var link = document.createElement("a");
      link.setAttribute("href", "data:text/html;charset=utf-8," + encodedUri);
      link.setAttribute("download", `FinalResultsTableBy${property}.html`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    getRouteCompleted(walker: VirtualWalker): Route {
      const distance = this.getWalkerDistance(walker);
      if (distance >= this.getRouteDistance(Route.PLATINUM)) {
        return Route.PLATINUM;
      } else if (distance >= this.getRouteDistance(Route.GOLD)) {
        return Route.GOLD;
      } else if (distance >= this.getRouteDistance(Route.SILVER)) {
        return Route.SILVER;
      } else if (distance >= this.getRouteDistance(Route.BRONZE)) {
        return Route.BRONZE;
      } else {
        return Route.NONE;
      }
    },
    downloadFinalResultsSpreadsheet() {
      const walkerList = this.$store.state.virtual
        .virtualWalkers as VirtualWalker[];
      walkerList.sort((a, b) => {
        const aContact = this.getContact(a);
        const bContact = this.getContact(b);
        if (aContact.email > bContact.email) return 1;
        else if (aContact.email < bContact.email) return -1;
        else return 0;
      });
      const csvString = this.makeCsv(walkerList);
      const encodedUri = encodeURI(csvString);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "FinalResultsSpreadsheet.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    makeCsv(data: VirtualWalker[]) {
      const header = "First Name,Last Name,Route Completed,Distance,Email";
      const values = data
        .map((o) => {
          const contact = this.getContact(o);
          return [
            `"${o.firstName}"`,
            `"${o.lastName}"`,
            `"${this.getRouteCompleted(o)}"`,
            `"${this.getWalkerDistance(o)}"`,
            `"${contact.email}"`,
          ].join(",");
        })
        .join("\n");
      return "data:text/csv;charset=utf-8," + header + "\n" + values;
    },
  },
});
