
import Vue, { PropType } from "vue";

import { VForm, ShopItem } from "@/types";
import { storage } from "@/plugins/firebaseConfig";

export default Vue.extend({
  data() {
    return {
      emptyShopItem: {
        name: "",
        description: "",
        category: "",
        quantity: 0,
        quantitySold: 0,
        cost: 0,
        image: "",
        hasVariants: false,
        variants: [],
        id: "",
        deliveryCosts: [],
      } as ShopItem,
      file: {} as File,
      image: "",
    };
  },
  props: {
    itemProp: { type: Object as PropType<ShopItem> },
  },
  computed: {
    item(): ShopItem {
      if (this.itemProp && this.itemProp.id) {
        return this.itemProp;
      } else {
        return this.emptyShopItem;
      }
    },
    form(): VForm {
      return this.$refs.shopItemForm as VForm;
    },
  },
  methods: {
    saveItem() {
      if (!this.form.validate()) {
        return;
      }

      if (this.item.id) {
        // Update existing item
        this.$store
          .dispatch("shop/updateItem", this.item)
          .then(() => {
            this.$emit("exit", true);
          })
          .catch((err) => {
            throw err;
          });
      } else {
        // Item doesn't exist, create new entry
        this.$store
          .dispatch("shop/createItem", this.item)
          .then(() => {
            this.clearData();
            this.$emit("exit", true);
          })
          .catch((err) => {
            throw err;
          });
      }
    },
    uploadImage() {
      const storageRef = storage.ref().child("shopItems/" + this.file.name);
      return storageRef
        .put(this.file)
        .then(() => {
          return storageRef.getDownloadURL();
        })
        .then((imageURL) => {
          this.image = imageURL;
          return storageRef.fullPath;
        })
        .catch((err) => {
          console.log("File failed to upload: ", err);
          return "";
        });
    },
    clearData() {
      this.emptyShopItem = {
        name: "",
        description: "",
        category: "",
        quantity: 0,
        quantitySold: 0,
        cost: 0,
        image: "",
        hasVariants: false,
        variants: [],
        id: "",
        deliveryCosts: [],
      } as ShopItem;
      // this.form.reset();
    },
  },
  watch: {
    file() {
      if (this.file && this.file.name) {
        this.uploadImage().then((ref) => {
          this.item.image = ref;
        });
      }
    },
    item() {
      storage
        .ref(this.item.image)
        .getDownloadURL()
        .then((url) => {
          this.image = url;
        })
        .catch(() => {
          this.image = "";
        });
    },
  },
});
