import { infoCollection, store } from "@/plugins/firebaseConfig";
import { Config, Trophy } from "@/types";
import moment from "moment";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { InfoState, RootState } from "../types";

const namespaced: boolean = true;

export const state: InfoState = {
  groups: [],
  countdown: false,
  testing: false,
  walkerNumbers: {
    start: {
      Bronze: 0,
      Silver: 0,
      Gold: 0,
    },
    limit: {
      Bronze: 0,
      Silver: 0,
      Gold: 0,
    },
  },
  dates: {
    main: {
      bookingsOpen: new store.Timestamp(0, 0),
      earlyBirdEnds: new store.Timestamp(0, 0),
      walkerNumbersLocked: new store.Timestamp(0, 0),
      bookingsClose: new store.Timestamp(0, 0),
      event: new store.Timestamp(0, 0),
    },
    virtual: {
      start: new store.Timestamp(0, 0),
      end: new store.Timestamp(0, 0),
    },
    shopClose: new store.Timestamp(0, 0),
  },
  trophies: {
    soble: [],
  },
};

export const getters: GetterTree<InfoState, RootState> = {
  mainEventBookingsOpened(state): Boolean {
    return new Date() > state.dates.main.bookingsOpen.toDate();
  },
  mainEventBookingsClosed(state): Boolean {
    return new Date() > state.dates.main.bookingsClose.toDate();
  },
  mainEventEarlyBirdEnded(state): Boolean {
    return new Date() > state.dates.main.earlyBirdEnds.toDate();
  },
  mainEventWalkerNumbersLocked(state): Boolean {
    return new Date() > state.dates.main.walkerNumbersLocked.toDate();
  },
  virtualEventStarted(state): Boolean {
    return new Date() > state.dates.virtual.start.toDate();
  },
  virtualEventEnded(state): Boolean {
    return new Date() > state.dates.virtual.end.toDate();
  },
  breakfastClosed(state): Boolean {
    return (
      new Date() >
      moment(state.dates.main.event.toDate()).subtract(6, "days").toDate()
    );
  },
  shopClosed(state): Boolean {
    return new Date() > state.dates.shopClose.toDate();
  },
};

export const actions: ActionTree<InfoState, RootState> = {
  fetchGroups(context) {
    context.commit("startLoading", "info/fetchGroups", { root: true });
    return infoCollection
      .doc("groups")
      .get()
      .then((doc) => {
        const data = doc.data();
        if (data) {
          context.commit(
            "setGroups",
            data["groups"].filter((v: string) => v != "")
          );
          context.commit("finishLoading", "info/fetchGroups", { root: true });
        } else {
          throw new Error("Groups document was undefined");
        }
      })
      .catch((err) => {
        context.commit("finishLoading", "info/fetchGroups", { root: true });
        context.commit("setErrorMessage", `Failed to fetch groups: ${err}`, {
          root: true,
        });
      });
  },
  fetchConfig(context) {
    context.commit("startLoading", "info/fetchConfig", { root: true });
    return infoCollection
      .doc("config")
      .get()
      .then((doc) => {
        context.commit("setConfig", doc.data());
        context.commit("finishLoading", "info/fetchConfig", { root: true });
      })
      .catch((err) => {
        context.commit("finishLoading", "info/fetchConfig", { root: true });
        context.commit(
          "setErrorMessage",
          `Cannot fetch global settings: ${err}`,
          {
            root: true,
          }
        );
      });
  },
  fetchTrophies(context) {
    context.commit("startLoading", "info/fetchTrophies", { root: true });
    return infoCollection
      .doc("trophies")
      .get()
      .then((doc) => {
        context.commit("setTrophies", doc.data());
        context.commit("finishLoading", "info/fetchTrophies", { root: true });
      })
      .catch((err) => {
        context.commit("finishLoading", "info/fetchTrophies", { root: true });
        context.commit("setErrorMessage", `Cannot fetch trophies: ${err}`, {
          root: true,
        });
      });
  },
  updateConfig(context, config) {
    context.commit("startLoading", "info/updateConfig", { root: true });
    return infoCollection
      .doc("config")
      .update(config)
      .then(() => {
        context.commit("setConfig", config);
        context.commit("finishLoading", "info/updateConfig", { root: true });
      })
      .catch((err) => {
        context.commit("finishLoading", "info/updateConfig", { root: true });
        context.commit(
          "setErrorMessage",
          `Cannot update global settings: ${err}`,
          {
            root: true,
          }
        );
      });
  },
  updateTrophies(context, trophies) {
    context.commit("startLoading", "info/updateTrophies", { root: true });
    return infoCollection
      .doc("trophies")
      .update(trophies)
      .then(() => {
        context.commit("setTrophies", trophies);
        context.commit("finishLoading", "info/updateTrophies", { root: true });
      })
      .catch((err) => {
        context.commit("finishLoading", "info/updateTrophies", { root: true });
        context.commit("setErrorMessage", `Cannot update trophies: ${err}`, {
          root: true,
        });
      });
  },
  addGroup(context, group) {
    if (!context.state.groups.includes(group)) {
      context.commit("startLoading", "info/addGroup", { root: true });
      return infoCollection
        .doc("groups")
        .update({
          groups: store.FieldValue.arrayUnion(group),
        })
        .then(() => {
          context.commit("appendGroup", group);
          context.commit("finishLoading", "info/addGroup", { root: true });
        })
        .catch((err) => {
          context.commit("finishLoading", "info/addGroup", { root: true });
          return context.commit(
            "setErrorMessage",
            `Error adding group: ${err}`,
            {
              root: true,
            }
          );
        });
    } else {
      return;
    }
  },
  clearData: {
    root: true,
    handler(context) {
      context.commit("setGroups", []);
    },
  },
};

export const mutations: MutationTree<InfoState> = {
  setGroups(state, val: string[]) {
    state.groups = val.sort();
  },
  setConfig(state, val: Config) {
    state.dates.main.bookingsClose = val.dates.main.bookingsClose;
    state.dates.main.bookingsOpen = val.dates.main.bookingsOpen;
    state.dates.main.earlyBirdEnds = val.dates.main.earlyBirdEnds;
    state.dates.main.walkerNumbersLocked = val.dates.main.walkerNumbersLocked;
    state.dates.main.event = val.dates.main.event;
    state.dates.virtual.start = val.dates.virtual.start;
    state.dates.virtual.end = val.dates.virtual.end;
    state.dates.shopClose = val.dates.shopClose;
    state.countdown = val.countdown;
    state.testing = val.testing;
    state.walkerNumbers = val.walkerNumbers;
  },
  setTrophies(state, val: { soble: number[] }) {
    state.trophies = val;
  },
  setMainEventBookingsClosingDate(
    state,
    val: firebase.default.firestore.Timestamp
  ) {
    state.dates.main.bookingsClose = val;
  },
  setMainEventBookingsOpeningDate(
    state,
    val: firebase.default.firestore.Timestamp
  ) {
    state.dates.main.bookingsOpen = val;
  },
  setMainEventEarlyBirdEndingDate(
    state,
    val: firebase.default.firestore.Timestamp
  ) {
    state.dates.main.earlyBirdEnds = val;
  },
  setMainEventWalkerNumbersLockedDate(
    state,
    val: firebase.default.firestore.Timestamp
  ) {
    state.dates.main.walkerNumbersLocked = val;
  },
  setMainEventDate(state, val: firebase.default.firestore.Timestamp) {
    state.dates.main.event = val;
  },
  setVirtualEventStartDate(state, val: firebase.default.firestore.Timestamp) {
    state.dates.virtual.start = val;
  },
  setVirtualEventEndDate(state, val: firebase.default.firestore.Timestamp) {
    state.dates.virtual.end = val;
  },
  setShopCloseDate(state, val: firebase.default.firestore.Timestamp) {
    state.dates.shopClose = val;
  },
  setCountdown(state, val: boolean) {
    state.countdown = val;
  },
  setTesting(state, val: boolean) {
    state.testing = val;
  },
  appendGroup(state, val: string) {
    state.groups.push(val);
  },
};

export const info: Module<InfoState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
