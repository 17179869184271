
/* eslint-disable no-undef */
import Vue from "vue";
import ShopCard from "./ShopCard.vue";
import { BasketItem, ShopItem, UserProfile } from "@/types";
import { DeliveryMethod } from "@/types/enums";
import { calculateBasketCost, calculateDeliveryCost } from "@/utils/costs";
import { store as fbStore } from "@/plugins/firebaseConfig";

export default Vue.extend({
  data() {
    return {
      quantitiesChanged: false,
      collection: false,
      post: false,
      shopClosedDialog: false,
      basket: [] as BasketItem[],
      deliveryMethod: DeliveryMethod.COLLECTION,
    };
  },
  methods: {
    updateTotal(productId: string, newQuantity: number, variantIndex?: number) {
      if (this.shopClosed) {
        console.log("shop closed");
        this.shopClosedDialog = true;
      } else {
        this.quantitiesChanged = true;
        const basketItemIndex = this.basket.findIndex(
          (item) =>
            item.id === productId &&
            (variantIndex === undefined || item.variantIndex === variantIndex)
        );
        if (basketItemIndex === -1) {
          console.log("not in basket");
          if (variantIndex !== undefined) {
            console.log("is a variant");
            this.basket.push({
              id: productId,
              quantity: newQuantity,
              variantIndex,
              createdTimestamp: fbStore.Timestamp.now(),
              updatedTimestamp: fbStore.Timestamp.now(),
              delivered: false,
            });
          } else {
            console.log("is not a variant");
            this.basket.push({
              id: productId,
              quantity: newQuantity,
              createdTimestamp: fbStore.Timestamp.now(),
              updatedTimestamp: fbStore.Timestamp.now(),
              delivered: false,
            });
          }
        } else {
          console.log("is in basket");
          this.basket[basketItemIndex].quantity = newQuantity;
          this.basket[basketItemIndex].updatedTimestamp =
            fbStore.Timestamp.now();
          this.basket[basketItemIndex].delivered = false;
          console.log(this.basket[basketItemIndex]);
        }
      }
    },
    saveChanges() {
      if (this.shopClosed) {
        this.shopClosedDialog = true;
      } else {
        const profile = this.$store.state.user.profile as UserProfile;
        profile.basket = this.basket;
        profile.deliveryMethod = this.deliveryMethod;
        this.$store
          .dispatch("user/updateUser", profile)
          .then(() => {
            this.quantitiesChanged = false;
          })
          .catch((err) => {
            throw err;
          });
      }
    },
    selectPost() {
      if (this.deliveryMethod != DeliveryMethod.POST) {
        this.quantitiesChanged = true;
        this.post = true;
        this.collection = false;
        this.deliveryMethod = DeliveryMethod.POST;
      }
    },
    selectCollection() {
      if (this.deliveryMethod != DeliveryMethod.COLLECTION) {
        this.quantitiesChanged = true;
        this.post = false;
        this.collection = true;
        this.deliveryMethod = DeliveryMethod.COLLECTION;
      }
    },
    setInitialValue() {
      this.basket = [...this.user.basket];
      this.deliveryMethod = this.user.deliveryMethod;
    },
    itemCost(basket: BasketItem[]) {
      return calculateBasketCost(basket, this.items);
    },
    deliveryCost(basket: BasketItem[], deliveryMethod: DeliveryMethod) {
      return calculateDeliveryCost(basket, deliveryMethod, this.items);
    },
    totalCost(basket: BasketItem[], deliveryMethod: DeliveryMethod) {
      return this.itemCost(basket) + this.deliveryCost(basket, deliveryMethod);
    },
    getQuantities(item: ShopItem) {
      if (item.hasVariants) {
        return item.variants.map((variant) => {
          const basketVariant = this.basket.find(
            (i) =>
              i.id === item.id &&
              i.variantIndex === item.variants.indexOf(variant)
          );
          if (basketVariant) {
            return basketVariant.quantity;
          } else {
            return 0;
          }
        });
      } else {
        const basketItem = this.basket.find((i) => i.id === item.id);
        if (basketItem) {
          return [basketItem.quantity];
        } else {
          return [0];
        }
      }
    },
  },
  computed: {
    items(): ShopItem[] {
      return this.$store.state.shop.shopItems;
    },
    user(): UserProfile {
      return this.$store.state.user.profile as UserProfile;
    },
    shopClosed(): boolean {
      return this.$store.getters["info/shopClosed"];
    },
    breakfastClosed(): boolean {
      return this.$store.getters["info/breakfastClosed"];
    },
    eventDate() {
      const date = (
        this.$store.state.info.dates.main
          .event as firebase.default.firestore.Timestamp
      ).toDate();
      return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    },
    itemCategories(): string[] {
      return Array.from(new Set(this.items.map((item) => item.category)));
    },
    quantities() {
      return this.$store.state.shop.shopItems.map((item: ShopItem) => {
        if (item.hasVariants) {
          return item.variants.map((variant) => {
            const basketVariant = this.basket.find(
              (i) =>
                i.id === item.id &&
                i.variantIndex === item.variants.indexOf(variant)
            );
            if (basketVariant) {
              return basketVariant.quantity;
            } else {
              return 0;
            }
          });
        } else {
          const basketItem = this.basket.find((i) => i.id === item.id);
          if (basketItem) {
            return [basketItem.quantity];
          } else {
            return [0];
          }
        }
      });
    },
  },
  watch: {
    user() {
      this.deliveryMethod = this.user.deliveryMethod;
      if (this.user.deliveryMethod === DeliveryMethod.POST) {
        this.post = true;
        this.collection = false;
      } else if (this.user.deliveryMethod === DeliveryMethod.COLLECTION) {
        this.post = false;
        this.collection = true;
      }
      this.setInitialValue();
    },
  },
  beforeMount() {
    if (this.user.deliveryMethod === DeliveryMethod.POST) {
      this.post = true;
    } else if (this.user.deliveryMethod === DeliveryMethod.COLLECTION) {
      this.collection = true;
    }
    this.setInitialValue();
  },
  beforeRouteLeave(to, from, next) {
    if (this.quantitiesChanged) {
      const answer = window.confirm(
        "Are you sure you want to leave? You haven't saved the changes to your basket."
      );
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
  components: {
    ShopCard,
  },
});
