
import { Team, Trophy, Walker } from "@/types";
import { Route } from "@/types/enums";
import {
  createIndividualResultsReport,
  createTeamResultsReport,
} from "@/utils/reports";
import Vue, { PropType } from "vue";

interface HasTime {
  totalTime: string;
}

enum Tab {
  WALKERS = "walkers",
  TEAMS = "teams",
}

export default Vue.extend({
  data() {
    return {
      tab: Tab.TEAMS,
    };
  },
  props: {
    routeProp: {
      type: String as PropType<Route>,
    },
  },
  computed: {
    teams(): Team[] {
      return (this.$store.state.teams.teams as Team[])
        .filter(
          (team: Team) =>
            team.route === this.routeProp && !team.isBroken && team.totalTime
        )
        .sort(
          (a: Team, b: Team) =>
            this.getTimeInMinutes(a.totalTime) -
            this.getTimeInMinutes(b.totalTime)
        );
    },
    walkers(): Walker[] {
      let walkerArray = this.$store.state.walkers.walkers as Walker[];
      walkerArray = walkerArray
        .filter((w) => w.route === this.routeProp && w.totalTime)
        .sort(
          (a, b) =>
            this.getTimeInMinutes(a.totalTime) -
            this.getTimeInMinutes(b.totalTime)
        );
      return walkerArray;
    },
    individualWalkers(): Walker[] {
      return this.walkers.filter((w) => {
        return (
          !this.$store.getters["teams/isCoreWalker"](w.walkerNumber) ||
          (
            this.$store.getters["teams/getTeamByCoreWalker"](
              w.walkerNumber
            ) as Team
          ).isBroken ||
          !(
            this.$store.getters["teams/getTeamByCoreWalker"](
              w.walkerNumber
            ) as Team
          )?.totalTime
        );
      });
    },
  },
  methods: {
    getTimeInMinutes(timeString: string): number {
      const timeArray = timeString.split(":");
      return parseInt(timeArray[0]) * 60 + parseInt(timeArray[1]);
    },
    getWalkers(team: Team): string {
      let walkerString = "";
      team.walkers.forEach(
        (w) => (walkerString += `<p>${this.getWalkerName(w)}</p>`)
      );
      return walkerString;
    },
    getWalker(walkerNumber: number): Walker {
      const walker = walkerNumber
        ? this.$store.getters["walkers/getWalker"](walkerNumber)
        : {};
      return walker ? walker : ({} as Walker);
    },
    getWalkerName(walkerNumber: number): string {
      return walkerNumber
        ? this.$store.getters["walkers/getWalkerName"](walkerNumber)
        : "";
    },
    getPosition(item: HasTime, list: HasTime[]): number {
      const positions = list
        .map((v: HasTime, i: number) => {
          return { item: v, position: i };
        })
        .filter((v) => v.item.totalTime === item.totalTime)
        .map((v) => v.position);
      return Math.min(...positions) + 1;
    },
    downloadTable() {
      let content = `
        <html>
          <style>
            table {
              margin-left: auto;
              margin-right: auto;
            }
            .position {
              text-align: center;
              padding-right: 5px;
            }

            .section,
            .distance {
              padding-right: 5px;
            }
            th,
            td {
              padding-left: 5px;
              padding-right: 40px;
            }
            table,
            th,
            td {
              border: 1px solid grey;
              border-collapse: collapse;
            }
          </style>
          <body>`;
      switch (this.tab) {
        case "walkers":
          content += this.exportWalkerHTML();
          break;
        case "teams":
          content += this.exportTeamHTML();
          break;
      }
      content += "</body></html>";
      const encodedUri = encodeURI(content);
      var link = document.createElement("a");
      link.setAttribute("href", "data:text/html;charset=utf-8," + encodedUri);
      link.setAttribute("download", `${this.routeProp} ${this.tab}.html`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    exportWalkerHTML(): string {
      let htmlString = `
        <h1>${this.routeProp} Individual Results</h1>
        <table>
          <tr>
            <th class="section">Position</th>
            <th>Name</th>
            <th>Group</th>
            <th class="distance">Start Time</th>
            <th class="distance">Finish Time</th>
            <th class="distance">Total Time</th>
          </tr>`;
      for (const i in this.individualWalkers) {
        const walker = this.individualWalkers[i];
        htmlString += `
          <tr>
            <td class="section">${this.getPosition(
              walker,
              this.individualWalkers
            )}</td>
            <td>${walker.firstName} ${walker.lastName}</td>
            <td>${walker.group}</td>
            <td>${walker.startTime}</td>
            <td>${walker.finishTime}</td>
            <td>${walker.totalTime}</td>
          </tr>`;
      }
      htmlString += "</table>";
      return htmlString;
    },
    exportTeamHTML(): string {
      let htmlString = `
        <h1>${this.routeProp} Team Results</h1>
        <table>
          <tr>
            <th>Position</th>
            <th class="distance">Group</th>
            <th class="distance">Walkers</th>
            <th class="distance">Start Time</th>
            <th class="distance">Finish Time</th>
            <th class="distance">Total Time</th>
          </tr>`;
      for (const i in this.teams) {
        const team = this.teams[i];
        htmlString += `
          <tr>
            <td>${this.getPosition(team, this.teams)}</td>
            <td>${this.getWalker(team.walkers[0]).group}</td>
            <td>${this.getWalkers(team)}</td>
            <td>${team.startTime}</td>
            <td>${team.finishTime}</td>
            <td>${team.totalTime}</td>
          </tr>`;
      }
      htmlString += "</table>";
      return htmlString;
    },
    downloadCSV() {
      let csvString = "";
      switch (this.tab) {
        case "walkers":
          csvString += this.makeWalkerCSV();
          break;
        case "teams":
          csvString += this.makeTeamCSV();
          break;
      }
      const encodedUri = encodeURI(csvString);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", `${this.routeProp} ${this.tab}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    makeWalkerCSV(): string {
      const header =
        "Position,First Name,Last Name,Group,Start Time,Finish Time,Total Time";
      const values = this.individualWalkers
        .map((w) => {
          return [
            `"${this.getPosition(w, this.individualWalkers)}"`,
            `"${w.firstName}"`,
            `"${w.lastName}"`,
            `"${w.group}"`,
            `"${w.startTime}"`,
            `"${w.finishTime}"`,
            `"${w.totalTime}"`,
          ].join(",");
        })
        .join("\n");
      return "data:text/csv;charset=utf-8," + header + "\n" + values;
    },
    makeTeamCSV(): string {
      const header =
        "Position,Group,Walker 1,Walker 2,Walker 3,Additional Walker 1,Additional Walker 2,Start Time,Finish Time,Total Time";
      const values = this.teams
        .map((t) => {
          return [
            `"${this.getPosition(t, this.teams)}"`,
            `"${this.getWalker(t.walkers[0]).group}"`,
            `"${this.getWalkerName(t.walkers[0])}"`,
            `"${this.getWalkerName(t.walkers[1])}"`,
            `"${this.getWalkerName(t.walkers[2])}"`,
            `"${this.getWalkerName(t.additionalWalkers[0])}"`,
            `"${this.getWalkerName(t.additionalWalkers[1])}"`,
            `"${t.startTime}"`,
            `"${t.finishTime}"`,
            `"${t.totalTime}"`,
          ].join(",");
        })
        .join("\n");
      return "data:text/csv;charset=utf-8," + header + "\n" + values;
    },
    makeAlanWalkerCSV(): string {
      const header = "Position,Time,Name,Group";
      let lastPosition = 0;
      const values = this.individualWalkers
        .map((w) => {
          const currentPosition = this.getPosition(w, this.individualWalkers);
          const row = [
            `"${lastPosition !== currentPosition ? currentPosition : ""}"`,
            `"${w.totalTime}"`,
            `"${w.firstName} ${w.lastName}"`,
            `"${w.group}"`,
          ].join(",");
          lastPosition = currentPosition;
          return row;
        })
        .join("\n");
      return "data:text/csv;charset=utf-8," + header + "\n" + values;
    },
    makeAlanTeamCSV(): string {
      const header = "Position,Time,Walker,Group,Trophy";
      const trophies: Trophy[] =
        this.$store.getters[`teams/get${this.routeProp}Trophies`];
      const values = this.teams
        .map((t) => {
          const teamTrophies = trophies
            .filter((trophy) =>
              trophy.teams.some((team) => team.teamNumber === t.teamNumber)
            )
            .map((trophy) => trophy.name);
          return t.walkers
            .map((w) => {
              return [
                `"${
                  t.walkers[0] === w ? this.getPosition(t, this.teams) : ""
                }"`,
                `"${t.totalTime}"`,
                `"${this.getWalkerName(w)}"`,
                `"${this.getWalker(w).group}"`,
                `"${teamTrophies.join(", ")}"`,
              ].join(",");
            })
            .join("\n");
        })
        .join("\n");
      return "data:text/csv;charset=utf-8," + header + "\n" + values;
    },
    downloadAlanResults() {
      let csvString = "";
      switch (this.tab) {
        case "walkers":
          csvString += this.makeAlanWalkerCSV();
          break;
        case "teams":
          csvString += this.makeAlanTeamCSV();
          break;
      }
      const encodedUri = encodeURI(csvString);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute(
        "download",
        `${this.routeProp} ${this.tab} For Alan.csv`
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    downloadReport() {
      switch (this.tab) {
        case "walkers":
          createIndividualResultsReport(this.individualWalkers, this.routeProp);
          break;
        case "teams":
          createTeamResultsReport(
            this.teams,
            this.walkers,
            [
              ...this.$store.getters[`teams/get${this.routeProp}Trophies`],
              this.$store.getters["teams/getSobleTrophy"],
            ],
            this.routeProp
          );
          break;
      }
    },
  },
});
