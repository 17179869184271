
import { functions } from "@/plugins/firebaseConfig";
import { Walker } from "@/types";
import Vue from "vue";
import WalkerForm from "./WalkerForm.vue";

export default Vue.extend({
  data() {
    return {
      validated: false,
      email: "",
      password: "",
      authenticated: false,
      passwordDialog: false,
      errorDialog: false,
      errorMessage: "",
      walker: {} as Walker,
    };
  },
  methods: {
    finishValidation() {
      this.validated = true;
      if (!this.$store.state.user.profile.id) {
        setTimeout(() => {
          this.$store.dispatch("clearData");
          window.location.replace("http://www.cotswoldmarathon.org.uk");
        }, 5000);
      }
    },
    authenticate() {
      const checkParentEmail = functions.httpsCallable("checkParentEmail");
      this.$store.commit("startLoading", "functions/checkParentEmail");
      checkParentEmail({ email: this.email, walkerId: this.$route.query.id })
        .then((result) => {
          if (result.data) {
            this.fetchWalker()
              .then((walker) => {
                this.walker = walker;
                this.authenticated = true;
                this.$store.commit(
                  "finishLoading",
                  "functions/checkParentEmail"
                );
              })
              .catch((error) => {
                this.errorMessage = error;
                this.errorDialog = true;
                this.$store.commit(
                  "finishLoading",
                  "functions/checkParentEmail"
                );
              });
          } else {
            this.errorMessage =
              "Oops, your email address doesn't appear to match. Please check which email address this link was sent to and try again.";
            this.errorDialog = true;
            this.$store.commit("finishLoading", "functions/checkParentEmail");
          }
        })
        .catch((err) => {
          this.$store.commit(
            "setErrorMessage",
            `Failed to confirm email address: ${err}`
          );
          this.$store.commit("finishLoading", "functions/checkParentEmail");
        });
    },
    fetchWalker(): Promise<Walker> {
      const getWalker = functions.httpsCallable(
        "checkParentEmailAndReturnWalker"
      );
      this.$store.commit(
        "startLoading",
        "functions/checkParentEmailAndReturnWalker"
      );
      return getWalker({ email: this.email, walkerId: this.$route.query.id })
        .then((query) => {
          const walker = query.data as Walker;
          walker.id = this.$route.query.id as string;
          this.$store.commit(
            "finishLoading",
            "functions/checkParentEmailAndReturnWalker"
          );
          return walker;
        })
        .catch((error) => {
          this.$store.commit(
            "finishLoading",
            "functions/checkParentEmailAndReturnWalker"
          );
          throw error;
        });
    },
    updateWalker() {
      const validateAndUpdateWalker = functions.httpsCallable(
        "validateAndUpdateWalker"
      );
      this.$store.commit("startLoading", "functions/validateAndUpdateWalker");
      validateAndUpdateWalker({ email: this.email, walker: this.walker })
        .then(() => {
          this.$store.commit(
            "finishLoading",
            "functions/validateAndUpdateWalker"
          );
          this.finishValidation();
        })
        .catch((err) => {
          this.$store.commit(
            "setErrorMessage",
            `Failed to validate and update walker: ${err}`
          );
          this.$store.commit(
            "finishLoading",
            "functions/validateAndUpdateWalker"
          );
        });
    },
  },
  components: {
    WalkerForm,
  },
});
