
import Vue, { PropType } from "vue";

import { Team, Walker, VForm, VMenu } from "@/types";
import { Route } from "@/types/enums";
import { getRouteColour } from "@/utils/colours";

export default Vue.extend({
  data() {
    return {
      emptyTeam: {
        route: Route.BRONZE,
        walkers: [] as number[],
        additionalWalkers: [] as number[],
        startTime: "",
        finishTime: "",
        id: "",
        isBroken: false,
        totalTime: "",
        contact: "",
        teamNumber: 0,
      } as Team,
      emptyWalker: {
        firstName: "",
        lastName: "",
      } as Walker,
      timeMenu: false,
      walker1: "",
      walker2: "",
      walker3: "",
      additionalWalker1: "",
      additionalWalker2: "",
    };
  },
  props: {
    teamProp: { type: Object as PropType<Team> },
  },
  computed: {
    teamInfo(): Team {
      if (this.teamProp && this.teamProp.id) {
        return this.teamProp;
      } else {
        return this.emptyTeam;
      }
    },
    buttonText() {
      if (this.teamProp) {
        return "Save Team";
      } else {
        return "Add Team";
      }
    },
    form(): VForm {
      return this.$refs.teamForm as VForm;
    },
    startTimeMenu(): VMenu {
      return this.$refs.menu as VMenu;
    },
  },
  methods: {
    addTeam() {
      if (!this.form.validate()) {
        return;
      }

      this.teamInfo.walkers = [this.walker1, this.walker2, this.walker3].map(
        (w) => parseInt(w)
      );
      this.teamInfo.additionalWalkers = [
        this.additionalWalker1,
        this.additionalWalker2,
      ]
        .filter((w) => w)
        .map((w) => parseInt(w));

      this.teamInfo.route = this.$store.getters["walkers/getWalker"](
        this.teamInfo.walkers[0]
      ).route;

      if (this.teamInfo.id) {
        // Update existing team
        this.$store
          .dispatch("teams/updateTeam", this.teamInfo)
          .then(() => {
            this.$emit("exit", true);
          })
          .catch((err) => {
            throw err;
          });
      } else {
        // Team doesn't exist, create new entry
        this.teamInfo.contact = this.$store.getters["walkers/getWalker"](
          this.teamInfo.walkers[0]
        ).contact;
        this.$store
          .dispatch("teams/createTeam", this.teamInfo)
          .then(() => {
            this.clearData();
            this.$emit("exit", true);
          })
          .catch((err) => {
            throw err;
          });
      }
    },
    routeColour(route: Route): string {
      return getRouteColour(route);
    },
    clearData() {
      this.emptyTeam = {
        route: Route.BRONZE,
        walkers: [] as number[],
        additionalWalkers: [] as number[],
        startTime: "",
        finishTime: "",
        id: "",
        isBroken: false,
        totalTime: "",
        contact: "",
        teamNumber: 0,
        trackerId: "",
      } as Team;
      this.form.reset();
    },
    setWalkers() {
      if (this.teamProp) {
        const walkers = this.teamProp.walkers.map((w) => w.toString());
        const additionalWalkers = this.teamProp.additionalWalkers.map((w) =>
          w.toString()
        );

        this.walker1 = walkers[0];
        this.walker2 = walkers[1];
        this.walker3 = walkers[2];
        this.additionalWalker1 = additionalWalkers[0] ?? "";
        this.additionalWalker2 = additionalWalkers[1] ?? "";
      }
    },
  },
  watch: {
    teamProp() {
      this.setWalkers();
    },
  },
  mounted() {
    this.setWalkers();
  },
});
