export enum DeliveryMethod {
  POST = "Post",
  COLLECTION = "Collection",
}

export enum Role {
  PARENT = "parent",
  LEADER = "leader",
  ADMIN = "admin",
  STAFF = "staff",
  ENHANCED_STAFF = "enhanced-staff",
}

export enum Unit {
  MILES = "miles",
  MINUTES = "mins",
}

export enum Route {
  BRONZE = "Bronze",
  SILVER = "Silver",
  GOLD = "Gold",
  NONE = "",
}

export enum VirtualRoute {
  BRONZE = "Bronze",
  SILVER = "Silver",
  GOLD = "Gold",
  PLATINUM = "Platinum",
  NONE = "",
}

export enum Section {
  SQUIRRELS = "Squirrels",
  BEAVERS = "Beavers",
  CUBS = "Cubs",
  SCOUTS = "Scouts",
  EXPLORERS = "Explorers",
  RAINBOWS = "Rainbows",
  BROWNIES = "Brownies",
  GUIDES = "Guides",
  RANGERS = "Rangers",
  ADULTS = "Adults",
  SIBLINGS = "Siblings",
}

export enum Gender {
  MALE = "Male",
  FEMALE = "Female",
  MIXED = "Mixed",
}

export enum PaymentStatus {
  NONE = "",
  NOT_PAID = "Not Paid",
  PAYMENT_SENT = "Payment Sent",
  PARTIALLY_PAID = "Partially Paid",
  PAID = "Paid",
}

export enum ErrorType {
  CLIENT = "Client",
  CLOUD = "Cloud",
}
