
import { BasketItem, ShopItem, UserProfile, Walker } from "@/types";
import { calculateDeliveryCost, calculateBasketCost } from "@/utils/costs";
import { getReference } from "@/utils/users";
import OrderForm from "./OrderForm.vue";
import Vue from "vue";
import { DeliveryMethod } from "@/types/enums";

interface TableUser {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  group: string;
  role: string;
  [key: string]: any;
}

export default Vue.extend({
  data() {
    return {
      search: "",
      editDialog: false,
      currentUser: {
        basket: [] as BasketItem[],
      } as UserProfile,
      paymentForm: false,
      addDialog: false,
      onlyShowUndeliveredItems: false,
      includeWalkerBadges: true,
    };
  },
  computed: {
    users(): UserProfile[] {
      const users = (this.$store.state.admin.users as UserProfile[]).filter(
        (v: UserProfile) =>
          (this.getWalkersForUser(v).length > 0 &&
            (!this.onlyShowUndeliveredItems || !v.walkerBadgesDelivered)) ||
          v.basket.filter(
            (basketItem) =>
              basketItem.quantity > 0 &&
              (!this.onlyShowUndeliveredItems || !basketItem.delivered)
          ).length > 0
      );
      return users;
    },
    tableUsers(): TableUser[] {
      return this.users.map((user) => {
        const baseTableUser: TableUser = {
          id: user.id,
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          group: user.group,
          role: user.role,
        };
        this.items.map((item) => {
          if (item.hasVariants) {
            item.variants.map((variant, index) => {
              const basketItem = user.basket.find(
                (basketItem) =>
                  basketItem.id === item.id && basketItem.variantIndex === index
              );
              baseTableUser[`${item.id}-${index}`] = {
                quantity: basketItem?.quantity ?? 0,
                delivered: basketItem?.delivered ?? true,
              };
            });
          } else {
            const basketItem = user.basket.find(
              (basketItem) => basketItem.id === item.id
            );
            baseTableUser[`${item.id}`] = {
              quantity: basketItem?.quantity ?? 0,
              delivered: basketItem?.delivered ?? true,
            };
          }

          if (item.name === "Badge" && this.includeWalkerBadges) {
            const numberOfWalkers = this.getWalkersForUser(user).length;
            if (numberOfWalkers > 0) {
              baseTableUser[`${item.id}`].quantity += numberOfWalkers;
              baseTableUser[`${item.id}`].delivered =
                baseTableUser[`${item.id}`].delivered &&
                user.walkerBadgesDelivered;
            }
          }
        });
        return baseTableUser;
      });
    },
    allUsers(): UserProfile[] {
      return this.$store.state.admin.users;
    },
    items(): ShopItem[] {
      return this.$store.state.shop.shopItems;
    },
    itemAndVariantIds(): string[] {
      return this.items.flatMap((item) => {
        if (item.hasVariants) {
          return item.variants.map((variant, index) => {
            return `${item.id}-${index}`;
          });
        } else {
          return [item.id];
        }
      });
    },
    headers() {
      const items = this.$store.state.shop.shopItems as ShopItem[];
      const itemHeaders = items.flatMap((item) => {
        if (item.hasVariants) {
          return item.variants.map((variant, index) => {
            return {
              text: `${item.name} - ${variant.name}`,
              value: `${item.id}-${index}.quantity`,
            };
          });
        } else {
          return [
            {
              text: item.name,
              value: `${item.id}.quantity`,
            },
          ];
        }
      });

      return [
        {
          text: "First Name",
          value: "firstName",
        },
        {
          text: "Last Name",
          value: "lastName",
        },
        {
          text: "Email",
          value: "email",
        },
        {
          text: "Group",
          value: "group",
        },
        {
          text: "Paid",
          value: "paid",
        },
        ...itemHeaders,
        {
          text: "Actions",
          value: "actions",
        },
      ];
    },
  },
  methods: {
    downloadOrders() {
      const csvString = this.makeCsv(this.users);
      const encodedUri = encodeURI(csvString);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "orders.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    makeCsv(data: UserProfile[]): string {
      const header = `First Name,Last Name,Group,Email,Phone Number,Address Line 1,Address Line 2,Town,County,Postcode,Badges (Walkers),${this.items
        .flatMap((item) =>
          item.hasVariants
            ? item.variants.map((variant) => item.name + " - " + variant.name)
            : [item.name]
        )
        .join(
          ","
        )},Early Bird Walkers,Other Walkers,Shop Cost,Walker Cost,Delivery Cost,Total Cost,Paid,Delivery Method,Reference`;
      const values = data
        .map((o) => {
          const walkers = this.getWalkersForUser(o);
          const earlyWalkers = walkers.filter((w) => w.cost === 15);
          const lateWalkers = walkers.filter((w) => w.cost === 16);
          return [
            `"${o.firstName}"`,
            `"${o.lastName}"`,
            `"${o.group}"`,
            `"${o.email}"`,
            `="${o.phoneNumber}"`,
            `"${o.address ? o.address.line1 : ""}"`,
            `"${o.address ? o.address.line2 : ""}"`,
            `"${o.address ? o.address.town : ""}"`,
            `"${o.address ? o.address.county : ""}"`,
            `"${o.address ? o.address.postcode : ""}"`,
            `"${walkers.length}"`,
            ...this.items
              .flatMap((item) =>
                item.hasVariants
                  ? item.variants.map(
                      (variant, index) =>
                        o.basket.find(
                          (basketItem) =>
                            basketItem.id === item.id &&
                            basketItem?.variantIndex === index
                        )?.quantity ?? 0
                    )
                  : [
                      o.basket.find((basketItem) => basketItem.id === item.id)
                        ?.quantity ?? 0,
                    ]
              )
              .map((quantity) => `"${quantity}"`),
            `"${earlyWalkers.length}"`,
            `"${lateWalkers.length}"`,
            `"${this.shopCost(o)}"`,
            `"${this.walkerCost(o)}"`,
            `"${this.deliveryCost(o)}"`,
            `"${this.totalCost(o)}"`,
            `"${
              this.$store.getters["admin/totalOutstandingBalanceForUser"](
                o.id
              ) <= 0
            }"`,
            `"${o.deliveryMethod}"`,
            `"${this.getReference(o)}"`,
          ].join(",");
        })
        .join("\n");
      return "data:text/csv;charset=utf-8," + header + "\n" + values;
    },
    getItemTotal(itemAndVariantId: string): number {
      return this.tableUsers
        .map((tableUser) => tableUser[itemAndVariantId].quantity)
        .reduce((a, b) => a + b, 0);
    },
    getReference(user: UserProfile): string {
      if (user && user.id) {
        return getReference(user.id);
      } else {
        return "";
      }
    },
    editOrder(tableUser: TableUser) {
      const user = this.allUsers.find((user) => user.id === tableUser.id);
      if (!user) {
        this.$store.commit(
          "setErrorMessage",
          `Failed to find user: ${tableUser.firstName} ${tableUser.lastName}`
        );
      } else {
        this.currentUser = user;
        this.editDialog = true;
      }
    },
    addOrder() {
      this.currentUser = {
        basket: [] as BasketItem[],
        deliveryMethod: DeliveryMethod.POST,
      } as UserProfile;
      this.addDialog = true;
    },
    saveOrder() {
      this.$store
        .dispatch("admin/updateUser", this.currentUser)
        .then(() => {
          this.editDialog = false;
          this.addDialog = false;
        })
        .catch((err) => {
          throw err;
        });
    },
    totalCost(user: UserProfile) {
      return this.$store.getters["admin/totalCostForUser"](user.id);
    },
    walkerCost(user: UserProfile) {
      return this.$store.getters["walkers/totalCostForUser"](user.id);
    },
    shopCost(user: UserProfile) {
      return calculateBasketCost(user.basket, this.items);
    },
    deliveryCost(user: UserProfile) {
      return calculateDeliveryCost(
        user.basket,
        user.deliveryMethod,
        this.items
      );
    },
    getWalkersForUser(user: UserProfile) {
      return (this.$store.state.walkers.walkers as Walker[]).filter(
        (w) => w.contact === user.id
      );
    },
    markAsDelivered(tableUser: TableUser) {
      const user = this.allUsers.find((user) => user.id === tableUser.id);
      console.log(user);
      if (!user) {
        this.$store.commit(
          "setErrorMessage",
          `Failed to find user: ${tableUser.firstName} ${tableUser.lastName}`
        );
      } else {
        user.walkerBadgesDelivered = true;
        user.basket = user.basket.map((basketItem) => {
          basketItem.delivered = true;
          return basketItem;
        });
        this.$store.dispatch("admin/updateUser", user);
      }
    },
    markItemAsDelivered(tableUser: TableUser, itemId: string) {
      const user = this.allUsers.find((user) => user.id === tableUser.id);
      console.log(user);
      if (!user) {
        this.$store.commit(
          "setErrorMessage",
          `Failed to find user: ${tableUser.firstName} ${tableUser.lastName}`
        );
      } else {
        const [id, variantIndex] = itemId.split("-");
        const item = user.basket.find((basketItem) => {
          if (variantIndex) {
            return (
              basketItem.id === id &&
              basketItem.variantIndex === parseInt(variantIndex)
            );
          } else {
            return basketItem.id === id;
          }
        });
        if (item) {
          item.delivered = true;
          this.$store.dispatch("admin/updateUser", user);
        }
        const shopItem = this.items.find((shopItem) => shopItem.id === id);
        if (shopItem && shopItem.name === "Badge") {
          user.walkerBadgesDelivered = true;
          this.$store.dispatch("admin/updateUser", user);
        }
      }
    },
  },
  components: {
    OrderForm,
  },
});
