import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import { store } from "./store";
import vuetify from "./plugins/vuetify";
import { auth } from "./plugins/firebaseConfig";

Vue.config.productionTip = false;

let app: Vue;
auth.onAuthStateChanged(() => {
  if (!app) {
    app = new Vue({
      el: "#app",
      router,
      store,
      vuetify,
      render: (h) => h(App),
    });
  }
});
