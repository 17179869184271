import {
  functions,
  infoCollection,
  store,
  usersCollection,
  walkersCollection,
} from "@/plugins/firebaseConfig";
import { Walker } from "@/types";
import { Route } from "@/types/enums";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { RootState, WalkerState } from "../types";

const namespaced: boolean = true;

export const state: WalkerState = {
  walkers: [],
};

export const getters: GetterTree<WalkerState, RootState> = {
  getWalker:
    (state, getters, rootState) =>
    (walkerNumber: number): Walker => {
      const walker = state.walkers.find((w) => {
        return w.walkerNumber === walkerNumber;
      });
      if (walker) {
        return walker;
      } else {
        rootState.errorMessage = `Cannot find walker with walker number ${walkerNumber}`;
        return {} as Walker;
      }
    },
  getWalkerName:
    (state, getters, rootState) =>
    (walkerNumber: number): string => {
      const walker = state.walkers.find((w) => {
        return w.walkerNumber === walkerNumber;
      });
      if (walker) {
        return `${walker.firstName} ${walker.lastName}`;
      } else {
        rootState.errorMessage = `Cannot find walker with walker number ${walkerNumber}`;
        return "";
      }
    },
  getWalkers:
    (state) =>
    (userId: string): Walker[] => {
      return state.walkers.filter((w) => w.contact === userId);
    },
  totalCost(state, getters, rootState): number {
    return state.walkers
      .filter((walker) => walker.contact === rootState.user?.profile.id)
      .reduce((total, walker) => total + walker.cost, 0);
  },
  totalCostForUser:
    (state, getters, rootState) =>
    (id: string): number => {
      return state.walkers
        .filter((walker) => walker.contact === id)
        .reduce((total, walker) => total + walker.cost, 0);
    },
};

export const actions: ActionTree<WalkerState, RootState> = {
  fetchWalkers(context) {
    context.commit("startLoading", "walkers/fetchWalkers", { root: true });
    if (context.rootGetters["user/isAdmin"]) {
      return walkersCollection
        .get()
        .then((query) => {
          const docs = query.docs;
          const walkers = docs.map((x) => {
            const data = x.data();
            data.id = x.id;
            return data;
          });
          context.commit(
            "setWalkers",
            walkers.sort((a, b) => {
              return a.walkerNumber - b.walkerNumber;
            })
          );
          context.commit("finishLoading", "walkers/fetchWalkers", {
            root: true,
          });
        })
        .catch((err) => {
          context.commit("finishLoading", "walkers/fetchWalkers", {
            root: true,
          });
          context.commit(
            "setErrorMessage",
            `Cannot fetch walkers (admin): ${err}`,
            { root: true }
          );
        });
    } else {
      const userID = context.rootState.user
        ? context.rootState.user.credentials.uid
        : "";
      return usersCollection
        .doc(userID)
        .collection("walkers")
        .get()
        .then((query) => {
          const docs = query.docs;
          const walkers = docs.map((x) => {
            const ref = x.data()
              .record as firebase.default.firestore.DocumentReference;
            return ref
              .get()
              .then((walkerDoc) => {
                const data = walkerDoc.data();
                if (data) {
                  data.id = walkerDoc.id;
                  return data as Walker;
                } else {
                  throw new Error(
                    `Could not find document for walker id ${ref.id}`
                  );
                }
              })
              .catch((err) => {
                context.commit("finishLoading", "virtual/fetchWalkers", {
                  root: true,
                });
                context.commit(
                  "setErrorMessage",
                  `Failed to fetch walker: ${err}`,
                  {
                    root: true,
                  }
                );
                throw err;
              });
          });
          return Promise.all(walkers).then((walkers) => {
            context.commit(
              "setWalkers",
              walkers.sort((a, b) => {
                if (!a && !b) return 0;
                else if (!a) return 1;
                else if (!b) return -1;
                else return a.walkerNumber - b.walkerNumber;
              })
            );
          });
        })
        .then(() => {
          context.commit("finishLoading", "walkers/fetchWalkers", {
            root: true,
          });
        })
        .catch((err) => {
          context.commit("finishLoading", "walkers/fetchWalkers", {
            root: true,
          });
          context.commit(
            "setErrorMessage",
            `Cannot fetch walkers (user): ${err}`,
            { root: true }
          );
        });
    }
  },
  getNextWalkerNumber(context, route: Route) {
    context.commit("startLoading", "walkers/getNextWalkerNumber", {
      root: true,
    });
    return infoCollection
      .doc("walkerNumbers")
      .get()
      .then((doc) => {
        let walkerNumber = 0;
        walkerNumber = doc.get(route);
        const walkerCap =
          route !== Route.NONE && context.rootState.info
            ? context.rootState.info?.walkerNumbers.start[route] +
              context.rootState.info?.walkerNumbers.limit[route]
            : 0;
        if (walkerNumber >= walkerCap && !context.rootGetters["user/isAdmin"]) {
          throw new Error(`Unfortunately the ${route} route is now full`);
        }
        const extraNumbers: number[] = doc.get("extras")[route];
        if (
          extraNumbers.length > 0 &&
          !context.rootGetters["info/mainEventWalkerNumbersLocked"] &&
          !context.rootGetters["info/mainEventBookingsClosed"]
        ) {
          walkerNumber = extraNumbers[0];
          infoCollection
            .doc("walkerNumbers")
            .update(
              `extras.${route}`,
              store.FieldValue.arrayRemove(walkerNumber)
            );
        } else {
          infoCollection.doc("walkerNumbers").update(route, walkerNumber + 1);
        }
        context.commit("finishLoading", "walkers/getNextWalkerNumber", {
          root: true,
        });
        return walkerNumber;
      })
      .catch((err) => {
        context.commit("finishLoading", "walkers/getNextWalkerNumber", {
          root: true,
        });
        context.commit(
          "setErrorMessage",
          `Cannot fetch next walker number: ${err}`,
          { root: true }
        );
        throw err;
      });
  },
  isRouteFull(context, route: Route) {
    context.commit("startLoading", "walkers/isRouteFull", {
      root: true,
    });
    return infoCollection
      .doc("walkerNumbers")
      .get()
      .then((doc) => {
        const walkerNumber = doc.get(route);
        const walkerCap =
          route !== Route.NONE && context.rootState.info
            ? context.rootState.info?.walkerNumbers.start[route] +
              context.rootState.info?.walkerNumbers.limit[route]
            : 0;
        return walkerNumber >= walkerCap;
      })
      .catch((err) => {
        context.commit("finishLoading", "walkers/isRouteFull", {
          root: true,
        });
        context.commit(
          "setErrorMessage",
          `Cannot fetch next walker number: ${err}`,
          { root: true }
        );
        throw err;
      });
  },
  createWalker(context, walker: Walker) {
    context.commit("startLoading", "walkers/createWalker", { root: true });
    const user = context.rootState.user?.profile;
    if (!user) {
      context.commit(
        "setErrorMessage",
        "Error adding walker: User is undefined"
      );
      return;
    }
    if (!walker.contact) {
      walker.contact = user.id;
    }
    return context
      .dispatch("getNextWalkerNumber", walker.route)
      .then((walkerNumber) => {
        walker.walkerNumber = walkerNumber;
        walker.createdTimestamp = store.Timestamp.now();
        walker.updatedTimestamp = store.Timestamp.now();
        return walkersCollection.add(walker);
      })
      .then((docRef) => {
        walker.id = docRef.id;
        return usersCollection
          .doc(walker.contact)
          .collection("walkers")
          .doc(docRef.id)
          .set({
            firstName: walker.firstName,
            lastName: walker.lastName,
            record: docRef,
          });
      })
      .then(() => {
        return context.dispatch("info/addGroup", walker.group, { root: true });
      })
      .then(() => {
        context.commit("appendWalker", walker);
        return context.commit("finishLoading", "walkers/createWalker", {
          root: true,
        });
      })
      .catch((err) => {
        context.commit("finishLoading", "walkers/createWalker", { root: true });
        return context.commit(
          "setErrorMessage",
          `Error adding walker: ${err}`,
          { root: true }
        );
      });
  },
  updateWalker(context, walker: Walker) {
    context.commit("startLoading", "walkers/updateWalker", { root: true });
    walker.updatedTimestamp = store.Timestamp.now();
    return walkersCollection
      .doc(walker.id)
      .set(walker)
      .then(() => {
        return context.dispatch("info/addGroup", walker.group, { root: true });
      })
      .then(() => {
        context.commit("setWalker", walker);
        context.commit("finishLoading", "walkers/updateWalker", { root: true });
      })
      .catch((err) => {
        context.commit("finishLoading", "walkers/updateWalker", { root: true });
        return context.commit(
          "setErrorMessage",
          `Error updating walker: ${err}`,
          { root: true }
        );
      });
  },
  deleteWalker(
    context,
    { walker, deletionReason }: { walker: Walker; deletionReason: string }
  ) {
    context.commit("startLoading", "walkers/deleteWalker", { root: true });
    return walkersCollection
      .doc(walker.id)
      .delete()
      .then(() => {
        return usersCollection
          .doc(walker.contact)
          .collection("walkers")
          .doc(walker.id)
          .delete();
      })
      .then(() => {
        return infoCollection
          .doc("walkerNumbers")
          .update(
            `extras.${walker.route}`,
            store.FieldValue.arrayUnion(walker.walkerNumber)
          );
      })
      .then(() => {
        const reportWalkerDeletion = functions.httpsCallable(
          "reportWalkerDeletion"
        );
        return reportWalkerDeletion({
          walker,
          deletionReason,
          user: context.rootState.user?.profile,
        });
      })
      .then(() => {
        const walkerArray = context.state.walkers.filter(
          (w) => w.id !== walker.id
        );
        context.commit("setWalkers", walkerArray);
        context.commit("finishLoading", "walkers/deleteWalker", { root: true });
      })
      .catch((err) => {
        context.commit("finishLoading", "walkers/deleteWalker", { root: true });
        return context.commit(
          "setErrorMessage",
          `Error deleting walker: ${err}`,
          {
            root: true,
          }
        );
      });
  },
  deleteWalkersByUserID(context, id: string) {
    context.commit("startLoading", "walkers/deleteWalkersByUserID", {
      root: true,
    });
    return walkersCollection
      .where("contact", "==", id)
      .get()
      .then((query) => {
        return query.forEach((doc) => {
          doc.ref.delete();
        });
      })
      .then(() => {
        return usersCollection.doc(id).collection("walkers").get();
      })
      .then((query) => {
        return query.forEach((doc) => {
          doc.ref.delete();
        });
      })
      .then(() => {
        const walkerArray = context.state.walkers.filter(
          (w) => w.contact !== id
        );
        context.commit("setWalkers", walkerArray);
        context.commit("finishLoading", "walkers/deleteWalkersByUserID", {
          root: true,
        });
      })
      .catch((err) => {
        context.commit("finishLoading", "walkers/deleteWalkersByUserID", {
          root: true,
        });
        return context.commit(
          "setErrorMessage",
          `Error deleting walkers: ${err}`,
          { root: true }
        );
      });
  },
  clearData: {
    root: true,
    handler(context) {
      context.commit("setWalkers", []);
    },
  },
};

export const mutations: MutationTree<WalkerState> = {
  setWalkers(state, val: Walker[]) {
    state.walkers = val;
  },
  appendWalker(state, val: Walker) {
    state.walkers.push(val);
    state.walkers.sort((a, b) => a.walkerNumber - b.walkerNumber);
  },
  setWalker(state, val: Walker) {
    const i = state.walkers.findIndex((walker) => walker.id === val.id);
    state.walkers[i] = val;
  },
};

export const walkers: Module<WalkerState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
