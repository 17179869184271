import { BasketItem, ShopItem } from "@/types";
import { DeliveryMethod } from "@/types/enums";

export function calculateBasketCost(basket: BasketItem[], items: ShopItem[]) {
  return basket
    .map(
      (basketItem) =>
        (items.find((item) => item.id === basketItem.id)?.cost ?? 0) *
        basketItem.quantity
    )
    .reduce((a, b) => a + b, 0);
}

export function calculateDeliveryCost(
  basket: BasketItem[],
  deliveryMethod: DeliveryMethod,
  items: ShopItem[]
) {
  if (deliveryMethod === DeliveryMethod.POST) {
    return items
      .map((item) => {
        const quantity = basket
          .filter((basketItem) => basketItem.id === item.id)
          .map((basketItem) => basketItem.quantity)
          .reduce((a, b) => a + b, 0);
        const cost =
          item.deliveryCosts.find(
            (deliveryCost) =>
              quantity >= deliveryCost.minNumber &&
              quantity <= deliveryCost.maxNumber
          )?.cost ?? 0;
        return cost;
      })
      .reduce((a, b) => a + b, 0);
  } else {
    return 0;
  }
}
