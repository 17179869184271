
import { UserProfile, Walker } from "@/types";
import Vue from "vue";
import RouteStatCard from "./RouteStatCard.vue";

export default Vue.extend({
  data() {
    return {
      bronzeColour: "#b1560f",
      silverColour: "#aaa9ad",
      goldColour: "#ffdf00",
    };
  },
  computed: {
    walkers(): Walker[] {
      return this.$store.state.walkers.walkers;
    },
    user(): UserProfile {
      return this.$store.state.user.profile;
    },
    totalToBePaid(): number {
      if (this.$store.getters["user/isAdmin"]) {
        return this.$store.getters["admin/totalOutstandingBalance"];
      } else {
        return this.$store.getters["user/outstandingBalance"];
      }
    },
    totalPaid(): number {
      if (this.$store.getters["user/isAdmin"]) {
        return this.$store.getters["admin/totalPaid"];
      } else {
        return this.user.totalPaid;
      }
    },
  },
  components: {
    RouteStatCard,
  },
});
