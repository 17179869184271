
import { ErrorLog } from "@/types";
import moment from "moment";
import Vue from "vue";
export default Vue.extend({
  data() {
    return {
      headers: [
        {
          text: "Timestamp",
          value: "timestamp",
        },
        {
          text: "Name",
          value: "userName",
        },
        {
          text: "Error Type",
          value: "type",
        },
        {
          text: "Error",
          value: "error",
        },
      ],
    };
  },
  computed: {
    errors(): ErrorLog[] {
      return this.$store.state.admin.errors;
    },
  },
  methods: {
    formatDate(date: Date) {
      return moment(date).format("DD/MM/YYYY HH:mm:ss.SSS");
    },
  },
});
